import React, { Component } from 'react'
import { Card, Input, Form, Button, message } from 'antd'
import request from '../utils/request';
import auth from '../utils/auth';
import Loader from '../components/Loader'
import { connect } from 'react-redux'

class PerfilPage extends Component {

    state = {
        pwActual: "",
        pwNueva: "",
        pwNueva2: ""
    }

    cambiarContraseña = () => {

        if(this.state.pwNueva.length < 6){ message.warning("La contraseña ha de tener una longitud igual o superior a 6 caracteres") }
        if(this.state.pwNueva !== this.state.pwNueva2){ message.warning("Las contraseñas no coinciden") }
        if(this.state.pwNueva === "cetelem"){ message.warning("Esa contraseña no es válida") }

        fetch(process.env.REACT_APP_API_URL + "/auth/local", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "identifier": auth.getUserInfo().username,
                "password": this.state.pwActual,
                "tieneQueCambiarPw": false
            })
        }).then(resp => {
            console.log(resp)
            if(resp.status === 200 && this.state.pwNueva.length >= 6 && this.state.pwNueva === this.state.pwNueva2 && this.state.pwNueva !== "cetelem"){
                request(process.env.REACT_APP_API_URL + "/users/" + auth.getUserInfo()._id, {
                    method: "PUT",
                    body: { 
                        password: this.state.pwNueva,
                        tieneQueCambiarPw: false 
                    }
                }).then(data => {
                    message.success("La contraseña se cambió correctamente")
                    auth.setUserInfo({ ...auth.getUserInfo(), tieneQueCambiarPw: false })
                }).catch(err => message.error("Ocurrió un error durante el cambio de contraseña"))
            }
            else if(resp.status === 400){ message.warning("La contraseña actual no es correcta") }
            else if(resp.status >= 500){ message.error("No se ha podido contactar con el servidor") }
        }).catch(err => {
            console.log(err)
            message.error("No se ha podido contactar con el servidor")
        })
    }

    render(){
        return (
            <div style={{
                display: "flex",
                flexDirection: "column",
                margin: "0 auto",
                width: "100%",
                padding: "1em",
                maxWidth: "1200px"
            }}>
                <Loader loading={this.props.cargando} />
                <div style={{ padding: "2em 0" }}>
                    <h2 style={{ paddingBottom: "1em" }}>Mi perfil</h2>
                    <Card style={{ display: "flex", flexDirection: "column", marginBottom: "1em", alignItems: "center" }}>
                        <h3>Cambio de contraseña</h3>
                        <div style={{ width: 300 }}>
                            <Form.Item label="Contraseña actual">
                                <Input.Password onChange={evt => this.setState({ pwActual: evt.target.value })} />
                            </Form.Item>
                            <Form.Item label="Nueva contraseña">
                                <Input.Password onChange={evt => this.setState({ pwNueva: evt.target.value })} />
                            </Form.Item>
                            <Form.Item label="Confirma la nueva contraseña">
                                <Input.Password onChange={evt => this.setState({ pwNueva2: evt.target.value })} />
                            </Form.Item>
                            <Button onClick={() => this.cambiarContraseña()}>Cambiar contraseña</Button>
                        </div>
                    </Card>
                </div>
            </div>
        )
    } 
}

const mapStateToProps = state => ({
    cargando: state.cargando
})

export default connect(mapStateToProps)(PerfilPage)