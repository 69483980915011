/**
 *
 * PrivateRoute
 * Higher Order Component that blocks navigation when the user is not logged in
 * and redirect the user to login page
 *
 * Wrap your protected routes to secure your container
 */

import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { includes as lodashIncludes } from 'lodash';

import auth from '../utils/auth';

const PrivateRoute = ({ component: Component, allowed, ...rest }) => {
  const authData = auth.getUserInfo();
  //const basicPath = (authData && authData.role) ? ( authData.role.name === "CETAgente" ? "/agente" : (authData.role.name === "CETManager" ? "/listaequipos" : "/")) : "/auth/login"
  const basicPath = "/"
  return (
  <Route
    {...rest}
    render={ props =>
      ( auth.getToken() !== null && authData !== null && authData.status !== false && lodashIncludes( allowed, auth.getUserInfo().role.name ) ) ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: basicPath,
            state: { from: props.location },
          }}
        />
      )
    }
  />
)};

export default PrivateRoute;
