import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Card, Button, Switch, message } from 'antd'
import { fetchConfig } from '../../actions'
import request from '../../utils/request';
import 'react-sortable-tree/style.css';

class PanelEmail extends Component{
    state = {
        notificar1: false,
        notificar2: false,
        notificar3: false
    }

    componentDidMount(){
        if(this.props.config){
            this.setState({
                notificar1: this.props.config.enviarManagerEncuesta,
                notificar2: this.props.config.enviarAgenteValorado,
                notificar3: this.props.config.enviarManagerComentario
            })
        } 
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.config !== this.props.config){
            this.setState({
                notificar1: this.props.config.enviarManagerEncuesta,
                notificar2: this.props.config.enviarAgenteValorado,
                notificar3: this.props.config.enviarManagerComentario
            })
        }
    }

    guardarConfig = () => {
        request(process.env.REACT_APP_API_URL + "/configs/" + this.props.config._id, {
            method: "PUT",
            body: {
                enviarManagerEncuesta: this.state.notificar1,
                enviarAgenteValorado: this.state.notificar2,
                enviarManagerComentario: this.state.notificar3
            }
        }).then(data => {
            message.success("Los ajustes se guardaron correctamente")
            this.props.dispatch(fetchConfig())
        }).catch(err => {
            console.log(err)
            message.error("Ocurrió un error durante el guardado de los ajustes")
        })
    }
    

    render(){
        return(
            <div style={{
                padding: "2em 0",
                display: "flex",
                flexDirection: "column"
            }}>
                <h2 style={{ paddingBottom: "1em" }}>Emails</h2>
                <div style={{ marginBottom: "1em", height: 800 }}>
                    <Card style={{ display: "flex", flexDirection: "column", marginBottom: "1em", padding: "10px 30px 0 30px" }}>
                        <div style={{ marginBottom: "1em", display: "flex", justifyContent: "space-between" }}>
                            <span style={{ marginRight: "1em" }}>Notificar al manager cuando hay una nueva encuesta disponible</span>
                            <Switch checked={ this.state.notificar1 } onChange={() => this.setState({ notificar1: !this.state.notificar1})} />
                        </div>
                        <div style={{ marginBottom: "1em", display: "flex", justifyContent: "space-between" }}>
                            <span style={{ marginRight: "1em" }}>Notificar a los asesores que su manager les ha valorado</span>
                            <Switch checked={ this.state.notificar2 } onChange={() => this.setState({ notificar2: !this.state.notificar2})} />
                        </div>
                        <div style={{ marginBottom: "1em", display: "flex", justifyContent: "space-between" }}>
                            <span style={{ marginRight: "1em" }}>Notificar a los manager que un superior ha escrito un comentario sobre su equipo o sobre alguno de sus asesores</span>
                            <Switch checked={ this.state.notificar3 } onChange={() => this.setState({ notificar3: !this.state.notificar3})} />
                        </div>
                        <Button onClick={this.guardarConfig} style={{ marginTop: 20 }}>Guardar</Button>
                    </Card>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    config: state.config
})

export default connect(mapStateToProps)(PanelEmail)