import React, { Component } from 'react'
import { Button, Modal, message, Input } from 'antd'
import { subirFotoPerfil, asociarCoord } from '../utils/func'
import Dropzone from 'react-dropzone'
import { connect } from 'react-redux'

class ModalEditarDatos extends Component{
    state = {
        coord: {}
    }

    componentDidUpdate(prevProps){
        if((this.props.coord !== prevProps.coord) && this.props.coord){
            this.setState({
                lat: this.props.coord.lat,
                lng: this.props.coord.lng
            })
        }
    }
    
    render(){
        const { visible, id, tipo, fotoActual, cb } = this.props
        const { lng, lat } = this.state
        return (
            <Modal
            visible={visible}
            onCancel={() => cb(false)}
            footer={null}
            >
                <h3 style={{ marginBottom: 20 }}>Cambiar foto</h3>
                { fotoActual ?
                    <img style={{
                        width: "100%",
                        maxHeight: 300,
                        borderRadius: 5,
                        marginBottom: "1em",
                        objectFit: "cover"
                    }} src={process.env.REACT_APP_API_URL + fotoActual.url} alt="" /> :
                    <p style={{ marginBottom: 20 }}>Sin foto asociada</p>
                }
                <div className="dropzoneCleaner" style={{ marginBottom: 40 }}>
                    <Dropzone 
                        accept={["image/jpeg", "image/jpeg", "image/png", "image/svg+xml"]}
                        onDrop={files => subirFotoPerfil(files, tipo, id, cb)}
                        onDropRejected={() => message.warning("Las imagenes han de ser JPG, PNG y SVG con un tamaño máximo de 2MiB")}
                        multiple={false}
                        maxSize={2097152}
                        >
                        <Button size="large">Subir nueva imagen</Button>
                    </Dropzone>
                </div>
                {
                    this.props.coord &&
                        <div>
                            <h3 style={{ marginBottom: 20 }}>Asociar coordenadas</h3>
                            <p>Latitud<Input type="number" value={lat} onChange={evt => this.setState({ lat: evt.target.value }) }/></p>
                            <p>Longitud<Input type="number" value={lng} onChange={evt => this.setState({ lng: evt.target.value }) }/></p>
                            <a href={`http://www.google.com/maps/place/${lat},${lng}`} rel="noopener noreferrer" target="_blank"><Button>Probar ubicación</Button></a>
                            <Button style={{ marginRight: 10 }} onClick={async () => {
                                await asociarCoord({_id: id}, {lat, lng})
                                cb(true)
                            }} type="primary">Guardar coordenadas</Button>
                        </div>
                }
            </Modal>
        )
    }
}

export default connect()(ModalEditarDatos)
