import React, { Component } from 'react';
import { Col, Row, Container } from 'react-grid-system'
import FichaFoto from '../components/FichaFoto'
import BarFiltro from '../components/BarFiltro'
import CardNivel from '../components/CardNivel'
import Ranking from '../components/Ranking'
import ButtonBlock from '../components/ButtonBlock'
import CardTeam from '../components/CardTeam'
import BlockMap from '../components/BlockMap'
import Loader from '../components/Loader'
import { connect } from 'react-redux'
import ModalEditarDatos from '../components/ModalEditarDatos'
import ModalValoracion from '../components/ModalValoracion';
import ModalComentario from '../components/ModalComentario';
import { fetchDatos, fetchMedias } from '../actions'
import auth from '../utils/auth'
import { mediaVariosMeses, getGrupo, getBaseURL } from '../utils/func'
import moment from 'moment'
import ModalCambiarPw from '../components/ModalCambiarPw';
import request from '../utils/request';

class DivisionPage extends Component {

  state = {
    division: {
      nombre: "",
      managerdivision: [{
        name: "",
        lastname: ""
      }],
      departamentos: [],
      medias: {}
    },
    rankingSelected: {"NEG": 1},
    equipoactivo: "Activos"
  }

  async componentDidMount(){
    if(Array.isArray(this.props.datos.plano)){
      // const slug = this.props.match.params.idDivision
      // const indice = slug.slice(-2, -1) === "_" ? parseInt(slug.slice(-1), 10) : 0
      // let division = this.props.datos.plano.filter(e => {
      //   const slugTrim = indice > 0 ? slug.slice(0, -2) : slug
      //   return slugTrim === slugify(e.nombre) && e.jerarquia === "division"
      // })[indice]
      let division = this.props.datos.plano.find(d => d.idcet.toLowerCase() === this.props.match.params.idDivision)
      if(!division){ this.props.history.push(await getBaseURL()) }
      else{
        if(Array.isArray(division.agentes)){ division.agentes = division.agentes.filter(a => !a.blocked) }
        if(Array.isArray(division.departamentos)){ division.departamentos = division.departamentos.filter(d => d.activo || (Array.isArray(d.datobams) && d.datobams.length > 0)) }
        this.setState({ division })
        this.seteoInicial(division)
      }
    }
  }
  
  async componentDidUpdate(prevProps){
    if((prevProps.datos !== this.props.datos || this.props.medias !== prevProps.medias) && Array.isArray(this.props.datos.plano) && this.props.medias ){
      let division = this.props.datos.plano.find(d => d.idcet.toLowerCase() === this.props.match.params.idDivision)
      if(!division){ this.props.history.push(await getBaseURL()) }
      else{
        if(Array.isArray(division.agentes)){ division.agentes = division.agentes.filter(a => !a.blocked) }
        if(Array.isArray(division.departamentos)){ division.departamentos = division.departamentos.filter(d => d.activo || (Array.isArray(d.datobams) && d.datobams.length > 0)) }
        this.setState({ division })
        this.seteoInicial(division)
      }
    }
    if(this.props.fecha !== prevProps.fecha){
      this.props.dispatch(fetchDatos(auth.getUserInfo().role.name, getGrupo(auth.getUserInfo().role.name), this.props.fecha))
      this.props.dispatch(fetchMedias(auth.getUserInfo().role.name, this.props.fecha))
    }
  }

  seteoInicial = division => {
    const managerStr = Object.keys(this.props.datos.arbol[0]).find(k => k.slice(0, 7) === "manager")
    if(auth.getUserInfo().tieneQueCambiarPw){
      this.setState({ modalCambiarPw: true })
    }
    this.procesaMedias(division)
    try{
      if(
        (Array.isArray(this.props.datos.arbol[0][managerStr]) && this.props.datos.arbol[0][managerStr].map(it => it._id).includes(auth.getUserInfo()._id)) && 
        auth.getUserInfo().entraEncuesta && 
        !(this.props.location.state && !this.props.location.state.sinEncuesta) &&
        this.props.datos.arbol[0].agentes.length > 0
      ){
        this.setState({ modalValoracion: true })
      }
    }
    catch(err){}
  }

  procesaMedias = division => {
    const unMes = moment(this.props.fecha.desde).isSame(this.props.fecha.hasta, "month")
    const departamentos = division.departamentos
    const mdep = this.props.medias.filter(m => m.departamento).filter(m => moment(m.fecha).isSameOrAfter(this.props.fecha.desde, "month") && moment(m.fecha).isSameOrBefore(this.props.fecha.hasta, "month"))
    const mediaPorDepartamento = departamentos.map(d => {
      const mdFilt = mdep.filter(m => {
        if(!unMes){ return m.departamento._id === d._id }
        else{ return m.departamento._id === d._id && moment(m.fecha).isSame(this.props.fecha.desde, "month") }
      })
      return { 
        _id: d._id, 
        mediasMensuales: mdFilt.map(m => ({[moment.utc(m.fecha).format()]: m})).reduce((a, b) => ({...a, ...b}), {}), 
        medias: mediaVariosMeses(mdFilt)
      }
    })
    const areas = division.unidadareas
    const mar = this.props.medias.filter(m => m.unidadarea).filter(m => moment(m.fecha).isSameOrAfter(this.props.fecha.desde, "month") && moment(m.fecha).isSameOrBefore(this.props.fecha.hasta, "month"))
    const mediaPorArea = areas.map(a => {
      const mdFilt = mar.filter(m => {
        if(!unMes){ return m.unidadarea._id === a._id }
        else{ return m.unidadarea._id === a._id && moment(m.fecha).isSame(this.props.fecha.desde, "month") }
      })
      return { 
        _id: a._id, 
        mediasMensuales: mdFilt.map(m => ({[moment.utc(m.fecha).format()]: m})).reduce((a, b) => ({...a, ...b}), {}), 
        medias: mediaVariosMeses(mdFilt)
      }
    })
    const agentes = division.agentes
    const muser = this.props.medias.filter(m => m.user).filter(m => moment(m.fecha).isSameOrAfter(this.props.fecha.desde, "month") && moment(m.fecha).isSameOrBefore(this.props.fecha.hasta, "month"))
    const mediaPorAgente = agentes.map(a => {
      const mdFilt = muser.filter(m => m.user._id === a._id)
      return { 
        _id: a._id, 
        mediasMensuales: mdFilt.map(m => ({[moment.utc(m.fecha).format()]: m})).reduce((a, b) => ({...a, ...b}), {}), 
        medias: mediaVariosMeses(mdFilt)
      }
    })
    const mdiv = this.props.medias.filter(m => m.division)
                                  .filter(m => m.division._id === division._id)
                                  .filter(m => moment(m.fecha).isSameOrAfter(unMes ? moment(this.props.fecha.desde).subtract(6, "months") : this.props.fecha.desde, "month") && moment(m.fecha).isSameOrBefore(this.props.fecha.hasta, "month"))
    const mediaGlobal = {
      mediasMensuales: mdiv.map(m => ({[moment.utc(m.fecha).format()]: m})).reduce((a, b) => ({...a, ...b}), {}), 
      medias: mediaVariosMeses(mdiv.filter(m => !unMes ? mdiv : mdiv && moment(m.fecha).isSame(this.props.fecha.desde, "month")))
    }
    this.setState({ mediaPorDepartamento, mediaPorArea, mediaPorAgente, mediaGlobal })
  }

  abreModal = (datos, accionModal) => {
    const managers = (datos && datos[Object.keys(datos).find(k => k.slice(0, 7) === "manager")]) || []
    if(managers.map(m => m._id).includes(auth.getUserInfo()._id)){
      const noLeidos = datos.comentariosRecibidos.filter(c => c.nuevo)
      noLeidos.forEach(c => {
        request(process.env.REACT_APP_API_URL + "/comentarios/" + c._id, {
          method: "PUT",
          body: { nuevo: false}
        }).catch(err => console.log(err))
      })
    }
    this.setState({
      modalComentario: true,
      datosModalComentario: datos,
      accionModal
    })
  }

  render() {
    const { division } = this.state
    return (
          <div>
            <Loader loading={this.props.cargando} />
            <Container fluid style={{ margin: 0, padding: 0 }}>
              <Row style={{ margin: 0, padding: 0 }}>
                <Col style={{ margin: 0, padding: 0 }}>
                  <BarFiltro breadcrumbs={{ 
                    comite: division.comite, 
                    direccion: division.direccion, 
                    division: { nombre: division.nombre, idcet: division.idcet }
                  }} title={""} fecha={true} centro={true} />
                </Col>
              </Row>
            </Container>

            <Container style={{ marginTop: 50 }}>
              <Row >
                <Col md={6} >
                  <div className="card shadow" style={{ height: '400px' }}>
                    <BlockMap
                      isMarkerShown={true}
                      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAHppFv9ZKUMaEpDIm0mOhdJ78oQqiU83w&v=3.exp&libraries=geometry,drawing,places"
                      loadingElement={<div style={{ height: `100%` }} />}
                      containerElement={<div style={{ height: `100%` }} />}
                      mapElement={<div style={{ height: `100%`, overflow: `hidden`, borderRadius: `15px` }} />}
                      defaultCenter={{ 
                        lat: division.coordenadas && division.coordenadas.lat,
                        lng: division.coordenadas && division.coordenadas.lng
                      }}
                      defaultZoom={8}
                    />
                  </div>
                </Col>
                <Col md={6} >
                  <FichaFoto 
                    img={division.picture ? process.env.REACT_APP_API_URL + division.picture.url : "https://images.pexels.com/photos/380768/pexels-photo-380768.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"} 
                    name={ division.nombre } 
                    puesto={"Jefe de equipo: " + (division.managerdivision[0] ? (division.managerdivision[0].name + " " + division.managerdivision[0].lastname) : "")} 
                    alturatit={150} 
                    alturafoto={250} 
                    mostrarBtn={true}
                    action={() => this.setState({ modalEdicion: true })} 
                    actionTitle="Editar datos" 
                    action2={() => this.abreModal(division, "ver")}
                    actionTitle2="Comentarios"
                  />
                </Col>
              </Row>
            </Container>

            <Container style={{ marginTop: 100, padding: 30, border: '3px solid gainsboro', borderRadius: 25, height: 750, marginBottom: 200 }}>
              <Row justify="center">
                <Col md={5} style={{ marginTop: -50, marginBottom: 30, textAlign: 'center', backgroundColor: 'white' }}><h2>Media Nivel x Responsabilidad</h2></Col>
              </Row>
              <Row justify="center">
                <Col md={3}>
                  <CardNivel
                    title="Garantizar el cumplimiento de los objetivos principales" 
                    currentVal={ this.state.mediaGlobal && this.state.mediaGlobal.medias[1] } 
                    historicVal={ this.state.mediaGlobal && this.state.mediaGlobal.mediasMensuales } 
                    datobams={division.datobams}
                    fecha={this.props.fecha}
                    resp={1}
                  />
                </Col>
                <Col md={3}>
                  <CardNivel 
                    title="Garantizar el cumplimiento de los objetivos intermedios" 
                    currentVal={ this.state.mediaGlobal && this.state.mediaGlobal.medias[2] } 
                    historicVal={ this.state.mediaGlobal && this.state.mediaGlobal.mediasMensuales } 
                    datobams={division.datobams}
                    fecha={this.props.fecha}
                    resp={2}
                  />
                </Col>
                <Col md={3}>
                  <CardNivel 
                    title="Garantizar la satisfacción del cliente" 
                    currentVal={ this.state.mediaGlobal && this.state.mediaGlobal.medias[3] } 
                    historicVal={ this.state.mediaGlobal && this.state.mediaGlobal.mediasMensuales } 
                    datobams={division.datobams}
                    fecha={this.props.fecha}
                    resp={3}
                  />
                </Col>
              </Row>
              <Row justify="center" style={{ marginTop: 50 }}>
                <Col md={3}>
                  <CardNivel 
                    title="Aprovechamiento de las acciones formativas" 
                    currentVal={ this.state.mediaGlobal && this.state.mediaGlobal.medias[4] } 
                    historicVal={ this.state.mediaGlobal && this.state.mediaGlobal.mediasMensuales } 
                    datobams={division.datobams}
                    fecha={this.props.fecha}
                    resp={4}
                  />
                </Col>
                <Col md={3}>
                  <CardNivel 
                    title="Garantizar el cumplimiento normativo" 
                    currentVal={ this.state.mediaGlobal && this.state.mediaGlobal.medias[5] } 
                    historicVal={ this.state.mediaGlobal && this.state.mediaGlobal.mediasMensuales } 
                    datobams={division.datobams}
                    fecha={this.props.fecha}
                    resp={5}
                  />
                </Col>
                <Col md={3}>
                  <CardNivel stars={true} 
                    title="Valoración del manager" 
                    currentVal={ this.state.mediaGlobal && this.state.mediaGlobal.medias[6] } 
                    historicVal={ this.state.mediaGlobal && this.state.mediaGlobal.mediasMensuales } 
                    datobams={division.datobams}
                    fecha={this.props.fecha}
                    resp={6}
                  />
                </Col>
              </Row>
            </Container>

            { Array.isArray(division.departamentos) && division.departamentos.length > 0 && 
              <div>
                <Container style={{ marginTop: 50 }}>
                  <Row align="center" style={{ marginBottom: "1em" }}>
                    <Col md={5}>
                      <h2 style={{ textAlign: "left", }}>Ranking de Departamentos</h2>
                    </Col>
                    <Col offset={{ md: 2 }} md={5}>
                      <ButtonBlock 
                        changeRankingSelected={(rankingSelected) => this.setState({ rankingSelected })} 
                        rankingSelected={Object.keys(this.state.rankingSelected)[0]} 
                        indicadores={[{"NEG": 1}, {"INT": 2}, {"CAL": 3}, {"FOR": 4}, {"NOR": 5}, {"MAN": 6}]} 
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Ranking tipo="departamento" medias={this.state.mediaPorDepartamento} dataRanking={division.departamentos} height={300} rankingSelected={Object.values(this.state.rankingSelected)} placeholder="/stickmangroup.jpg" />
                    </Col>
                  </Row>
                </Container>

                <Container style={{ marginTop: 50, marginBottom: 50 }}>
                  <Row >
                    <Col md={4}>
                      <h2 style={{ textAlign: "left", marginBottom: "1em" }}>Departamentos</h2>
                    </Col>
                  </Row>
                  <Row>
                    {division.departamentos.map((d, i) =>
                      <Col key={i} md={3}>
                        <CardTeam 
                          indicator={d.estado} 
                          image={d.picture ? process.env.REACT_APP_API_URL + d.picture.url : "/stickmangroup.jpg"}
                          title={d.nombre} 
                          subtitle={d.managerdepartamento && d.managerdepartamento[0] && (d.managerdepartamento[0].name + " " + d.managerdepartamento[0].lastname)} 
                          dataIndicadores={this.state.mediaPorDepartamento && this.state.mediaPorDepartamento.find(m => m._id === d._id).medias} 
                          abreModal={this.abreModal}
                          datos={d}
                          comentarios={d.comentarios}
                          linkTo={"/departamento/" + d.idcet.toLowerCase()}
                        />
                      </Col>
                    )
                    }
                  </Row>
                </Container>
              </div>
            }

            { Array.isArray(division.unidadareas) && division.unidadareas.length > 0 && 
              <div>
                <Container style={{ marginTop: 50 }}>
                  <Row align="center">
                    <Col md={4}>
                      <h2>Ranking de Áreas</h2>
                    </Col>
                    <Col offset={{ md: 3 }} md={5}>
                      <ButtonBlock 
                        changeRankingSelected={(rankingSelected) => this.setState({ rankingSelected })} 
                        rankingSelected={this.state.rankingSelected} 
                        indicadores={[{"1": 1}, {"2": 2}, {"3": 3}, {"4": 4}, {"5": 5}, {"6": 6}]} 
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Ranking tipo="area" medias={this.state.mediaPorArea} dataRanking={division.unidadareas} height={300} rankingSelected={this.state.rankingSelected} placeholder="/stickmangroup.jpg" />
                    </Col>
                  </Row>
                </Container>

                <Container style={{ marginTop: 50, marginBottom: 50 }}>
                  <Row style={{ marginBottom: "1em" }}>
                    <Col md={4}>
                      <h2 style={{ textAlign: "left" }}>Áreas</h2>
                    </Col>
                  </Row>
                  <Row>
                    {division.unidadareas.map((d, i) => 
                      <Col key={i} md={3}>
                        <CardTeam 
                          indicator={d.estado} 
                          image={d.picture ? process.env.REACT_APP_API_URL + d.picture.url : "/stickmangroup.jpg"}  
                          title={d.nombre} 
                          subtitle={d.managerdepartamento && d.managerdepartamento[0] && d.managerdepartamento[0].nombre} 
                          dataIndicadores={this.state.mediaPorArea && this.state.mediaPorArea.find(m => m._id === d._id).medias} 
                          abreModal={this.abreModal}
                          datos={d}
                          comentarios={d.comentarios}
                          linkTo={"/area/" + d.idcet.toLowerCase()}
                        />
                      </Col>
                    )}
                  </Row>
                </Container>
              </div>
            }

            { Array.isArray(division.agentes) && division.agentes.length > 0 &&
              <div>
                <Container style={{ marginTop: 50 }}>
                  <Row align="center" style={{ marginBottom: "1em" }}>
                    <Col md={4}>
                      <h2 style={{ textAlign: "left" }}>Ranking de Agentes</h2>
                    </Col>
                    <Col offset={{ md: 3 }} md={5}>
                      <ButtonBlock
                        changeRankingSelected={rankingSelected =>
                          this.setState({ rankingSelected })
                        }
                        rankingSelected={
                          Object.keys(this.state.rankingSelected)[0]
                        }
                        indicadores={[
                          { NEG: 1 },
                          { INT: 2 },
                          { CAL: 3 },
                          { FOR: 4 },
                          { NOR: 5 },
                          { MAN: 6 }
                        ]}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Ranking
                        tipo="agente"
                        dataRanking={division.agentes}
                        medias={this.state.mediaPorAgente}
                        height={300}
                        rankingSelected={Object.values(
                          this.state.rankingSelected
                        )}
                        placeholder="/stickman.jpg"
                      />
                    </Col>
                  </Row>
                </Container>
                  <Container style={{ marginTop: 100, marginBottom: 50 }}>
                    <Row align="center" style={{ marginBottom: 30 }}>
                        <Col md={4}>
                            <h2 style={{ textAlign: "left" }}>Equipo</h2>
                        </Col>
                        <Col offset={{ md: 5 }} md={3}>
                            {/* <ButtonBlock 
                                changeRankingSelected={(equipoactivo) => this.setState({ equipoactivo })} 
                                rankingSelected={this.state.equipoactivo} 
                                indicadores={[{Activos: "Activos"}, {Inactivos: "Inactivos"}]} 
                                /> */}
                            {/* <ButtonBlockEquipo changeTeamSelected={(equipoactivo) => this.setState({ equipoactivo })} teamSelected={this.state.equipoactivo} /> */}
                        </Col>
                    </Row>
                    <Row>
                        {division.agentes.map((a, i) =>
                            <Col key={i} md={3}>
                              <CardTeam 
                                indicator={a.estado} 
                                image={a.imagen ? process.env.REACT_APP_API_URL + a.imagen.url : "/stickman.jpg"} 
                                title={a.name + " " + a.lastname} 
                                dataIndicadores={this.state.mediaPorAgente && this.state.mediaPorAgente.find(m => m._id === a._id).medias} 
                                abreModal={this.abreModal}
                                datos={a}
                                comentarios={a.comentarios}
                                linkTo={"/agente/" + a.idusuario}
                              />
                            </Col>
                        )}
                    </Row>
                  </Container>
              </div>
            }

            <ModalValoracion
              visible={this.state.modalValoracion}
              cb={bool => {
                if(bool){ this.props.history.push("/valoraciones") } 
                else{ this.setState({ modalValoracion: false }) }
              }}
            />
            <ModalComentario
              visible={this.state.modalComentario}
              cb={() => this.setState({ modalComentario: false }) }
              comentado={this.state.datosModalComentario}
              accionModal={this.state.accionModal}
            />
            <ModalCambiarPw visible={this.state.modalCambiarPw} cb={bool => this.setState({ modalCambiarPw: false }) } history={this.props.history} />
            <ModalEditarDatos
              visible={this.state.modalEdicion}
              id={division._id}
              tipo="division"
              fotoActual={division.picture}
              cb={refresh => {
                this.setState({ modalEdicion: false })
                if(refresh){ this.props.dispatch(fetchDatos(auth.getUserInfo().role.name, getGrupo(auth.getUserInfo().role.name), this.props.fecha)) }
              }}
              coord={(division && division.coordenadas) || true}
            />

          </div>
    );
  }
}

const mapStateToProps = state => ({
  datos: state.datos,
  medias: state.medias,
  fecha: state.fecha,
  cargando: state.cargando
})

export default connect(mapStateToProps)(DivisionPage);