// Recibe Datos 
// El Indicator puede ser false , completado o nocompletado

import React from 'react';
import './styles.scss'
import BarBlock from '../BarBlock'
import { Icon } from 'antd'
import { Link } from 'react-router-dom'


const CardTeam = ({datos = {}, indicator = false, abreModal, linkTo="/", image = "https://live.staticflickr.com/6093/6306570912_f6716c716d_b.jpg", title, subtitle, dataIndicadores={}, indMax = 100, indCol1 = 33, indCol2 = 66 }) => {

    const dataFancy = [
        {etiqueta: 'R1: NEGOCIO', val: dataIndicadores["1"], obj:50},
        {etiqueta: 'R2: INTERMEDIO', val: dataIndicadores["2"], obj:50},
        {etiqueta: 'R3: CALIDAD', val: dataIndicadores["3"], obj:50},
        {etiqueta: 'R4: FORMACIÓN', val: dataIndicadores["4"], obj:50},
        {etiqueta: 'R5: C.NORMATIVO', val: dataIndicadores["5"], obj:50},
        {etiqueta: 'R6: V.MANAGER', val: dataIndicadores["6"], obj:50} 
    ]
    
    return ( 
        <div className="teamBlock">
            {indicator === 'completado' ? <div className="indicator green"><Icon type="check" /></div> : indicator !== false ? <div className="indicator red"><Icon type="exclamation" /></div> : null }
            <div className="mainBlock card shadow">
                <Link to={linkTo}>
                    <div className="imageTeam">
                        <img alt="" src={image}/>
                    </div>
                    <div className="teamBlock">
                        <h3>{title}</h3>
                        <h5>{subtitle}</h5>
                    </div>
                    <div>
                        <div className="indicadoresBlock">
                            {
                                dataFancy.map((indicador, i) =>
                                    <BarBlock 
                                        key={i} 
                                        val={indicador.val} 
                                        obj={indicador.obj} 
                                        max={indMax} 
                                        etiq={indicador.etiqueta} 
                                        etiqin={true} 
                                        margin={2}
                                        height="15px" 
                                        color1={indCol1} color2={indCol2}
                                    />
                                )
                            }
                        </div>
                    </div>
                </Link>
                <div>
                    <div className="buttonBlock">
                        <div className="buttoncard" onClick={() => abreModal(datos, "ver")}>
                            <Icon type="eye"/>
                        </div>
                        <div className="buttoncard" onClick={() => abreModal(datos, "comentar")}>
                            <Icon type="message" />
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="blockButtom card shadow">
            </div> */}
        </div>
     );
}
 
export default CardTeam;