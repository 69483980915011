import React from 'react';
import './styles.scss'
import 'react-circular-progressbar/dist/styles.css';
import CircularIndicator from '../../components/CircularIndicator'
import { LineChart, Line, ResponsiveContainer } from 'recharts';
import { Rate, Icon, Tooltip } from 'antd'
import { redondear } from '../../utils/func'
import moment from 'moment';

const CardNivel = ({ 
    stars=false, 
    title, 
    currentVal, 
    historicVal = [], 
    dataHistoricValKey = 'value', 
    colorHitoricChart = 'gainsboro', 
    tip, 
    tipid="", 
    fecha,
    resp 
}) => {

    const hayDatos = !Number.isNaN(currentVal) ? true : false
    const nmeses = moment(fecha.hasta).diff(fecha.desde, "months") + 1 > 1 ? moment(fecha.hasta).diff(fecha.desde, "months") + 1 : 6
    const meses = Object.values(Array(nmeses).fill(null).map((e, i) => {
        const mes = moment.utc(fecha.hasta).subtract(i, "months").startOf("month").format()
        return { [mes]: historicVal[mes] }
    }).reverse()).map(a => ({ value: (Object.values(a)[0] && Object.values(a)[0]["v" + resp]) || 0 }))

    return(
        <div className="card shadow">
            <div className="titleblock">
                <Tooltip id={tipid} title={tip}>
                    <Icon style={{ width: 15, height: 15 }} type="info-circle" />
                    <h4>{title}</h4>
                </Tooltip>
            </div>
            { stars === false ?
                <div>
                    <div className="progresscontainer" style={{ minHeight: 175 }}>
                        <CircularIndicator
                        r={80}
                        strokeWidth={10}
                        percentaje={hayDatos ? redondear(currentVal) : undefined}
                        />
                    </div>
                </div> :
                <div>
                    <div className="progresscontainer" style={{ minHeight: 190 }}>
                        <Rate style={{ marginTop: -80 }} count={6} value={(currentVal / 20) + 1} allowHalf disabled />
                    </div>
                </div>
            }
            <div className="chartblock">
                <ResponsiveContainer width="50%" height={30} >
                    <LineChart data={meses}>
                        <Line type="monotone" dataKey={dataHistoricValKey} stroke={colorHitoricChart} strokeWidth={2} />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
};

export default CardNivel;