import { uniqBy, chunk, flattenDeep, partition } from 'lodash'
import XLSX from 'xlsx'
import request from './request'
import auth from './auth';
import moment from 'moment';
import { mayusculizer, getGrupo } from './func'
import { fetchDatos, fetchUsuarios, estaCargando, fetchConfig } from '../actions'
import { message, Modal } from 'antd'

const hadouken = async (uploaded, datos, fecha, roles, dispatch, config, usuarios) => {
    await dispatch(estaCargando(true))
    var file = uploaded[0], read = new FileReader();

    const body = new FormData()
    body.append("files", uploaded[0])
    request(process.env.REACT_APP_API_URL + "/upload", {
        method: "POST",
        body
    }, false).then(data => console.log(data)).catch(err => console.log(err))
    
    read.readAsBinaryString(file);
    read.onloadend = async () => {
        try{
            var oFile = XLSX.read(read.result, {type: 'binary' })
            const data = XLSX.utils.sheet_to_json(oFile.Sheets[oFile.SheetNames[0]], {cellNF:true, raw:false})

            //Comprobamos a ver si están todas las columnas que tienen que estar o si falta algún dato
            const comprob = [
                "CODUOCD", 
                "CODUOUD", 
                "CODUOUDIV", 
                "CODUOUDPT", 
                "CODUOUAREA", 
                "CODUOUSAREA", 
                "Es Manager", 
                "Cod Empleado", 
                "Nombre", 
                "Apellidos",
                "Correo Electrónico", 
                "Fecha Antigüedad", 
                "Posición",
                "Año",
                "Mes"
            ].map(campo => {
                return ({ [campo]: data.map((row, i) => {
                        if(row[campo] === undefined){ return i }
                        else{ return undefined }
                    }).filter(e => e)
                })
            })

            const columnasFaltan = comprob.filter(c => Object.values(c)[0].length === data.length - 1)
            const datosFaltan = comprob.filter(c => Object.values(c)[0].length > 0 && Object.values(c)[0].length < data.length - 1)

            //Si faltan cosas tira un error y se sale
            if(columnasFaltan.length > 0 || datosFaltan > 0){
                const modalDatos = () => {
                    if(datosFaltan.length > 0){ 
                        Modal.error({ title: "Error en la carga de datos", content: "Faltan datos en las siguientes columnas: " + datosFaltan.map(e => Object.keys(e)[0] + " (Fila " + Object.values(e)[0].reduce((a, b) => a + ", " + b, "").slice(2) + ")").reduce((a, b) => a + ", " + b, "").slice(2) })
                    }
                }

                if(columnasFaltan.length > 0 ){ 
                    Modal.error({ 
                        title: "Error en la carga de datos", 
                        content: "No se detectan las siguientes columnas en el archivo de carga: " + columnasFaltan.map(e => Object.keys(e)[0]).reduce((a, b) => a + ", " + b, "").slice(2),
                        onOk: () => modalDatos(),
                        onCancel: () => modalDatos()
                    })
                }
                else{ 
                    modalDatos()
                }
                console.log({ columnasFaltan, datosFaltan })
                throw new Error("Archivo inválido")
            }

            // Esto es para capar que solo cargue los datos de B2B pese a que suban la tabla entera
            // Realmente funciona perfectamente con todos los datos sin filtrar xD
            const dataFilter = config.soloB2B ? data.filter(d => d["CODUOUD"] === "CET562") : data

            if(!dataFilter[0]){
                await dispatch(estaCargando(false))
                message.error("El archivo a subir no contiene datos o la estructura de columnas es incorrecta.", 5)
            }
            else if(!dataFilter[0]["Mes"] || !dataFilter[0]["Año"]){
                await dispatch(estaCargando(false))
                message.error("El archivo a subir no contiene fechas. Añade las columnas Mes y Año y vuélvelo a intentar", 5)
            }
            else{
                const fechaUltimaEstructura = moment().utc().month(parseInt(dataFilter[0]["Mes"]) - 1).year(parseInt(dataFilter[0]["Año"])).startOf("month")
                console.log({ fechaUltimaEstructura })

                //Esta es la función principal, ver final del archivo para verle el sentido a la cosa
                const ahoraSi = async () => {
                    dispatch(estaCargando(true))
                    //Procesamos las filas del archivo para sacar los equipos del listado de usuarios
                    const equiposProcesados = uniqBy(dataFilter.map((fila, i) => {

                        if(i===0 && Object.keys(fila).some(k => k.includes("Ã"))){
                            message.error("La codificación de caracteres del archivo es incorrecta", 5)
                            dispatch(estaCargando(false))
                            throw new Error("Codificación de caracteres incompatible o incorrecta")
                        }
                        
                        // Estas líneas feas sacan los equipos a los que pertenecen los usuarios y meten el código del equipo al que pertenecen en la variable
                        // codigoUnidad, que es la que criba el uniqBy que arropa todo para dejar un usuario de cada equipo... porque realmente lo que queremos
                        // ahora son los equipos solo

                        // const cln  = fila["Código de UO por tipo de unidad Línea de negocio"]
                        const ccd  = fila["CODUOCD"] && fila["CODUOCD"].slice(3)
                        const cdir = fila["CODUOUD"] && fila["CODUOUD"].slice(3)
                        const cdiv = fila["CODUOUDIV"] && fila["CODUOUDIV"].slice(3)
                        const cdep = fila["CODUOUDPT"] && fila["CODUOUDPT"].slice(3)
                        const car  = fila["CODUOUAREA"] && fila["CODUOUAREA"].slice(3)
                        const csub = fila["CODUOUSAREA"] && fila["CODUOUSAREA"].slice(3)
                        // const dln  = fila["D Empresa"]
                        const dcd  = fila["Comité Dirección"]
                        const ddir = fila["Unidad Dirección"]
                        const ddiv = fila["Unidad División"]
                        const ddep = fila["Unidad Departamento"]
                        const dar  = fila["Unidad Área"]
                        const dsub = fila["Unidad Subárea"]
                        
                        const codigoUnidad = (ccd  === cdir) ? ccd  : (
                                    (cdir === cdiv) ? cdir : (
                                    (cdiv === cdep) ? cdiv : (
                                    (cdep === car ) ? cdep : (
                                    (car  === csub) ? car  : csub 
                        ))))

                        // El codigoPadre se usará despues para definir las jerarquías, es el código del equipo inmediato superior
                        const codigoPadre = (ccd  === cdir) ? "qwe"  : (
                        (cdir === cdiv) ? ccd : (
                        (cdiv === cdep) ? cdir : (
                        (cdep === car ) ? cdiv : (
                        (car  === csub) ? cdep  : car
                        ))))

                        const denominacionUnidad = (ccd  === cdir) ? dcd  : (
                                    (cdir === cdiv) ? ddir : (
                                    (cdiv === cdep) ? ddiv : (
                                    (cdep === car ) ? ddep : (
                                    (car  === csub) ? dar  : dsub 
                        ))))

                        const jerarquia = (ccd  === cdir) ? "comite"  : (
                        (cdir === cdiv) ? "direccion" : (
                        (cdiv === cdep) ? "division" : (
                        (cdep === car ) ? "departamento" : (
                        (car  === csub) ? "area" : "subarea"
                        ))))

                        return {
                        ...fila,
                        denominacionUnidad,
                        codigoUnidad,
                        codigoPadre, 
                        jerarquia }
                    }), "codigoUnidad")

                    //Organizamos los equipos por nivel de jerarquia dentro de la organización
                    let eqPorJerarquia = { comites: [], direccions: [], divisions: [], departamentos: [], areas: [], subareas: []}
                    equiposProcesados.forEach(e => {
                        if(e.jerarquia === "comite")      { eqPorJerarquia.comites = [...eqPorJerarquia.comites, e]}
                        if(e.jerarquia === "direccion")   { eqPorJerarquia.direccions = [...eqPorJerarquia.direccions, e]}
                        if(e.jerarquia === "division")    { eqPorJerarquia.divisions = [...eqPorJerarquia.divisions, e]}
                        if(e.jerarquia === "departamento"){ eqPorJerarquia.departamentos = [...eqPorJerarquia.departamentos, e]}
                        if(e.jerarquia === "area")        { eqPorJerarquia.areas = [...eqPorJerarquia.areas, e]}
                        if(e.jerarquia === "subarea")     { eqPorJerarquia.subareas = [...eqPorJerarquia.subareas, e]}
                    })

                    // datos.plano contiene todos los equipos actualmente en el sistema sin jerarquizar, la siguiente variable comprueba
                    // que equipos de los que están ahora mismo no están en el nuevo organigrama
                    // Vamos, los que hay que fusilar
                    const equiposDesactivar = datos.plano.filter(s => !equiposProcesados.map(e => e.codigoUnidad).includes(s.idcet))

                    // De los equipos que sí están en el nuevo organigrama puede ser que estén ya en el viejo y haya que editarlos o que no y que haya que crearlos
                    const arrayidcet = datos.plano.map(e => e.idcet)
                    const [comitesEdit, comitesNuevos] = partition(eqPorJerarquia.comites, c => arrayidcet.includes(c.codigoUnidad))
                    const [direccionesEdit, direccionesNuevas] = partition(eqPorJerarquia.direccions, d => arrayidcet.includes(d.codigoUnidad))
                    const [divisionesEdit, divisionesNuevas] = partition(eqPorJerarquia.divisions, d => arrayidcet.includes(d.codigoUnidad))
                    const [departamentosEdit, departamentosNuevos] = partition(eqPorJerarquia.departamentos, d => arrayidcet.includes(d.codigoUnidad))
                    const [areasEdit, areasNuevas] = partition(eqPorJerarquia.areas, a => arrayidcet.includes(a.codigoUnidad))

                    
                    // Ahora un buen chorro de líneas son símplemente las requests de todo lo que hay que hacer sobre los equipos
                    // Es importante el orden de las requests y que se vayan esperando bien las unas a las otras, si no se ha creado el padre
                    // no se le puede vincular el hijo
                    const promDesact = equiposDesactivar.map(e => {
                        // if(e.jerarquia === "comite")           { return request(process.env.REACT_APP_API_URL + "/comitedireccions/" + e._id, { method: "PUT", body: { activo: false }}) }
                        if(e.jerarquia === "direccion")   { return request(process.env.REACT_APP_API_URL + "/direccions/"       + e._id, { method: "PUT", body: { activo: false }}) }
                        else if(e.jerarquia === "division")    { return request(process.env.REACT_APP_API_URL + "/divisions/"        + e._id, { method: "PUT", body: { activo: false }}) }
                        else if(e.jerarquia === "departamento"){ return request(process.env.REACT_APP_API_URL + "/departamentos/"    + e._id, { method: "PUT", body: { activo: false }}) }
                        else if(e.jerarquia === "area")        { return request(process.env.REACT_APP_API_URL + "/unidadareas/"      + e._id, { method: "PUT", body: { activo: false }}) }
                        else{ return undefined }
                    })
                    
                    const eqDesact = await Promise.all(promDesact)

                    console.log({ eqDesact })
                    console.log({ comitesNuevos, direccionesNuevas, divisionesNuevas, departamentosNuevos, areasNuevas })
                    console.log({ comitesEdit, direccionesEdit, divisionesEdit, departamentosEdit, areasEdit })

                    // const promComitesNuevos = comitesNuevos.map( c => request(process.env.REACT_APP_API_URL + "/comitedireccions", {
                    //   method: "POST",
                    //   body: {
                    //     nombre: c.denominacionUnidad,
                    //     idcet: c.codigoUnidad,
                    //     activo: true
                    //   }
                    // }))
                    // const respComitesNuevos = await Promise.all(promComitesNuevos)

                    let equiposActuales = datos.plano
                    // let equiposActuales = [...datos.plano, ...respComitesNuevos]

                    const promDireccionesNuevas = direccionesNuevas.map( d => {
                        const padre = equiposActuales.find(e => e.idcet === d.codigoPadre)
                        return request(process.env.REACT_APP_API_URL + "/direccions", {
                        method: "POST",
                        body: {
                            nombre: d.denominacionUnidad,
                            idcet: d.codigoUnidad,
                            comitedireccion: datos.arbol[0]._id,
                            // comitedireccion: padre && padre._id,
                            activo: true
                        }
                        })
                    })
                    const respDireccionesNuevas = await Promise.all(promDireccionesNuevas)

                    equiposActuales = [...equiposActuales, ...respDireccionesNuevas]

                    const promDivisionesNuevas = divisionesNuevas.map( d => {
                        const padre = equiposActuales.find(e => e.idcet === d.codigoPadre)
                        return request(process.env.REACT_APP_API_URL + "/divisions", {
                        method: "POST",
                        body: {
                            nombre: d.denominacionUnidad,
                            idcet: d.codigoUnidad,
                            direccion: padre && padre._id,
                            activo: true,
                            coordenadas: {
                            "lat": 40.416966,
                            "lng": -3.7039702
                            }
                        }
                        })
                    })
                    const respDivisionesNuevas = await Promise.all(promDivisionesNuevas)

                    equiposActuales = [...equiposActuales, ...respDivisionesNuevas]

                    const promDepartamentosNuevos = departamentosNuevos.map( d => {
                        const padre = equiposActuales.find(e => e.idcet === d.codigoPadre)
                        return request(process.env.REACT_APP_API_URL + "/departamentos", {
                        method: "POST",
                        body: {
                            nombre: d.denominacionUnidad,
                            idcet: d.codigoUnidad,
                            division: padre && padre._id,
                            activo: true
                        }
                        })
                    })
                    const respDepartamentosNuevos = await Promise.all(promDepartamentosNuevos)

                    equiposActuales = [...equiposActuales, ...respDepartamentosNuevos]

                    const promAreasNuevas = areasNuevas.map( a => {
                        const padre = equiposActuales.find(e => e.idcet === a.codigoPadre)
                        return request(process.env.REACT_APP_API_URL + "/unidadareas", {
                        method: "POST",
                        body: {
                            nombre: a.denominacionUnidad,
                            idcet: a.codigoUnidad,
                            departamento: padre && padre._id,
                            division: padre && padre._id,
                            activo: true
                        }
                        })
                    })
                    const respAreasNuevas = await Promise.all(promAreasNuevas)
                    console.log({ respAreasNuevas })

                    //Ahora los equipos a editar

                    // const promComitesEdit = comitesEdit.map( c => {
                    //   const id = datos.plano.find(orig => orig.idcet === c.CODUOCD.slice(3))._id
                    //   return request(process.env.REACT_APP_API_URL + "/comitedireccions/" + id, {
                    //     method: "PUT",
                    //     body: {
                    //       nombre: c.denominacionUnidad,
                    //       activo: true
                    //     }
                    //   })
                    // })
                    // const respComitesEdit = await Promise.all(promComitesEdit)

                    // equiposActuales = [...datos.plano.filter(c => !respComitesEdit.map(ce => ce.idcet).includes(c.idcet)), ...respComitesEdit]

                    const promDireccionesEdit = direccionesEdit.map( d => {
                        const id = datos.plano.find(orig => orig.idcet === d.CODUOUD.slice(3))._id
                        const padre = equiposActuales.find(e => e.idcet === d.codigoPadre)
                        return request(process.env.REACT_APP_API_URL + "/direccions/" + id, {
                        method: "PUT",
                        body: {
                            nombre: d.denominacionUnidad,
                            comitedireccion: padre && padre._id,
                            activo: true
                        }
                        })
                    })
                    const respDireccionesEdit = await Promise.all(promDireccionesEdit)

                    equiposActuales = [...datos.plano.filter(d => !respDireccionesEdit.map(de => de.idcet).includes(d.idcet)), ...respDireccionesEdit]

                    const promDivisionesEdit = divisionesEdit.map( d => {
                        const id = datos.plano.find(orig => orig.idcet === d.CODUOUDIV.slice(3))._id
                        const padre = equiposActuales.find(e => e.idcet === d.codigoPadre)
                        return request(process.env.REACT_APP_API_URL + "/divisions/" + id, {
                        method: "PUT",
                        body: {
                            nombre: d.denominacionUnidad,
                            direccion: padre && padre._id,
                            activo: true
                        }
                        })
                    })
                    const respDivisionesEdit = await Promise.all(promDivisionesEdit)

                    equiposActuales = [...datos.plano.filter(d => !respDivisionesEdit.map(de => de.idcet).includes(d.idcet)), ...respDivisionesEdit]

                    const promDepartamentosEdit = departamentosEdit.map( d => {
                        const id = datos.plano.find(orig => orig.idcet === d.CODUOUDPT.slice(3))._id
                        const padre = equiposActuales.find(e => e.idcet === d.codigoPadre)
                        return request(process.env.REACT_APP_API_URL + "/departamentos/" + id, {
                        method: "PUT",
                        body: {
                            nombre: d.denominacionUnidad,
                            division: padre && padre._id,
                            activo: true
                        }
                        })
                    })
                    const respDepartamentosEdit = await Promise.all(promDepartamentosEdit)

                    equiposActuales = [...datos.plano.filter(d => !respDepartamentosEdit.map(de => de.idcet).includes(d.idcet)), ...respDepartamentosEdit]

                    const promAreasEdit = areasEdit.map( a => {
                        const id = datos.plano.find(orig => orig.idcet === a.CODUOUAREA.slice(3))._id
                        const padre = equiposActuales.find(e => e.idcet === a.codigoPadre)
                        return request(process.env.REACT_APP_API_URL + "/unidadareas/" + id, {
                        method: "PUT",
                        body: {
                            nombre: a.denominacionUnidad,
                            departamento: padre && padre._id,
                            division: padre && padre._id,
                            activo: true
                        }
                        })
                    })
                    const respAreasEdit = await Promise.all(promAreasEdit)
                    console.log({ respAreasEdit })

                    await dispatch(fetchDatos(auth.getUserInfo().role.name, getGrupo(auth.getUserInfo().role.name), fecha, { noQuitarCargando: true }))

                    // Hasta aquí llega la carga de equipos, el dispatch con el await hace que los equipos ya estén actualizados para tenerlos a punto
                    // para meter los usuarios, que vienen ahora

                    console.log({ datosPlano: datos.plano })
                    // Se filtran los becarios porque no suelen tener email y me joden el invento
                    //const usuariosProcesados = dataFilter.filter(fila => fila["Puesto"] && !(fila["Puesto"].toLowerCase().includes("becario"))).map( fila => {
                    const usuariosProcesados = dataFilter.map( fila => {
                        const ccd  = fila["CODUOCD"] && fila["CODUOCD"].slice(3)
                        const cdir = fila["CODUOUD"] && fila["CODUOUD"].slice(3)
                        const cdiv = fila["CODUOUDIV"] && fila["CODUOUDIV"].slice(3)
                        const cdep = fila["CODUOUDPT"] && fila["CODUOUDPT"].slice(3)
                        const car  = fila["CODUOUAREA"] && fila["CODUOUAREA"].slice(3)
                        const csub = fila["CODUOUSAREA"] && fila["CODUOUSAREA"].slice(3)
                        
                        
                        const codigoUnidad = (ccd  === cdir) ? ccd  : (
                                    (cdir === cdiv) ? cdir : (
                                    (cdiv === cdep) ? cdiv : (
                                    (cdep === car ) ? cdep : (
                                    (car  === csub) ? car  : csub 
                        ))))
                        // console.log({ codigoUnidad })

                        const grupo = datos.plano.find(g => g.idcet === codigoUnidad) || {}

                        //Nadie debería salir por aquí pero siempre hay algún gilipollas
                        if(grupo === undefined){ console.log(fila) }
                        
                        //Esta puede que sea de las líneas de código más chorras que haya escrito nunca
                        // const esJefe = fila["Puesto"].toLowerCase().includes("jef") || fila["Puesto"].toLowerCase().includes("direc")

                        const esJefe = ["si", "sí"].includes(fila["Es Manager"].toLowerCase())

                        //Se asigna su rol en base al nivel de jerarquía de su equipo y de si su puesto parece ser jefoso
                        const rol = esJefe ? (
                            grupo.jerarquia === "comitedireccion" ? roles.find(r => r.name === "Administrator") : (
                            grupo.jerarquia === "direccion" ? roles.find(r => r.name === "BAMDireccion") : (
                            grupo.jerarquia === "division" ? roles.find(r => r.name === "BAMDivision") : (
                            grupo.jerarquia === "departamento" ? roles.find(r => r.name === "BAMDepartamento") : (
                            grupo.jerarquia === "area" ? roles.find(r => r.name === "BAMArea") : undefined 
                        ))))) : roles.find(r => r.name === "BAMAgente")
                        
                        const reset = {
                            direccionagente: null,
                            divisionagente: null,
                            departamentoagente: null,
                            areaagente: null,
                            comitedireccions: [],
                            direccions: [],
                            divisions: [],
                            departamentos: [],
                            unidadareas: []
                        }
                        // Y en base a lo anterior preparamos la relación que sea
                        const pertenencia = esJefe ? (
                            grupo.jerarquia === "comitedireccion" ? { 
                                ...reset,
                                comitedireccions: [grupo._id],
                            } : (
                            grupo.jerarquia === "direccion" ? { 
                                ...reset,
                                direccions: [grupo._id],
                            } : (
                            grupo.jerarquia === "division" ? { 
                                ...reset,
                                divisions: [grupo._id],
                            } : (
                            grupo.jerarquia === "departamento" ? { 
                                ...reset,
                                departamentos: [grupo._id],
                            } : (
                            grupo.jerarquia === "area" ? { 
                                ...reset,
                                unidadareas: [grupo._id],
                            } : undefined 
                        ))))) : (
                            grupo.jerarquia === "direccion" ? { 
                                ...reset,
                                direccionagente: grupo._id,
                            } : (
                            grupo.jerarquia === "division" ? { 
                                ...reset,
                                divisionagente: grupo._id,
                            } : (
                            grupo.jerarquia === "departamento" ? { 
                                ...reset,
                                departamentoagente: grupo._id,
                            } : (
                            grupo.jerarquia === "area" ? { 
                                ...reset,
                                areaagente: grupo._id,
                            } : undefined 
                        ))))

                        // console.log({ ...fila, grupo, codigoUnidad, esJefe, rol, pertenencia })

                        // Usuario listo para tirarselo a mongo!
                        return {
                            idusuario: fila["Cod Empleado"].toLowerCase(),
                            username: fila["Cod Empleado"].toLowerCase(),
                            name: mayusculizer(fila["Nombre"]),
                            lastname: mayusculizer(fila["Apellidos"]),
                            email: fila["Correo Electrónico"] ? fila["Correo Electrónico"].toLowerCase().trim() : "",
                            codigoUnidad,
                            esJefe,
                            grupo: grupo._id,
                            role: rol._id,
                            fechaContrato: moment(fila["Fecha Antigüedad"], "MM/DD/YYYY HH:mm:SS").utc().format(),
                            puesto: fila["Posición"],
                            ...pertenencia
                        }
                    })

                    // Igual que con los equipos, separamos los usuarios a desactivar, a crear y a editar en base a si existían o no y si existen o no ahora
                    // Importante filtrar solo los roles de BAM entre la lista de usuarios actuales o nos cargaremos los usuarios con rol de CET
                    const usuariosDesact = usuarios.filter(u => !(usuariosProcesados.map(up => up.idusuario).includes(u.idusuario)) && ["BAMAgente", "BAMArea", "BAMDepartamento", "BAMDivision", "BAMDireccion"].includes(u.role.name))
                    const usuariosEdit = usuariosProcesados.filter(up => usuarios.map(u => u.idusuario).includes(up.idusuario))
                    // const usuariosEdit = usuarios.filter(u => usuariosProcesados.map(up => up.idusuario).includes(u.idusuario) && ["BAMAgente", "BAMArea", "BAMDepartamento", "BAMDivision", "BAMDireccion"].includes(u.role.name))
                    const usuariosCrear = usuariosProcesados.filter(up => !(usuarios.map(u => u.idusuario).includes(up.idusuario)))

                    console.log(usuariosProcesados)
                    console.log(usuarios)
                    console.log({ usuariosDesact, usuariosEdit, usuariosCrear })

                    var arrayResp = []
                    var errors = []

                    // Y ala, request a cascoporro, divididitas en chunks para que strapi no se angustie
                    const usuariosDesactChunks = chunk(usuariosDesact, 50)
                    for(const chunk of usuariosDesactChunks){
                        const promChunkiDesact = chunk.map(u => {
                            const user = usuarios.filter(u => u.idusuario).find(orig => orig.idusuario.toLowerCase() === u.idusuario)
                            const body = {
                                confirmed: false,
                                blocked: true
                            }
                            if(user){
                                return request(process.env.REACT_APP_API_URL + "/users/" + user._id, {
                                method: "PUT",
                                body
                                }).then(data => {
                                    return ({ ...data, ok: true })
                                }).catch(err => {
                                    errors.push({ body, err })
                                    return ({ ...data, ok: false })
                                })  
                            } else { return undefined }
                        })
                        const respChunkiDesact = await Promise.all(promChunkiDesact)
                        arrayResp.push(respChunkiDesact)
                    }

                    const usuariosEditChunks = chunk(usuariosEdit, 50)
                    for(const chunk of usuariosEditChunks){
                        const promChunkiEdit = chunk.map(u => {
                            const role = roles.find(r => r.name === u.role.name)
                            const user = usuarios.find(orig => {
                                if(orig.idusuario === null){
                                    console.log(orig)
                                }
                                else{
                                    return (typeof orig.idusuario === "string" && orig.idusuario.toLowerCase() === u.idusuario)
                                }
                            })
                            // if(user === undefined){ console.log({ user: u })}
                            if(user.email === u.email){ delete u.email }
                            const body = {
                                ...u,
                                role,
                                confirmed: true,
                                blocked: false,
                            }
                            if(user){
                                return request(process.env.REACT_APP_API_URL + "/users/" + user._id, {
                                    method: "PUT",
                                    body
                                }).then(data => {
                                    return ({ ...data, ok: true })
                                }).catch(err => {
                                    errors.push({ body, err })
                                    return ({ ...data, ok: false })
                                })  
                            } else { return undefined }
                        })
                        const respChunkiEdit = await Promise.all(promChunkiEdit)
                        arrayResp.push(respChunkiEdit)
                    }

                    const usuariosCrearChunks = chunk(usuariosCrear, 50)
                    for(const chunk of usuariosCrearChunks){
                        const promChunkiCrear = chunk.map(u => {
                            const body = {
                                ...u,
                                password: "cetelem",
                                confirmed: true,
                                blocked: false,
                                acceso: {
                                "bam": true,
                                "cet": false,
                                "rvi": false
                                },
                                tieneQueCambiarPw: true,
                            }
                            return request(process.env.REACT_APP_API_URL + "/users", {
                                method: "POST",
                                body
                            }).then(data => {
                                return ({ ...data, ok: true })
                            }).catch(err => {
                                errors.push({ body, err })
                                return ({ ...data, ok: false })
                            })  
                        })

                        const respChunkiCrear = await Promise.all(promChunkiCrear)
                        arrayResp.push(respChunkiCrear)
                    }

                    if(flattenDeep(arrayResp).map(r => r && r.ok).some(r => r === false)){ 
                        message.warning("Algunos registros fallaron al cargarse") 
                    }
                    else{
                        message.success("La carga de usuarios se completó satisfactoriamente") 
                    }

                    // Si todo va bien manda un correo de que todo chachi y actualiza la fecha de última estructura subida
                    const mailResp = await Promise.all(config.correosAvisarNuevaEstructura.map(to => (
                        request(process.env.REACT_APP_API_URL + "/email", {
                            method: "POST",
                            body: {
                                from: config.correobam,
                                to,
                                subject: "Nueva estructura en BAM",
                                body: `Hola, se ha subido una nueva estructura a BAM, el sistema está listo para recibir los datos de ${moment(fechaUltimaEstructura).format("MMMM YYYY")}`,
                                html: `<p>Hola, se ha subido una nueva estructura a BAM, el sistema está listo para recibir los datos de ${moment(fechaUltimaEstructura).format("MMMM YYYY")}</p><hr /><h6>Área de inteligencia de BAM</h6>`
                            }
                        }).catch(err => console.log(err))
                    )))
                    console.log(config.correosAvisarNuevaEstructura)
                    request(process.env.REACT_APP_API_URL + "/configs/" + config._id, {
                        method: "PUT",
                        body: {
                            fechaUltimaEstructura,
                            fechaSubidaUltimaEstructura: moment().utc().format()
                        }
                    }).then(data => {
                        dispatch(fetchConfig())
                    }).catch(err => console.log(err))

                    console.log({ arrayResp: flattenDeep(arrayResp), errors })
                    dispatch(fetchDatos(auth.getUserInfo().role.name, getGrupo(auth.getUserInfo().role.name), fecha))
                    dispatch(fetchUsuarios(auth.getUserInfo().role.name, getGrupo(auth.getUserInfo().role.name)))
                
                }

                // Para intentar que no rompan las cosas comprobamos antes de hacer nada si la estructura que intentan subir es anterior a la actual
                // Si están intentando subir datos viejos, el sistema te permite hacerlo (para debug y desarrollo viene bien) pero te pide confirmación
                if(fechaUltimaEstructura.isBefore(config.fechaUltimaEstructura)){
                    dispatch(estaCargando(false))
                    Modal.confirm({
                    title: "El archivo seleccionado contiene una estructura anterior a la actual. ¿Estás seguro de que deseas continuar?",
                    onOk: ahoraSi
                    })
                }
                else{
                    dispatch(estaCargando(false))
                    ahoraSi()
                }
            }
        }
        catch(err){
            await dispatch(estaCargando(false))
            console.log(err)
            if(err.message === "File is password-protected"){
                message.error("Los archivos protegidos con contraseña no son soportados por BAM. Desproteja el archivo e inténtelo de nuevo", 5)
            }
        }
    }
  }

  export default hadouken