import React, { Component } from 'react'
import { Col, Row, Container } from 'react-grid-system'
import FichaFoto from '../components/FichaFoto'
import SpiderBlock from '../components/SpiderBlock'
import BarFiltro from '../components/BarFiltro'
import CardNivel from '../components/CardNivel'
import Ranking from '../components/Ranking'
import ButtonBlock from '../components/ButtonBlock'
import CardTeam from '../components/CardTeam'
import Loader from '../components/Loader'
import { connect } from 'react-redux'
import ModalEditarDatos from '../components/ModalEditarDatos'
import ModalValoracion from '../components/ModalValoracion'
import ModalComentario from '../components/ModalComentario';
import { fetchDatos, fetchMedias } from '../actions'
import auth from '../utils/auth'
import moment from 'moment'
import { mediaVariosMeses, getGrupo, getBaseURL } from '../utils/func'
import ModalCambiarPw from '../components/ModalCambiarPw';

class DepartamentoPage extends Component {
  state = {
    departamento: {
      nombre: "",
      managerdepartamento: [{
        name: "",
        lastname: ""
      }],
      unidadareas: [],
      medias: {}
    },
    rankingSelected: {"NEG": 1},
    equipoactivo: {Activos: "Activos"}
  }

  async componentDidMount(){
    if(Array.isArray(this.props.datos.plano) && this.props.medias){
      const departamento = this.props.datos.plano.find(d => d.idcet.toLowerCase() === this.props.match.params.idDepartamento)
      if(!departamento){ this.props.history.push(await getBaseURL()) }      
      else{
        if(Array.isArray(departamento.agentes)){ departamento.agentes = departamento.agentes.filter(a => !a.blocked) }
        if(Array.isArray(departamento.unidadareas)){ departamento.unidadareas = departamento.unidadareas.filter(d => d.activo || (Array.isArray(d.datobams) && d.datobams.length > 0)) }
        this.setState({ departamento })
        this.seteoInicial(departamento)
      }
    }
  }
  
  async componentDidUpdate(prevProps){
    if((prevProps.datos !== this.props.datos || this.props.medias !== prevProps.medias) && Array.isArray(this.props.datos.plano) && this.props.medias ){
      const departamento = this.props.datos.plano.find(d => d.idcet.toLowerCase() === this.props.match.params.idDepartamento)
      // console.log({ departamento })
      if(!departamento){ this.props.history.push(await getBaseURL()) }      
      else{
        if(Array.isArray(departamento.agentes)){ departamento.agentes = departamento.agentes.filter(a => !a.blocked) }
        if(Array.isArray(departamento.unidadareas)){ departamento.unidadareas = departamento.unidadareas.filter(d => d.activo || (Array.isArray(d.datobams) && d.datobams.length > 0)) }
        this.setState({ departamento })
        this.seteoInicial(departamento)
      }
    }
    if(this.props.fecha !== prevProps.fecha){
      this.props.dispatch(fetchDatos(auth.getUserInfo().role.name, getGrupo(auth.getUserInfo().role.name), this.props.fecha))
      this.props.dispatch(fetchMedias(auth.getUserInfo().role.name, this.props.fecha))
    }
  }

  seteoInicial = departamento => {
    const managerStr = Object.keys(this.props.datos.arbol[0]).find(k => k.slice(0, 7) === "manager")
    if(auth.getUserInfo().tieneQueCambiarPw){
      this.setState({ modalCambiarPw: true })
    }
    this.procesaMedias(departamento)
    try{
      if((this.props.datos.arbol[0][managerStr][0]._id === auth.getUserInfo()._id) && 
        auth.getUserInfo().entraEncuesta && 
        !(this.props.location.state && !this.props.location.state.sinEncuesta) &&
        this.props.datos.arbol[0].agentes.length > 0){
        this.setState({ modalValoracion: true })
      }
    }
    catch(err){}
  }

  procesaMedias = departamento => {
    const unMes = moment(this.props.fecha.desde).isSame(this.props.fecha.hasta, "month")
    const areas = departamento.unidadareas
    const mar = this.props.medias.filter(m => m.unidadarea).filter(m => moment(m.fecha).isSameOrAfter(this.props.fecha.desde, "month") && moment(m.fecha).isSameOrBefore(this.props.fecha.hasta, "month"))
    const mediaPorArea = areas.map(a => {
      const mdFilt = mar.filter(m => {
        if(!unMes){ return m.unidadarea._id === a._id }
        else{ return m.unidadarea._id === a._id && moment(m.fecha).isSame(this.props.fecha.desde, "month") }
      })
      return { 
        _id: a._id, 
        mediasMensuales: mdFilt.map(m => ({[moment.utc(m.fecha).format()]: m})).reduce((a, b) => ({...a, ...b}), {}), 
        medias: mediaVariosMeses(mdFilt)
      }
    })
    const agentes = departamento.agentes
    const muser = this.props.medias.filter(m => m.user).filter(m => moment(m.fecha).isSameOrAfter(this.props.fecha.desde, "month") && moment(m.fecha).isSameOrBefore(this.props.fecha.hasta, "month"))
    const mediaPorAgente = agentes.map(a => {
      const mdFilt = muser.filter(m => {
        if(!unMes){ return m.user._id === a._id }
        else{ return m.user._id === a._id && moment(m.fecha).isSame(this.props.fecha.desde, "month") }
      })
      return { 
        _id: a._id, 
        mediasMensuales: mdFilt.map(m => ({[moment.utc(m.fecha).format()]: m})).reduce((a, b) => ({...a, ...b}), {}), 
        medias: mediaVariosMeses(mdFilt)
      }
    })
    const mdep = this.props.medias.filter(m => m.departamento)
                                  .filter(m => m.departamento._id === departamento._id)
                                  .filter(m => moment(m.fecha).isSameOrAfter(unMes ? moment(this.props.fecha.desde).subtract(6, "months") : this.props.fecha.desde, "month") && moment(m.fecha).isSameOrBefore(this.props.fecha.hasta, "month"))
    const mediaGlobal = {
      mediasMensuales: mdep.map(m => ({[moment.utc(m.fecha).format()]: m})).reduce((a, b) => ({...a, ...b}), {}), 
      medias: mediaVariosMeses(mdep.filter(m => !unMes ? mdep : mdep && moment(m.fecha).isSame(this.props.fecha.desde, "month")))
    }
    this.setState({ mediaPorArea, mediaPorAgente, mediaGlobal })
  }

  abreModal = (datos, accionModal) => {
    this.setState({
      modalComentario: true,
      datosModalComentario: datos,
      accionModal
    })
  }

  render() {
    const { departamento } = this.state

    return (
      <div>
        <Loader loading={this.props.cargando} />
        <Container fluid style={{ margin: 0, padding: 0 }}>
          <Row style={{ margin: 0, padding: 0 }}>
            <Col style={{ margin: 0, padding: 0 }}>
              <BarFiltro
                breadcrumbs={{
                  comite: departamento.comite, 
                  direccion: departamento.direccion, 
                  division: departamento.division,
                  departamento: { nombre: departamento.nombre, idcet: departamento.idcet }
                }}
              />
            </Col>
          </Row>
        </Container>

        <Container style={{ marginTop: 50 }}>
          <Row debug>
            <Col md={6}>
              <FichaFoto
                img={
                  departamento.picture
                    ? process.env.REACT_APP_API_URL +
                      departamento.picture.url
                    : "https://images.pexels.com/photos/380768/pexels-photo-380768.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                }
                name={departamento.nombre}
                puesto={
                  "Jefe de equipo: " +
                  (departamento.managerdepartamento &&
                  departamento.managerdepartamento[0]
                    ? departamento.managerdepartamento[0].name +
                      " " +
                      departamento.managerdepartamento[0].lastname
                    : "")
                }
                alturatit={150}
                alturafoto={250}
                mostrarBtn={true}
                action={() => this.setState({ modalEdicion: true })}
                actionTitle="Editar datos"
                action2={() => this.abreModal(departamento, "ver")}
                actionTitle2="Comentarios"
              />
            </Col>
            <Col md={6}>
              <SpiderBlock data={this.state.mediaGlobal && this.state.mediaGlobal.medias} height={400} />
            </Col>
          </Row>
        </Container>

        <Container
          style={{
            marginTop: 100,
            padding: 30,
            border: "3px solid gainsboro",
            borderRadius: 25,
            height: 750,
            marginBottom: 50
          }}
        >
          <Row justify="center">
            <Col
              md={5}
              style={{
                marginTop: -50,
                marginBottom: 30,
                textAlign: "center",
                backgroundColor: "white"
              }}
            >
              <h2>Media Nivel x Responsabilidad</h2>
            </Col>
          </Row>
          <Row justify="center">
            <Col md={3}>
              <CardNivel
                title="Garantizar el cumplimiento de los objetivos principales"
                currentVal={ this.state.mediaGlobal && this.state.mediaGlobal.medias[1] } 
                historicVal={ this.state.mediaGlobal && this.state.mediaGlobal.mediasMensuales } 
                datobams={departamento.datobams}
                fecha={this.props.fecha}
                resp={1}
              />
            </Col>
            <Col md={3}>
              <CardNivel
                title="Garantizar el cumplimiento de los objetivos intermedios"
                currentVal={ this.state.mediaGlobal && this.state.mediaGlobal.medias[2] } 
                historicVal={ this.state.mediaGlobal && this.state.mediaGlobal.mediasMensuales } 
                datobams={departamento.datobams}
                fecha={this.props.fecha}
                resp={2}
              />
            </Col>
            <Col md={3}>
              <CardNivel
                title="Garantizar la satisfacción del cliente"
                currentVal={ this.state.mediaGlobal && this.state.mediaGlobal.medias[3] } 
                historicVal={ this.state.mediaGlobal && this.state.mediaGlobal.mediasMensuales } 
                datobams={departamento.datobams}
                fecha={this.props.fecha}
                resp={3}
              />
            </Col>
          </Row>
          <Row justify="center" style={{ marginTop: 50 }}>
            <Col md={3}>
              <CardNivel
                title="Aprovechamiento de las acciones formativas"
                currentVal={ this.state.mediaGlobal && this.state.mediaGlobal.medias[4] } 
                historicVal={ this.state.mediaGlobal && this.state.mediaGlobal.mediasMensuales } 
                datobams={departamento.datobams}
                fecha={this.props.fecha}
                resp={4}
              />
            </Col>
            <Col md={3}>
              <CardNivel
                title="Garantizar el cumplimiento normativo"
                currentVal={ this.state.mediaGlobal && this.state.mediaGlobal.medias[5] } 
                historicVal={ this.state.mediaGlobal && this.state.mediaGlobal.mediasMensuales } 
                datobams={departamento.datobams}
                fecha={this.props.fecha}
                resp={5}
              />
            </Col>
            <Col md={3}>
              <CardNivel
                stars={true}
                title="Valoración del manager"
                currentVal={ this.state.mediaGlobal && this.state.mediaGlobal.medias[6] } 
                historicVal={ this.state.mediaGlobal && this.state.mediaGlobal.mediasMensuales } 
                datobams={departamento.datobams}
                fecha={this.props.fecha}
                resp={6}
              />
            </Col>
          </Row>
        </Container>

        {Array.isArray(departamento.unidadareas) &&
          departamento.unidadareas.length > 0 && [
            <Container key="area1" style={{ marginTop: 100 }}>
              <Row align="center" style={{ marginBottom: "1em" }}>
                <Col md={4}>
                  <h2 style={{ textAlign: "left" }}>Ranking de Areas</h2>
                </Col>
                <Col offset={{ md: 3 }} md={5}>
                  <ButtonBlock
                    changeRankingSelected={rankingSelected =>
                      this.setState({ rankingSelected })
                    }
                    rankingSelected={
                      Object.keys(this.state.rankingSelected)[0]
                    }
                    indicadores={[
                      { NEG: 1 },
                      { INT: 2 },
                      { CAL: 3 },
                      { FOR: 4 },
                      { NOR: 5 },
                      { MAN: 6 }
                    ]}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Ranking
                    tipo="area"
                    dataRanking={departamento.unidadareas}
                    medias={this.state.mediaPorArea} 
                    height={300}
                    rankingSelected={Object.values(
                      this.state.rankingSelected
                    )}
                    placeholder="/stickmangroup.jpg"
                  />
                </Col>
              </Row>
            </Container>,
            <Container
              key="area2"
              style={{ marginTop: 50, marginBottom: 50 }}
            >
              <Row>
                <Col md={4}>
                  <h2>Areas</h2>
                </Col>
              </Row>
              <Row>
                {departamento.unidadareas.map((a, i) => (
                  <Col key={i} md={3}>
                      <CardTeam
                        indicator={a.estado}
                        image={
                          a.picture
                            ? process.env.REACT_APP_API_URL + a.picture.url
                            : ""
                        }
                        title={a.nombre}
                        subtitle={a.jefe}
                        abreModal={this.abreModal}
                        datos={a}
                        comentarios={a.comentarios}
                        dataIndicadores={Array.isArray(this.state.mediaPorArea) && this.state.mediaPorArea.find(m => m._id === a._id).medias} 
                        linkTo={"/area/" + a.idcet.toLowerCase()}
                      />
                  </Col>
                ))}
              </Row>
            </Container>
          ]}

        {Array.isArray(departamento.agentes) &&
          departamento.agentes.length > 0 && (
            <div>
              <Container style={{ marginTop: 200 }}>
                <Row align="center" style={{ marginBottom: "1em" }}>
                  <Col md={4}>
                    <h2 style={{ textAlign: "left" }}>Ranking de Agentes</h2>
                  </Col>
                  <Col offset={{ md: 3 }} md={5}>
                    <ButtonBlock
                      changeRankingSelected={rankingSelected =>
                        this.setState({ rankingSelected })
                      }
                      rankingSelected={
                        Object.keys(this.state.rankingSelected)[0]
                      }
                      indicadores={[
                        { NEG: 1 },
                        { INT: 2 },
                        { CAL: 3 },
                        { FOR: 4 },
                        { NOR: 5 },
                        { MAN: 6 }
                      ]}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Ranking
                      tipo="agente"
                      dataRanking={departamento.agentes.filter(a => !a.blocked)}
                      medias={this.state.mediaPorAgente} 
                      height={300}
                      rankingSelected={Object.values(
                        this.state.rankingSelected
                      )}
                      placeholder="/stickman.jpg"
                    />
                  </Col>
                </Row>
              </Container>
              <Container style={{ marginTop: 100, marginBottom: 50 }}>
                <Row align="center" style={{ marginBottom: 30 }}>
                  <Col md={4}>
                    <h2 style={{ textAlign: "left" }}>Equipo</h2>
                  </Col>
                  {/* <Col offset={{ md: 5 }} md={3}>
                    <ButtonBlock 
                      changeRankingSelected={equipoactivo => { this.setState({ equipoactivo }) }}
                      rankingSelected={Object.values(this.state.equipoactivo)[0]} 
                      indicadores={[{Activos: "Activos"}, {Inactivos: "Inactivos"}]} 
                    />
                  </Col> */}
                </Row>
                <Row>
                  {departamento.agentes/*.filter(a => Object.values(this.state.equipoactivo)[0] === "Activos" ? !a.blocked : !!a.blocked )*/.map((a, i) => (
                    <Col key={i} md={3}>
                        <CardTeam
                          indicator={a.estado}
                          image={
                            (a.imagen &&
                              process.env.REACT_APP_API_URL +
                                a.imagen.url) ||
                            process.env.PUBLIC_URL + "/stickman.jpg"
                          }
                          abreModal={this.abreModal}
                          datos={a}
                          comentarios={a.comentarios}
                          title={a.name + " " + a.lastname}
                          linkTo={"/agente/" + a.idusuario}
                          dataIndicadores={Array.isArray(this.state.mediaPorAgente) && this.state.mediaPorAgente.find(m => m._id === a._id).medias} 
                        />
                    </Col>
                  ))}
                </Row>
              </Container>
            </div>
          )}

        <ModalValoracion
          visible={this.state.modalValoracion}
          cb={bool => {
            if (bool) {
              this.props.history.push("/valoraciones");
            } else {
              this.setState({ modalValoracion: false });
            }
          }}
        />
        <ModalComentario
          visible={this.state.modalComentario}
          cb={() => this.setState({ modalComentario: false }) }
          comentado={this.state.datosModalComentario}
          accionModal={this.state.accionModal}
        />
        <ModalCambiarPw visible={this.state.modalCambiarPw} cb={bool => this.setState({ modalCambiarPw: false }) } history={this.props.history} />
        <ModalEditarDatos
          visible={this.state.modalEdicion}
          id={departamento._id}
          tipo="departamento"
          fotoActual={departamento.picture}
          cb={refresh => {
            this.setState({ modalEdicion: false });
            if (refresh) {
              this.props.dispatch(
                fetchDatos(
                  auth.getUserInfo().role.name,
                  getGrupo(auth.getUserInfo().role.name)
                )
              );
            }
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  datos: state.datos,
  medias: state.medias,
  fecha: state.fecha,
  cargando: state.cargando
})

export default connect(mapStateToProps)(DepartamentoPage);