/**
 *
 * BarBlock: Bloque de barra doble (valor actual y valor objetivo) y speedometer
 *
**/

import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'antd'

import './styles.scss';

const BarBlock = ({padding, desc, etiq = false, height, margin, etiqin = false, val, obj, min=0, max, indicadores = false, speedo = false, color1, color2, perc = true, speedoobj = false, link = false, objReal, resReal, isPerc }) => {
  
  const valor = ((val-min)/(max-min))*100;
  const objetivo = ((obj-min)/(max-min))*100;

  var colind = "0";
  
  if( color1 === undefined || color2 === undefined ){
    if (valor > 66.6 ){ colind = "green" }
    else if (valor > 33.3 ){ colind = "orange" }
    else { colind = "red" }
  }
  else{
    if (val >= color2 ){colind = "green"}
    else if (val >= color1 ){colind = "orange"}
    else {colind = "red"}
  }
  
  const ind = indicadores ? <div>
                              <span className="bararrow" style={{left: valor + '%'}}><Icon type="up" /> Actual</span>
                              <span className="bararrow objetiv" style={{left: objetivo + '%', top: "-22px"}}>Objetivo <Icon type="down" /></span>
                            </div>
                          : "" ;  
  const angulo = valor*1.8;
  
  return(
    <div className={ indicadores ? "BarWrapper" : "" }>
      { speedo === false ? (
        <div className="barblock" style={{padding: padding, marginBottom: margin, height: height}}>
          { link !== false ? ( 
            <Link style={{ width: '20%', float:'left' }} to={{
              pathname: link.path,
              idUser: link.id 
            }}>
              <span>{ etiq }</span>
            </Link>
          ) : (
             etiqin === false && <span style={ etiq !== false ? {width: '20%', float:'left'} : {} }>{ etiq }</span> 
          )}
          <div style={ etiq !== false ? {width: etiqin === false ? '80%': '100%' , float:'right' } : {width: '100%'}}>
            <div className="BarWrappersmall" style={{marginTop:'10px', marginBottom:'10px', width: '100%',  height: height}}>
              <div className="barcontainer" style={{height: height}} title={desc ? `Descripción KPI: ${desc}\nResultado: ${resReal}\nObjetivo: ${objReal}` : ""}>
                <div className={"barobjective " + colind}  style={{width: objetivo + '%'}}></div>
                <div className={"barindicator " + colind } style={{width: valor + '%'}}></div>
                { etiqin === true && <div className="etiqetainterior">{etiq}</div> }
              </div>
              { ind }
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="outherspeed">
            <div className="containerspeed">
              <div className="line">
                <svg width="149px" height="76px" viewBox="0 0 149 76">
                  <defs>
                    <linearGradient id="green">
                      <stop offset="0" stopColor="#429321" stopOpacity="1" />
                      <stop offset="1" stopColor="#B4EC51" stopOpacity="1" />
                    </linearGradient>
                    <linearGradient id="orange">
                      <stop offset="0" stopColor="#F76B1C" stopOpacity="1" />
                      <stop offset="1" stopColor="#FAD961" stopOpacity="1" />
                    </linearGradient>
                    <linearGradient id="red">
                      <stop offset="0" stopColor="#9F041B" stopOpacity="1" />
                      <stop offset="1" stopColor="#F5515F" stopOpacity="1" />
                    </linearGradient>
                  </defs>
                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" >
                    <path d="M3,73 C4.03356625,34.163479 35.6495492,3 74.5000001,3 C113.350451,3 144.966434,34.163479 146,73" stroke="gainsboro" strokeWidth="6" />
                  </g>
                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" >
                    <path className="dibujo" d="M3,73 C4.03356625,34.163479 35.6495492,3 74.5000001,3 C113.350451,3 144.966434,34.163479 146,73" stroke={`url(#${colind})`} strokeWidth="6" />
                  </g>
                </svg>
              </div>
              <div className="speedindicator" style={{transform: `rotate(${angulo}deg)`}}>
                <svg width="44px" height="19px" viewBox="0 0 44 19">
                  <defs />
                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" >
                    <g transform="translate(-477.000000, -782.000000)" fill="#3E3E3E">
                      <path d="M499,813 C494.029437,813 490,808.856387 490,803.744981 C490,798.633575 497.225469,770 499.362473,770 C501.499478,770 508,798.633575 508,803.744981 C508,808.856387 503.970563,813 499,813 Z M499,808.447177 C500.911755,808.447177 502.461538,806.85348 502.461538,804.887555 C502.461538,802.921629 500.911755,801.327932 499,801.327932 C497.088245,801.327932 495.538462,802.921629 495.538462,804.887555 C495.538462,806.85348 497.088245,808.447177 499,808.447177 Z" transform="translate(499.000000, 791.500000) rotate(-90.000000) translate(-499.000000, -791.500000) " />
                    </g>
                  </g>
                </svg>
              </div>
            </div>
          </div>
          <div className="ratio" style={{marginTop:'40px'}} >
            <h4 style={{color: "black"}}> { (etiq !== false) ? `${etiq}: ` : "" }{val}{ perc ? "%" : ""}</h4>
            { speedoobj !== false ? (<p>Objetivo: { obj }{ perc ? "%" : "" }</p>) : "" }
          </div>
        </div>
      )}
    </div>
  )
}

export default BarBlock;
