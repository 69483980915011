import React from 'react'
import { Modal } from 'antd'

const ModalValoracion = ({ visible, cb }) => 
    <Modal
        title="Valoración del mánager"
        visible={visible}
        onOk={() => cb(true)}
        okText="Ir a valoraciones"
        onCancel={() => cb(false)}
        cancelText="Más tarde"
    >
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <p>Para el buen funcionamiento del sistema BAM necesitamos que valores a tus asesores</p>
        </div>
    </Modal>

export default ModalValoracion