import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { getBaseURL } from '../utils/func'
import { connect } from 'react-redux'
import './styles.scss'
import { cargarBaseURL } from '../actions';


class HomePage extends Component {
  async componentDidMount(){
    const baseURL = await getBaseURL()
    this.props.dispatch(cargarBaseURL(baseURL))
    if(baseURL !== "/auth/login"){
      this.props.history.push(baseURL)
    }
  }
  async componentDidUpdate(prevProps){
    const baseURL = await getBaseURL()
    this.props.dispatch(cargarBaseURL(baseURL))
    if(baseURL !== "/auth/login"){
      this.props.history.push(baseURL)
    }
  }

  render() {
    return (
      <div style={{ display: "flex", justifyContent: "center"}}>
        <div style={{ 
          display: "flex", 
          flexDirection: "column", 
          alignItems: "center", 
          border: "2px solid #F5F5F5", 
          borderRadius: 15, 
          width: 600, 
          marginTop: 50, 
          padding: "2em",
          boxShadow: "0 9px 19px 0 rgba(0, 0, 0, 0.1)"
        }}>
          <h3 style={{ marginBottom: "1em", color: "#777" }}>Bienvenido a BAM</h3>
          <Link to="/auth/login"><div className="btn activo" style={{ width: 150 }}>Acceder</div></Link>
        </div>
      </div>
    )
  }
}

export default withRouter(connect()(HomePage))