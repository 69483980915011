import React from 'react';
import './styles.scss'
import RankingItem from '../RankingItem'
import { Link } from 'react-router-dom'
import { redondear} from '../../utils/func'

const getLink = (datos, tipo) => {
    const link = `/${tipo}/${tipo === "agente" ? datos.idusuario : datos.idcet.toLowerCase()}`
    return link
}

const Ranking = ({dataRanking = [], height, rankingSelected, medias = [], placeholder, tipo}) => {
    const dataChachi = dataRanking.map(d => {
        const mediaItem = medias.find(m => m._id === d._id) || { medias: {}}
        return {...d, ...mediaItem}
    })
    const rankingOrdenado = [...dataChachi].sort((a, b) => {
        if(a.medias[rankingSelected] < b.medias[rankingSelected]){ return 1 }
        else if(a.medias[rankingSelected] > b.medias[rankingSelected] || Number.isNaN(b.medias[rankingSelected])){ return -1 }
        else{ return 0 }
    })

    return ( 
            <div className="card shadow">
                <div className="rankingContainer" style={{height: height}}>
                    {
                        rankingOrdenado.map((item, i) => {
                            const picture = item.picture || item.imagen
                            return (
                                <Link key={i} to={getLink(item, tipo)}>
                                    <RankingItem 
                                        itemImageUrl={picture ? process.env.REACT_APP_API_URL + picture.url : placeholder} 
                                        itemTitle={item.nombre || (item.name + " " + item.lastname)} 
                                        itemVal={item.medias[rankingSelected] === undefined ? 0 : redondear(item.medias[rankingSelected])} 
                                        itemObj={50}
                                        itemActivo={item}
                                    />
                                </Link> 
                            )
                        })
                    }
                    {dataRanking.length === 0 && <div style={{height:'100%', textAlign:'center', display:'flex', alignItems:'center', justifyContent:'center' }}><h2 style={{color: 'gainsboro' }}>Sin datos en el ranking</h2></div> }
                </div>
            </div>
     );
}
 
export default Ranking;
