import React, { Component } from 'react'
import { connect } from 'react-redux'
import Dropzone from 'react-dropzone'
import XLSX from 'xlsx'

class CargaPage extends Component {
    state = {}
    
    leerArchivo = uploaded => {
        var file = uploaded[0], read = new FileReader();
        read.readAsBinaryString(file);
        read.onloadend = () => {
            var oFile = XLSX.read(read.result, {type: 'binary' })
            const data = XLSX.utils.sheet_to_json(oFile.Sheets[oFile.SheetNames[0]], {cellNF:true, raw:false})
            this.setState({ data })
            console.log(data)
            console.log(this.props.usuarios)
            data.forEach( fila => {
                console.log(fila["UID"])
                const usuario = this.props.usuarios.find(u => u.idusuario.toLowerCase() === fila["UID"].toLowerCase())
                console.log(usuario)
            })
        }
    }
    
    render(){
        return (
            <div className="container sans">
                <div className="row mt-4 mb-4">
                    <div className="col-12">
                        <h2>Carga de datos de BAM</h2>
                    </div>
                    <div className="col-12 mt-2">
                        <Dropzone style={{}} onDrop={file => {
                            this.leerArchivo(file)
                        }}>
                            <button type="button" className="btn btn-outline-secondary btn-lg">Arrastrar archivo de carga de datos aquí</button>
                        </Dropzone>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        usuarios: state.usuarios,
        datos: state.datos
    }
}

export default connect(mapStateToProps)(CargaPage)