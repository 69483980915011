import React from "react";
import './style.scss'

const Loader = ({ loading }) => (
  <div className={`loader ${loading ? "loading" : ""}`} style={{
    background: `url(${process.env.PUBLIC_URL + "/fondoloader.jpg"}`
  }}>
    <h1>Cargando</h1>
    <div id="punto1"><h1>.</h1></div>
    <div id="punto2"><h1>.</h1></div>
    <div id="punto3"><h1>.</h1></div>
  </div>
);

export default Loader;
