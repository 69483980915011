import { Component } from 'react';
import auth from '../utils/auth';
import { includes as lodashIncludes } from 'lodash';

class PrivateComponent extends Component {

  render() {
    const authData = (typeof auth.getUserInfo() === "object" && auth.getUserInfo() !== null && auth.getUserInfo().blocked === false ) ? auth.getUserInfo() : {
      role: {
        name: "Not Authorised"
      }
    };
    return lodashIncludes( this.props.allowed, authData.role.name ) ? this.props.children : "";
  }
}

export default PrivateComponent