/**
 * App
 */

// import 'babel-polyfill';
import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux'

import Header from '../components/Header';
import Footer from '../components/Footer';

import NotFoundPage from './NotFoundPage';
import AuthPage from './AuthPage';
import GlobalPage from './GlobalPage';
import DireccionPage from './DireccionPage';
import DivisionPage from './DivisionPage';
import DepartamentoPage from './DepartamentoPage';
import AreaPage from './AreaPage';
import HomePage from './HomePage';
import HoliPage from './HoliPage';
import TecnicoPage from './TecnicoPage';
import CargaPage from './CargaPage';
import ValoracionesPage from './ValoracionesPage';
import ActualizarPage from './ActualizarPage';
import PrivateRoute from './PrivateRoute';
import GoUpAndBaseURL from './GoUpAndBaseURL'
import AdminPage from './AdminPage'
import 'antd/dist/antd.css';

import auth from '../utils/auth';
import { getGrupo } from '../utils/func'
import { fetchDatos, fetchUsuarios, fetchRoles, fetchConfig, fetchMedias, fetchDatobam } from '../actions'

// Design
import './styles.scss';
import './typo.scss';

import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import rootReducer from '../reducers/index'
import thunk from 'redux-thunk';
import moment from 'moment'
import 'moment/locale/es'
import PerfilPage from './PerfilPage';

// import { whyDidYouUpdate } from 'why-did-you-update';
// whyDidYouUpdate(React);


const store = createStore(
    rootReducer,
    {},
    composeWithDevTools(applyMiddleware(thunk))
)

class App extends Component {
  constructor(props){
    super(props);
    this.state = { logged: false }
  }

  componentDidMount(){
    moment.locale("es")
    const authData = auth.getUserInfo()
    if(authData){
      store.dispatch(fetchDatos(authData.role.name, getGrupo(authData.role.name), { desde: moment.utc().startOf("month").subtract(6, "months").format(), hasta: moment.utc().format() }))
      store.dispatch(fetchMedias(authData.role.name, { desde: moment.utc().startOf("month").subtract(6, "months").format(), hasta: moment.utc().format() }))
      store.dispatch(fetchConfig())
      if( authData.role.name === "Administrator" ){ store.dispatch(fetchUsuarios(authData.role.name, getGrupo(authData.role.name))) }
      if( authData.role.name === "Administrator" ){ store.dispatch(fetchRoles()) }
    }
  }

  componentDidUpdate(prevProps){
    const authData = auth.getUserInfo()
    if(authData){
      store.dispatch(fetchDatos(authData.role.name, getGrupo(authData.role.name), { desde: moment.utc().startOf("month").subtract(6, "months").format(), hasta: moment.utc().format() }))
      store.dispatch(fetchMedias(authData.role.name, { desde: moment.utc().startOf("month").subtract(6, "months").format(), hasta: moment.utc().format() }))
      store.dispatch(fetchDatobam({ desde: moment.utc().startOf("month").subtract(6, "months").format(), hasta: moment.utc().format() }))
      store.dispatch(fetchConfig())
      if( authData.role.name === "Administrator" ){ store.dispatch(fetchUsuarios(authData.role.name), getGrupo(authData.role.name)) }
      if( authData.role.name === "Administrator" ){ store.dispatch(fetchRoles()) }
    }
  }

  loggingAction = () => {
    if( auth.getUserInfo() !== null ){ this.setState({ logged: true }) }
    else{ this.setState({ logged: false }) }
  }

  render() {
    return (
      <Provider store={store}>
        <Router>
          <GoUpAndBaseURL>
            <div className="App" style={{minHeight: "100vh", display: "flex", flexDirection: "column"}}>
              <Header logged={this.state.logged} />
              <div style={{ flex: 1 }}>
                <Switch>
                  {/* A user can't go to the HomePage if is not authenticated */}
                  <Route
                    path="/auth/:authType/:id?"
                    render={(props) => <AuthPage {...props} loggingAction={this.loggingAction} />}
                  />
                  
                  {/* BAM */}
                    <PrivateRoute allowed={["Administrator"]} path="/global" component={GlobalPage} exact />
                    <PrivateRoute allowed={["Administrator"]} path="/carga" component={CargaPage} exact />
                    <PrivateRoute allowed={["Administrator"]} path="/admin" component={AdminPage} exact />
                    <PrivateRoute allowed={["Administrator", "BAMDireccion"]} path="/direccion/:idDireccion" component={DireccionPage} exact />
                    <PrivateRoute allowed={["Administrator", "BAMDireccion", "BAMDivision"]} path="/division/:idDivision" component={DivisionPage} exact />
                    <PrivateRoute allowed={["Administrator", "BAMDireccion", "BAMDivision", "BAMDepartamento"]} path="/departamento/:idDepartamento" component={DepartamentoPage} exact />
                    <PrivateRoute allowed={["Administrator", "BAMDireccion", "BAMDivision", "BAMDepartamento", "BAMArea"]} path="/area/:idArea" component={AreaPage} exact />
                    <PrivateRoute allowed={["Administrator", "BAMDireccion", "BAMDivision", "BAMDepartamento", "BAMArea"]} path="/valoraciones" component={ValoracionesPage} exact />
                    <PrivateRoute allowed={["Administrator", "BAMDireccion", "BAMDivision", "BAMDepartamento", "BAMArea", "BAMAgente"]} path="/agente/:idAgente" component={TecnicoPage} exact />
                    <PrivateRoute allowed={["Administrator", "BAMDireccion", "BAMDivision", "BAMDepartamento", "BAMArea", "BAMAgente"]} path="/perfil" component={PerfilPage} exact />
                    <PrivateRoute allowed={["Administrator", "BAMDireccion", "BAMDivision", "BAMDepartamento", "BAMArea", "BAMAgente"]} path="/actualizar" component={ActualizarPage} exact />
                  {/* Hasta aquí BAM */}
                  
                  <PrivateRoute allowed={["Administrator"]} path="/holi" component={HoliPage} />
                  <Route exact path="/" component={HomePage} />
                  <Route path="" component={NotFoundPage} />
                </Switch>
              </div>
              <Footer logged={this.state.logged} />
            </div>
          </GoUpAndBaseURL>
        </Router>
      </Provider>
    );
  }
}

export default App;



