import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import './styles.scss'
import auth from '../../utils/auth';

const Footer = ({ config }) => {
    const [needUpdate, setNeedUpdate] = useState(false)
    const isRoot = auth.getUserInfo() && auth.getUserInfo().role && auth.getUserInfo().role.type === "root"
    
    useEffect(() => {
        if(config && typeof config.version === "string"){
            const envVersion = process.env.REACT_APP_VERSION.replace(" Dev", "").split(".")
            const backVersion = config.version.split(".")

            if(
                backVersion[0] > envVersion[0] || 
                (backVersion[0] === envVersion[0] && backVersion[1] > envVersion[1]) ||
                (backVersion[0] === envVersion[0] && backVersion[1] === envVersion[1] && backVersion[2] > envVersion[2])
            ){
                setNeedUpdate(true)
            }
        }
    }, [config])
    
    
    return ( 
        <div className="footerContainer">
            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: "0 30px 0 30px", alignItems: "center" }}>
                <p style={{ color: "white", margin: 0, fontSize: 13 }}>&reg; 2020 - <a href="https://www.hub7s.com" target="_blank" rel="noreferrer noopener">Hub7 Solutions</a></p>
                <p style={{ color: "white", margin: 0, fontSize: 13 }}>v{process.env.REACT_APP_VERSION}{needUpdate && <Link to="/actualizar"><span style={{ marginLeft: "1em", fontWeight: "bold"}}>(Versión {config.version} disponible)</span></Link>}</p>
            </div>
        </div>
     );
}
 
const mapStateToProps = state => ({
    config: state.config,
})

export default connect(mapStateToProps)(Footer);