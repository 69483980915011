import moment from 'moment'

export function queryDatos(rol, idGrupo, filtro = undefined){
  if(filtro && moment.isMoment(filtro.desde)){ filtro.desde = moment.utc(filtro.desde).format() }
  if(filtro && moment.isMoment(filtro.hasta)){ filtro.hasta = moment.utc(filtro.hasta).format() }
  if(rol === "Administrator"){
    return `{
    comitedireccions{
      _id
      idcet          
      activo
      nombre
      managercomite{
        _id
        name
        lastname
        email
      }
      direccions{
        _id
        idcet          
        activo
        nombre
        comentariosRecibidos(sort: "createdAt:desc", limit: 3){
          _id contenido createdAt
          nuevo de{ name lastname }
        }
        picture{
          url
        }
        agentes{
          _id
          name
          lastname
          idusuario
          email
          puesto
          fechaContrato
          blocked
          comentariosRecibidos(sort: "createdAt:desc", limit: 3){
            _id contenido createdAt
            nuevo de{ name lastname }
          }
          imagen{
            url
          }
        }
        managerdireccion{
          _id
          name
          lastname
          email
        }
        divisions{
          _id
          idcet          
          activo
          activo
          nombre
          coordenadas
          picture{
            url
          }
          comentariosRecibidos(sort: "createdAt:desc", limit: 3){
            _id contenido createdAt
            nuevo de{ name lastname }
          }
          agentes{
            _id
            name
            lastname
            idusuario
            email
            puesto
            fechaContrato
            blocked
            comentariosRecibidos(sort: "createdAt:desc", limit: 3){
              _id contenido createdAt
              nuevo de{ name lastname }
            }
            imagen{
              url
            }
          }
          managerdivision{
            _id
            name
            lastname
            email
          }
          departamentos{
            _id
            idcet          
            activo
            nombre
            picture{
              url
            }
            comentariosRecibidos(sort: "createdAt:desc", limit: 3){
              _id contenido createdAt
              nuevo de{ name lastname }
            }
            agentes{
              _id
              name
              lastname
              idusuario
              email
              puesto
              fechaContrato
              blocked
              imagen{
                url
              }
              comentariosRecibidos(sort: "createdAt:desc", limit: 3){
                _id contenido createdAt
                nuevo de{ name lastname }
              }
            }
            managerdepartamento{
              _id
              name
              lastname
              email
            }
            unidadareas{
              _id
              idcet          
              activo
              nombre
              picture{
                url
              }
              comentariosRecibidos(sort: "createdAt:desc", limit: 3){
                _id contenido createdAt
                nuevo de{ name lastname }
              }
              agentes{
                _id
                name
                lastname
                idusuario
                email
                puesto
                fechaContrato
                blocked
                comentariosRecibidos(sort: "createdAt:desc", limit: 3){
                  _id contenido createdAt
                  nuevo de{ name lastname }
                }
                imagen{
                  url
                }
              }
              managerarea{
                _id
                name
                lastname
                email
              }
            }
          }
          unidadareas{
            _id
            idcet          
            activo
            nombre
            picture{
              url
            }
            comentariosRecibidos(sort: "createdAt:desc", limit: 3){
              _id contenido createdAt
              nuevo de{ name lastname }
            }
            agentes{
              _id
              name
              lastname
              idusuario
              email
              puesto
              fechaContrato
              blocked
              comentariosRecibidos(sort: "createdAt:desc", limit: 3){
                _id contenido createdAt
                nuevo de{ name lastname }
              }
              imagen{
                url
              }
            }
            managerarea{
              _id
              name
              lastname
              email
            }
          }
        }
      }
    }
  }`
} else if ( rol === "BAMDireccion" ){ 
  return `{
    direccion(id: "${idGrupo}"){
      _id
      idcet          
      activo
      nombre
      picture{
        url
      }
      comentariosRecibidos(sort: "createdAt:desc", limit: 3){
        _id contenido createdAt
        nuevo de{ name lastname }
      }
      agentes{
        _id
        name
        lastname
        idusuario
        email
        puesto
        fechaContrato
        blocked
        imagen{
          url
        }
        comentariosRecibidos(sort: "createdAt:desc", limit: 3){
          _id contenido createdAt
          nuevo de{ name lastname }
        }
      }
      managerdireccion{
        _id
        name
        lastname
        email
      }
      divisions{
        _id
        idcet          
        activo
        nombre
        coordenadas
        picture{
          url
        }
        comentariosRecibidos(sort: "createdAt:desc", limit: 3){
          _id contenido createdAt
          nuevo de{ name lastname }
        }
        agentes{
          _id
          name
          lastname
          idusuario
          email
          puesto
          fechaContrato
          blocked
          imagen{
            url
          }
          comentariosRecibidos(sort: "createdAt:desc", limit: 3){
            _id contenido createdAt
            nuevo de{ name lastname }
          }
        }
        managerdivision{
          _id
          name
          lastname
          email
        }
        departamentos{
          _id
          idcet          
          activo
          nombre
          picture{
            url
          } 
          comentariosRecibidos(sort: "createdAt:desc", limit: 3){
            _id contenido createdAt
            nuevo de{ name lastname }
          }
          agentes{
            _id
            name
            lastname
            idusuario
            email
            puesto
            fechaContrato
            blocked
            imagen{
              url
            }
            comentariosRecibidos(sort: "createdAt:desc", limit: 3){
              _id contenido createdAt
              nuevo de{ name lastname }
            }
          }
          managerdepartamento{
            _id
            name
            lastname
            email
          }
          unidadareas{
            _id
            idcet          
            activo
            nombre
            picture{
              url
            }
            comentariosRecibidos(sort: "createdAt:desc", limit: 3){
              _id contenido createdAt
              nuevo de{ name lastname }
            }
            agentes{
              _id
              name
              lastname
              idusuario
              email
              puesto
              fechaContrato
              blocked
              imagen{
                url
              }
              comentariosRecibidos(sort: "createdAt:desc", limit: 3){
                _id contenido createdAt
                nuevo de{ name lastname }
              }
            }
            managerarea{
              _id
              name
              lastname
              email
            }
          }
        }
        unidadareas{
          _id
          idcet          
          activo
          nombre
          picture{
            url
          }
          comentariosRecibidos(sort: "createdAt:desc", limit: 3){
            _id contenido createdAt
            nuevo de{ name lastname }
          }
          agentes{
            _id
            name
            lastname
            idusuario
            email
            puesto
            fechaContrato
            blocked
            imagen{
              url
            }
            comentariosRecibidos(sort: "createdAt:desc", limit: 3){
              _id contenido createdAt
              nuevo de{ name lastname }
            }
          }
          managerarea{
            _id
            name
            lastname
            email
          }
        }
      }
    }
  }`
} else if ( rol === "BAMDivision" ){ 
  return `{
    division(id: "${idGrupo}"){
      _id
      idcet          
      activo
      nombre
      picture{
        url
      }
      comentariosRecibidos(sort: "createdAt:desc", limit: 3){
        _id contenido createdAt
        nuevo de{ name lastname }
      }
      coordenadas
      agentes{
        _id
        name
        lastname
        idusuario
        email
        puesto
        fechaContrato
        blocked
        imagen{
          url
        }
        comentariosRecibidos(sort: "createdAt:desc", limit: 3){
          _id contenido createdAt
          nuevo de{ name lastname }
        }
      }
      managerdivision{
        _id
        name
        lastname
        email
      }
      departamentos{
        _id
        idcet          
        activo
        nombre
        picture{
          url
        }
        comentariosRecibidos(sort: "createdAt:desc", limit: 3){
          _id contenido createdAt
          nuevo de{ name lastname }
        }
        agentes{
          _id
          name
          lastname
          idusuario
          email
          puesto
          fechaContrato
          blocked
          imagen{
            url
          }
          comentariosRecibidos(sort: "createdAt:desc", limit: 3){
            _id contenido createdAt
            nuevo de{ name lastname }
          }
        }
        managerdepartamento{
          _id
          name
          lastname
          email
        }
        unidadareas{
          _id
          idcet          
          activo
          nombre
          picture{
            url
          }
          comentariosRecibidos(sort: "createdAt:desc", limit: 3){
            _id contenido createdAt
            nuevo de{ name lastname }
          }
          agentes{
            _id
            name
            lastname
            idusuario
            email
            puesto
            fechaContrato
            blocked
            imagen{
              url
            }
            comentariosRecibidos(sort: "createdAt:desc", limit: 3){
              _id contenido createdAt
              nuevo de{ name lastname }
            }
          }
          managerarea{
            _id
            name
            lastname
            email
          }
        }
      }
      unidadareas{
        _id
        idcet          
        activo
        nombre
        picture{
          url
        }
        comentariosRecibidos(sort: "createdAt:desc", limit: 3){
          _id contenido createdAt
          nuevo de{ name lastname }
        }
        agentes{
          _id
          name
          lastname
          idusuario
          email
          puesto
          fechaContrato
          blocked
          imagen{
            url
          }
          comentariosRecibidos(sort: "createdAt:desc", limit: 3){
            _id contenido createdAt
            nuevo de{ name lastname }
          }
        }
        managerarea{
          _id
          name
          lastname
          email
        }
      }
    }
  }`
} else if ( rol === "BAMDepartamento" ){ 
  return `{
    departamento(id: "${idGrupo}"){
      _id
      idcet          
      activo
      nombre
      picture{
        url
      }
      comentariosRecibidos(sort: "createdAt:desc", limit: 3){
        _id contenido createdAt
        nuevo de{ name lastname }
      }
      agentes{
        _id
        name
        lastname
        idusuario
        email
        puesto
        fechaContrato
        blocked
        imagen{
          url
        }
        comentariosRecibidos(sort: "createdAt:desc", limit: 3){
          _id contenido createdAt
          nuevo de{ name lastname }
        }
      }
      managerdepartamento{
        _id
        name
        lastname
        email
      }
      unidadareas{
        _id
        idcet          
        activo
        nombre
        picture{
          url
        }
        comentariosRecibidos(sort: "createdAt:desc", limit: 3){
          _id contenido createdAt
          nuevo de{ name lastname }
        }
        agentes{
          _id
          name
          lastname
          idusuario
          email
          puesto
          fechaContrato
          blocked
          imagen{
            url
          }
          comentariosRecibidos(sort: "createdAt:desc", limit: 3){
            _id contenido createdAt
            nuevo de{ name lastname }
          }
        }
        managerarea{
          _id
          name
          lastname
          email
        }
      }
    }
    unidadareas{
      _id
      idcet          
      activo
      nombre
      picture{
        url
      }
      comentariosRecibidos(sort: "createdAt:desc", limit: 3){
        _id contenido createdAt
        nuevo de{ name lastname }
      }
      agentes{
        _id
        name
        lastname
        idusuario
        email
        puesto
        fechaContrato
        blocked
        imagen{
          url
        }
        comentariosRecibidos(sort: "createdAt:desc", limit: 3){
          _id contenido createdAt
          nuevo de{ name lastname }
        }
      }
      managerarea{
        _id
        name
        lastname
        email
      }
    }
  }`
} else if ( rol === "BAMArea" ){ 
  return `{
    unidadarea(id: "${idGrupo}"){
      _id
      idcet          
      activo
      nombre
      picture{
        url
      }
      comentariosRecibidos(sort: "createdAt:desc", limit: 3){
        _id contenido createdAt
        nuevo de{ name lastname }
      }
      agentes{
        _id
        name
        lastname
        idusuario
        blocked
        imagen{
          url
        }
        comentariosRecibidos(sort: "createdAt:desc", limit: 3){
          _id contenido createdAt
          nuevo de{ name lastname }
        }
      }
      managerarea{
        _id
        name
        lastname
        email
      }
    }
  }`
} else if ( rol === "BAMAgente" ){ 
  return `{
    user(id: "${idGrupo}"){
      _id
      name
      lastname
      idusuario
      nuevoComentario
      blocked
      imagen{
        url
      }
      entraEncuesta
      areaagente {
        nombre
        managerarea{
          _id
          name
          lastname
        }
      }
      departamentoagente {
        nombre
        managerdepartamento{
          _id
          name
          lastname
        }
      }
      divisionagente {
        nombre
        managerdivision{
          _id
          name
          lastname
        }
      }
      direccionagente {
        nombre
        managerdireccion{
          _id
          name
          lastname
        }
      }
    }
  }`
} else {
  return `{
  datobams{
    _id
  }
}`
}}

export function queryUsuarios(rol){
  if(rol === "Administrator"){
    return `{
      users(limit: 0){
        _id
        idusuario
        name
        lastname
        email
        blocked
        username
        role{
          name
        }
        direccions{
          _id
          nombre
          agentes{ _id }
        }
        divisions{
          _id
          nombre
          agentes{ _id }
        }
        departamentos{
          _id
          nombre
        agentes{ _id }
      }
      unidadareas{
        _id
        nombre
        agentes{ _id }        
      }
      direccionagente{ 
        _id 
        nombre 
        managerdireccion{
          email
          name
        }
      }
      divisionagente{ 
        _id 
        nombre 
        managerdivision{
          email
          name
        }
      }
      departamentoagente{ 
        _id 
        nombre 
        managerdepartamento{
          email
          name
        }
      }
      areaagente{ 
        _id 
        nombre 
        managerarea{
          email
          name
        }
      }
    }
  }`}
  else{
    return `{}`
  }
}

export function queryMedias(filtro){
  if(filtro && moment.isMoment(filtro.desde)){ filtro.desde = moment.utc(filtro.desde).format() }
  if(filtro && moment.isMoment(filtro.hasta)){ filtro.hasta = moment.utc(filtro.hasta).format() }
  return `{
    mediabams${filtro ? `(limit: 0, sort: "fecha:desc", where: {fecha_gte: "${moment(filtro.desde).isSame(moment(filtro.hasta), "month") ? moment.utc(filtro.desde).subtract(6, "months").format() : filtro.desde}", fecha_lte: "${filtro.hasta}"})` : ""}{
      v1
      v2
      v3
      v4
      v5
      v6
      p1
      p2
      p3
      p4
      p5
      p6
      fecha
      user{
        _id
      }
      unidadarea{
        _id
      }
      departamento{
        _id
      }
      division{
        _id
      }
      direccion{
        _id
      }
      comitedireccion{
        _id
      }
    }
  }`
}

export function queryDatobam(filtro, id){
  if(filtro && moment.isMoment(filtro.desde)){ filtro.desde = moment.utc(filtro.desde).format() }
  if(filtro && moment.isMoment(filtro.hasta)){ filtro.hasta = moment.utc(filtro.hasta).format() }
  return id ? `{
    user(id: "${id}"){
      _id
      datobams${filtro ? `(limit: 1000000, sort: "fecha:desc", where: { fecha_gte: "${moment(filtro.desde).isSame(moment(filtro.hasta), "month") ? moment.utc(filtro.desde).subtract(6, "months").format() : moment.utc(filtro.desde).format()}", fecha_lte: "${moment.utc(filtro.hasta).format()}"})` : ""}{
        _id
        fecha
        responsabilidad
        kpi
        descripcionkpi
        resultado
        objetivo
        ponderacion
        puntuacion
        descresponsabilidad
        escala
        puntponderada
        comentario
        respuesta
      }
    }
  }` : `{
    datobams(limit: 1000000, sort: "createdAt:desc"){
      _id
      fecha
      createdAt
      responsabilidad
    }
  }`
}