import React from 'react';
import {
    Radar, RadarChart, PolarGrid, ResponsiveContainer, PolarAngleAxis
} from 'recharts';
import { redondear } from '../../utils/func'
import './styles.scss';
import { Popover } from 'antd';

const getTitle = (e, dataChuli) => {
    const data = dataChuli.find(d => d.etiqueta === e.payload.value)
    return (
        <div>
            <p style={{ marginBottom: 5 }}>{e.payload.value}</p> 
            <p style={{ marginBottom: 5 }}>Valor: {redondear(data.val, 1)}</p> 
            <p style={{ marginBottom: 5 }}>Objetivo: {redondear(data.obj, 1)}</p> 
        </div>
    )
}

const SpiderBlock = ({height, data }) => {
    const dataChuli =[
        {
            etiqueta: 'Objetivos Principales', val: data ? data["1"] : 0, obj: 50
        },
        {
            etiqueta: 'Objetivos Intermedios', val: data ? data["2"] : 0, obj: 50
        },
        {
            etiqueta: 'Satisfaccion Cliente', val: data ? data["3"] : 0, obj: 50
        },
        {
            etiqueta: `Aprovechamiento Formaciones`, val: data ? data["4"] : 0, obj: 50
        },
        {
            etiqueta: 'Cumplimiento Normativo', val: data ? data["5"] : 0, obj: 50
        },
        {
            etiqueta: 'Valoracion del Manager', val: data ? data["6"] : 0, obj: 50
        }
    ];

    return (
        <div className="card shadow" style={{padding:'0px 30px'}}>
            <ResponsiveContainer width={"100%"} height={height}>
                <RadarChart  data={dataChuli}>
                    <PolarGrid innerRadius={0} outerRadius={0} polarAngles={[0,0,0,0]}/>
                    {/* <PolarRadiusAxis angle={30} domain={[0, 150]} /> */}
                    <Radar name="Resultados" dataKey="val" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
                    <Radar name="Objetivos" dataKey="obj" stroke="#82ca9d" fill="#82ca9d" fillOpacity={0.3} />
                    <PolarAngleAxis dataKey="etiqueta" tick={e => (
                        <Popover content={getTitle(e, dataChuli)}>
                            <g className="recharts-layer recharts-polar-angle-axis-tick">
                                <text radius={e.radius} stroke="none" x={e.x} y={e.y} className="recharts-text recharts-polar-angle-axis-tick-value" textAnchor="middle">
                                    <tspan x={e.x} dy="1em">{e.payload.value}</tspan>
                                </text>
                            </g>
                        </Popover>
                    )}/>
                    {/* <Legend /> */}
                </RadarChart>
            </ResponsiveContainer>
        </div>
    );
}

export default SpiderBlock;