import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Card, Button, message, Modal, Table } from 'antd'
import request from '../../utils/request';
import { plantillaMail } from '../../utils/func'
import moment from 'moment';

const PanelArchivos = () => {
    const [files, setFiles] = useState([])
    useEffect(() => {
        let jwt = window.localStorage.getItem("jwtToken") || window.sessionStorage.getItem("jwtToken") 
        if(jwt){ jwt = JSON.parse(jwt) }
        fetch(process.env.REACT_APP_API_URL + "/upload/files?_limit=0&_sort=createdAt:desc", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + jwt
            }
        }).then(resp => {
            resp.json().then(data => {
                setFiles(data)
            })
        }).catch(err => {
            console.log(err)
        })
    }, [])

    return(
        <div style={{
            padding: "2em 0"
        }}>
            <h2 style={{ paddingBottom: "1em" }}>Archivos</h2>
            <Table
                dataSource={files}
                rowKey="_id"
                columns={[
                    {
                        name: "Nombre",
                        dataIndex: "name",
                        key: "name"
                    },
                    {
                        name: "Fecha de subida",
                        key: "createdAt",
                        render: row => moment(row.createdAt).format("YYYY-MM-DD HH:mm")
                    },
                    {
                        key: "button",
                        render: row => <a href={process.env.REACT_APP_API_URL + row.url} rel="noopener noreferrer" target="_blank"><Button>Abrir archivo</Button></a>
                    }
                ]}
            />
        </div>
    )
}

export default PanelArchivos