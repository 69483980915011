 /**
 *
 * Header
 *
 **/

import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import logo from '../../assets/cetelemlogo.png'
import logoespecial from '../../assets/BAM.png'
import { Icon } from 'antd';
import PrivateComponent from '../../containers/PrivateComponent.js';
import { connect } from 'react-redux'

class Header extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      logged: false
    };
  }
  
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  
  async componentDidMount(){
    // if( auth.getUserInfo() !== null ){ this.setState({ logged: true }) }
    
    // if( auth.getUserInfo() !== null && auth.getUserInfo().role.name === "CETManager" ){
    //   const equipos = await request(process.env.REACT_APP_API_URL + "/graphql?query={equipos{_id%20subdivision}}")
    //   const managerDe = ( await request(process.env.REACT_APP_API_URL + "/users/me")).managerDe.map(m => m._id);
    //   const distribucion = equipos.data.equipos.filter( e => e.subdivision === "distribucion" ).map( m => m._id )
    //   const operaciones = equipos.data.equipos.filter( e => e.subdivision === "operaciones" ).map( m => m._id )
    //   const auto = equipos.data.equipos.filter( e => e.subdivision === "auto" ).map( m => m._id )
    //   if( difference(auto, managerDe).length === 0 ){ this.setState({ jefeSubdiv: "auto" }) }
    //   else if( difference(distribucion, managerDe).length === 0 ){ this.setState({ jefeSubdiv: "distribucion" }) }
    //   else if( difference(operaciones, managerDe).length === 0 ){ this.setState({ jefeSubdiv: "operaciones" }) }
    //   else{ this.setState({ jefeSubdiv: "no", managerDe }) }
    // }
    if(this.props.baseURL){
      this.setState({ baseURL: this.props.baseURL })
    }
  }
  
  async componentDidUpdate(prevProps, prevState){
    // if( prevProps.logged !== this.props.logged ){
    //   if( this.props.logged === true ){
    //     this.setState({ logged: true })
    //     if( auth.getUserInfo().role.name === "CETManager" ){
    //       const equipos = await request(process.env.REACT_APP_API_URL + "/graphql?query={equipos{_id%20subdivision}}")
    //       const managerDe = ( await request(process.env.REACT_APP_API_URL + "/users/me")).managerDe.map(m => m._id);
    //       const distribucion = equipos.data.equipos.filter( e => e.subdivision === "distribucion" ).map( m => m._id )
    //       const auto = equipos.data.equipos.filter( e => e.subdivision === "auto" ).map( m => m._id )
    //       if( difference(auto, managerDe).length === 0 ){ this.setState({ jefeSubdiv: "auto" }) }
    //       else if( difference(distribucion, managerDe).length === 0 ){ this.setState({ jefeSubdiv: "distribucion" }) }
    //       else{ this.setState({ jefeSubdiv: "no", managerDe }) }
    //     }
    //   }
    //   else{ this.setState({ logged: false }) }
    // }
    if(prevProps.baseURL !== this.props.baseURL && this.props.baseURL){
      this.setState({ baseURL: this.props.baseURL })
    }
  }
  
  render(){
    return (
       <div style={{
         display: "flex",
         justifyContent: "space-between",
         padding: "0 3em 0 1em",
         alignItems: "center"
       }}>
          <Link to={ this.props.baseURL } style={{minWidth:220}}>
            <img style={{ height: 60, width: 200, margin: "10px 0", objectFit: "contain" }} src={process.env.NODE_ENV === "development" ? logoespecial : logo} alt={"logo"} className={"d-inline-block align-top"}/>
          </Link>
          <div style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
            <PrivateComponent allowed={["Administrator"]}>
              <div style={{ padding: "0 0.5em" }}><Link to={"/global"}> <Icon type="global" theme="outlined" /> Global </Link></div>
            </PrivateComponent>                
            <PrivateComponent allowed={["BAMDireccion", "BAMDivision", "BAMDepartamento", "BAMArea"]}>
              <div style={{ padding: "0 0.5em" }}><Link to={this.props.baseURL}> <Icon type="team" theme="outlined" /> Mi equipo </Link></div>
            </PrivateComponent>                
            <PrivateComponent allowed={["BAMDireccion", "BAMDivision", "BAMDepartamento", "BAMArea"]}>
              <div style={{ padding: "0 0.5em" }}><Link to="/valoraciones"> <Icon type="rise" theme="outlined" /> Valoraciones </Link></div>
            </PrivateComponent>                
            <PrivateComponent allowed={["BAMAgente"]}>
              <div style={{ padding: "0 0.5em" }}><Link to={this.props.baseURL}> <Icon type="user" theme="outlined" /> Mi perfil </Link></div>
            </PrivateComponent>                
            <PrivateComponent allowed={["Administrator"]}>
              <div style={{ padding: "0 0.5em" }}><Link to={'/admin'}> <Icon type="setting" theme="outlined" /> Administración </Link></div>
            </PrivateComponent>
            {/* <PrivateComponent allowed={["Administrator"]}>
              <div style={{ padding: "0 0.5em" }}><Link to={'/holi'}> <Icon type="fire" theme="outlined" /> Holi </Link></div>
            </PrivateComponent> */}
            <PrivateComponent allowed={["Administrator", "BAMDireccion", "BAMDivision", "BAMDepartamento", "BAMArea", "BAMAgente"]}>
                <div style={{ padding: "0 0.5em" }}><Link to={'/perfil'}> <Icon type="user" theme="outlined" /> Mis datos </Link></div>
            </PrivateComponent>
            <PrivateComponent allowed={["Administrator", "BAMDireccion", "BAMDivision", "BAMDepartamento", "BAMArea", "BAMAgente"]}>
                <div style={{ padding: "0 0.5em" }}><Link to={'/auth/logout'}> <Icon type="logout" theme="outlined" /> Cerrar Sesión </Link></div>
            </PrivateComponent>
          </div>
      </div>
      
    )
  }
}

const mapStateToProps = state => ({
  baseURL: state.baseURL,
  fecha: state.fecha
})

export default withRouter(connect(mapStateToProps)(Header));

