import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { cargarBaseURL } from '../actions'

import { getBaseURL } from '../utils/func'

class GoUpAndBaseURL extends Component {
  async componentDidMount() {
    const baseURL = await getBaseURL()
    this.props.dispatch(cargarBaseURL(baseURL))
  }

  async componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0)
    }
    const baseURL = await getBaseURL()
    this.props.dispatch(cargarBaseURL(baseURL))
  }

  render() {
    return this.props.children
  }
}

export default withRouter(connect()(GoUpAndBaseURL))