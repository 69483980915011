import moment from 'moment';
import auth from './auth'
import { message } from 'antd'
import request from './request'
import gql from './gql'

export const sumar = (datos, clave) => (
    clave ? (
        datos.map(d => d[clave])
             .filter(e => (e !== undefined && typeof(e) === "number" && !Number.isNaN(e)))
             .reduce((a, b) => a + b, 0)
    ) : (
        datos.filter(e => (e !== undefined && typeof(e) === "number" && !Number.isNaN(e)))
             .reduce((a, b) => a + b, 0)
    )
)

export const mediaPorResponsabilidad = (datobams, global, filtro, key) => {
    if(Array.isArray(datobams)){
        const meses = filtro && Array(Math.ceil(moment.duration(filtro.hasta.diff(filtro.desde)).as("months"))).fill(null).map((e, i) => filtro.desde.clone().add(i, "months").format())
        const dbFilter = (filtro && filtro.unMes) ? datobams.filter(d => moment(d.fecha).isSame(meses.slice(-1)[0], "month")) : datobams
        const responsabilidades = [1, 2, 3, 4, 5, 6].filter(r => r === key || key === undefined)
        let medias = responsabilidades.map(r => {
            const datoresp = dbFilter.filter(d => d.responsabilidad === r)
            const media = sumar(datoresp, "puntponderada") / (sumar(datoresp, "ponderacion")/100)
            return {[r]: Number.isNaN(media) ? 0 : media}
        }).reduce((a, b) => ({...a, ...b}), {})
        medias = key ? medias[key] : medias
        if(global){
            const global = medias["1"]*0.4 + medias["2"]*0.15 + medias["3"]*0.15 + medias["4"] * 0.1 + medias["5"]*0.1 + medias["6"]*0.1
            medias.global = Number.isNaN(global) ? 0 : global
        }
        if(filtro && !key){
            medias.historico = responsabilidades.map(r => {
                const histxresp = meses.map(m => {
                    const dbrespmes = datobams.filter(d => moment(d.fecha).isSame(moment(m), "month") && d.responsabilidad === r)
                    return {
                        value: mediaPorResponsabilidad(dbrespmes, false, null, r)
                    }
                })
                return{
                    [r]: histxresp
                }
            }).reduce((a, b) => ({...a, ...b}), {})
        }
        return medias
    }
    else{
        return {"1": 0, "2": 0, "3": 0, "4": 0, "5": 0, "6": 0}
    }
}

export const redondear = (dato, decimales = 0) => {
    const returnito = Math.round( dato * Math.pow( 10, decimales )) / Math.pow( 10, decimales )
    return Number.isNaN(returnito) ? 0 : returnito
}

/* eslint-disable no-useless-escape */
export const slugify = (string, arr) => {
    const a = 'àáäâãåèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;'
    const b = 'aaaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------'
    const p = new RegExp(a.split('').join('|'), 'g')
    let str = string.toString().toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with
      .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, '-and-') // Replace & with ‘and’
      .replace(/[^\w\-]+/g, '') // Remove all non-word characters
      .replace(/\-\-+/g, '-') // Replace multiple — with single -
      .replace(/^-+/, '') // Trim — from start of text .replace(/-+$/, '') // Trim — from end of text
    //str = str + "-1";
    //return !arr.includes(str) ? str : (str.slice(0, -1) + (parseInt(arr.map(e => e.slice(0, -2)).filter(e => e === str.slice(0, -2)).length, 10) + 1))
    return str
}

export const getGrupo = rol => {
    if( rol === "Administrator" ){ return undefined }
    else if( rol === "BAMDireccion"){ return auth.getUserInfo().direccions[0] && auth.getUserInfo().direccions[0]._id}
    else if( rol === "BAMDivision"){ return auth.getUserInfo().divisions[0] && auth.getUserInfo().divisions[0]._id}
    else if( rol === "BAMDepartamento"){ return auth.getUserInfo().departamentos[0] && auth.getUserInfo().departamentos[0]._id}
    else if( rol === "BAMArea"){ return auth.getUserInfo().unidadareas[0] && auth.getUserInfo().unidadareas[0]._id}
    else if( rol === "BAMAgente"){ return auth.getUserInfo()._id}
}

export const getBaseURL = async () => {
    const user = auth.getUserInfo() 
    if(user){
      const nivel = user.role.name === "BAMDireccion" ? "direccions" : 
                  (user.role.name === "BAMDivision" ? "divisions" : 
                  (user.role.name === "BAMDepartamento" ? "departamentos" : 
                  (user.role.name === "BAMArea" ? "unidadareas" : "")))

      const query = !["Administrator", "BAMAgente"].includes(user.role.name) && await request(gql(`{
        user(id: "${user._id}"){ 
          ${nivel}{ 
            idcet
          }
        }
      }`))

      const baseURL = user.role.name === "Administrator" ? "/global" : (
                      user.role.name === "BAMDireccion" ? `/direccion/${ query.data.user[nivel][0].idcet.toLowerCase() }` : (
                      user.role.name === "BAMDivision" ? `/division/${ query.data.user[nivel][0].idcet.toLowerCase() }` : (
                      user.role.name === "BAMDepartamento" ? `/departamento/${ query.data.user[nivel][0].idcet.toLowerCase() }` : (
                      user.role.name === "BAMArea" ? `/area/${ query.data.user[nivel][0].idcet.toLowerCase() }` : (
                    //   user.role.name === "BAMDireccion" ? `/direccion/${ slugify(query.data.user[nivel][0].nombre) }` : (
                    //   user.role.name === "BAMDivision" ? `/division/${ slugify(query.data.user[nivel][0].nombre) }` : (
                    //   user.role.name === "BAMDepartamento" ? `/departamento/${ slugify(query.data.user[nivel][0].nombre) }` : (
                    //   user.role.name === "BAMArea" ? `/area/${ slugify(query.data.user[nivel][0].nombre) }` : (
                      user.role.name === "BAMAgente" ? `/agente/${ user.idusuario }` : "" )))))

      return baseURL
    }
    else{
      return "/auth/login"
    }
}

export const mayusculizer = str => str.replace(/\s|-/g, " ,")
    .split(",")
    .map(c => c.slice(0, 1).toUpperCase() + c.slice(1).toLowerCase())
    .reduce((a, b) => a + b, "")

export const subirFotoPerfil = (files, modelo, id, cb) => {
    const body = new FormData()
    body.append("files", files[0])
    body.append("refId", id)
    body.append("ref", modelo)
    body.append("field", "picture")
    if(modelo === "user"){
        body.append("source", "users-permissions")
    }
    request(process.env.REACT_APP_API_URL + "/upload", {
        method: "POST",
        body
    }, false).then(data => {
        message.success("La imagen de perfil se subió correctamente")
        cb(true)
    }).catch(err => {
        message.error("Algo falló durante la subida de la imagen")
        cb(false)
    })
}

export const asociarCoord = (division, coord) => {
    if(division && division._id){
        if(coord.lat <= 90 && coord.lat >= -90 ){
            if(coord.lng <= 180 && coord.lng >= -180 ){
                return request(process.env.REACT_APP_API_URL + "/divisions/" + division._id, {
                    method: "PUT",
                    body: {
                        coordenadas: {
                            lat: parseFloat(coord.lat, 10),
                            lng: parseFloat(coord.lng, 10)
                        }
                    }
                }).then(data => {
                    message.success("Las coordenadas se asociaron correctamente")
                }).catch(err => {
                    console.log(err)
                    message.error("Ocurrió un error durante el guardado de datos")
                })
            }
            else { message.warning("El rango válido de longitud está entre -90 y 90") }
        } else { message.warning("El rango válido de latitud está entre -90 y 90") }
    } else { message.error("División inválida") }
}

export const plantillaMail = (asuntoRaw, cuerpoRaw, variables) => {
    const subject = asuntoRaw.split(/\[|]/gi).map((ch, i) => {
        if(i%2){ return variables[ch] }
        else{ return ch }
    }).reduce((a, b) => a + b, "")
    const html = cuerpoRaw.split(/\[|]/gi).map((ch, i) => {
        if(i%2){ return variables[ch] }
        else{ return ch }
    }).reduce((a, b) => a + b, "")
    return { subject, html }
}

export const mediaVariosMeses = datos => {
    const mpr = [1,2,3,4,5,6].map(resp => {
      const pondTotal = datos.map(d => d["p" + resp]).reduce((a, b) => (a || 0) + b, 0)
      return {
        [resp]: datos.reduce((a, b) => a + ( b["p" + resp]*b["v" + resp] || 0 ), 0) / pondTotal
      }
    }).reduce((a, b) => ({...a, ...b}), {})
    const global = (mpr[1] || 0)*0.4 + (mpr[2] || 0)*0.15 + (mpr[3] || 0)*0.15 + (mpr[4] || 0)*0.15 + (mpr[5] || 0)*0.1 + (mpr[6] || 0)*0.1
    return {...mpr, global}
}