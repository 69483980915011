import React from 'react';
import './styles.scss'

const FichaFoto = ({img, name, centro, puesto, alturatit, alturafoto, action, actionTitle, action2, actionTitle2, mostrarBtn, fechaContrato, abreModal, agente, activo, datos}) => {
    return ( 
        <div className="card shadow ">
            <div className="fichapictureblock" style={{height:alturafoto}}>
                <img alt="" src={img} width="100%" height="100%"/>
            </div>
            <div className="fichatitleblock" style={{height:alturatit}}>
                <h3>{name}</h3>
                <small>{centro && centro.nombre}</small>
                <small>{puesto}</small>
                <small>{fechaContrato ? "Fecha de antigüedad: " + fechaContrato : ""}</small>
                {agente && !activo && <small>Usuario inactivo</small>}
                { mostrarBtn &&
                    <div className="btnActionWrapper">
                        <div onClick={action} className="botonficha">
                            {actionTitle}
                        </div>
                        { actionTitle2 &&
                            <div onClick={action2} className="botonficha">
                                {actionTitle2}
                            </div>
                        }
                    </div>
                }
            </div>
            
        </div>
     );
}
 
export default FichaFoto;