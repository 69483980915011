import React from 'react';
import './styles.scss'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

const BlockMap = withScriptjs(withGoogleMap(({isMarkerShown, defaultCenter, defaultZoom}) => {
    return ( 
        (defaultCenter && defaultCenter.lat && defaultCenter.lng) ? 
        <GoogleMap
            defaultZoom={defaultZoom}
            defaultCenter={defaultCenter}
        >
            {isMarkerShown && <Marker position={defaultCenter} />}
        </GoogleMap> : <span></span>
     );
}))
 
export default BlockMap;