import { isEmpty, difference, flatten } from 'lodash';
import request from './request';

const TOKEN_KEY = 'jwtToken';
const USER_INFO = 'userInfo';

const parse = JSON.parse;
const stringify = JSON.stringify;

const auth = {
  /**
   * Remove an item from the used storage
   * @param  {String} key [description]
   */
  clear(key) {
    if (localStorage && localStorage.getItem(key)) {
      return localStorage.removeItem(key);
    }

    if (sessionStorage && sessionStorage.getItem(key)) {
      return sessionStorage.removeItem(key);
    }

    return null;
  },

  /**
   * Clear all app storage
   */
  clearAppStorage() {
    if (localStorage) {
      localStorage.clear();
    }

    if (sessionStorage) {
      sessionStorage.clear();
    }
  },

  clearToken(tokenKey = TOKEN_KEY) {
    return auth.clear(tokenKey);
  },

  clearUserInfo(userInfo = USER_INFO) {
    return auth.clear(userInfo);
  },

  /**
   * Returns data from storage
   * @param  {String} key Item to get from the storage
   * @return {String|Object}     Data from the storage
   */
  get(key) {
    if (localStorage && localStorage.getItem(key)) {
      return parse(localStorage.getItem(key)) || null;
    }

    if (sessionStorage && sessionStorage.getItem(key)) {
      return parse(sessionStorage.getItem(key)) || null;
    }

    return null;
  },

  getToken(tokenKey = TOKEN_KEY) {
    return auth.get(tokenKey);
  },

  getUserInfo(userInfo = USER_INFO) {
    return auth.get(userInfo);
  },

  /**
   * Set data in storage
   * @param {String|Object}  value    The data to store
   * @param {String}  key
   * @param {Boolean} isLocalStorage  Defines if we need to store in localStorage or sessionStorage
   */
  set(value, key, isLocalStorage) {
    if (isEmpty(value)) {
      return null;
    }

    if (isLocalStorage && localStorage) {
      return localStorage.setItem(key, stringify(value));
    }

    if (sessionStorage) {
      return sessionStorage.setItem(key, stringify(value));
    }

    return null;
  },

  setToken(value = '', isLocalStorage = false, tokenKey = TOKEN_KEY) {
    return auth.set(value, tokenKey, isLocalStorage);
  },

  setUserInfo(value = '', isLocalStorage = false, userInfo = USER_INFO) {
    return auth.set(value, userInfo, isLocalStorage);
  },
  
  
  async puedoEntrar( pagina, idPagina, idUsuario, history){
    //console.log( pagina, idPagina, idUsuario )
    if( pagina === "global" ){
      
    }
    else if( pagina === "subdivision" ){
      const user = await request(`${process.env.REACT_APP_API_URL}/users/${ auth.getUserInfo()._id }`)
      const equipos = await request(process.env.REACT_APP_API_URL + "/graphql?query={equipos{_id%20subdivision}}")
      const eqSubdiv = equipos.data.equipos.filter( e => e.subdivision === idPagina ).map( m => m._id )
      const managerDe = Array.isArray(user.managerDe) && user.managerDe.map(m => m._id)
      if( difference(eqSubdiv, managerDe).length > 0 && user.role.name === "CETManager" ){ history.push(`/equipos/${idPagina === "auto" ? "distribucion" : "auto"}`) } 
    }
    else if( pagina === "equipo" ){
      const user = await request(`${process.env.REACT_APP_API_URL}/users/${ auth.getUserInfo()._id }`)
      const managerDe = Array.isArray(user.managerDe) && user.managerDe.map(m => m._id)
      if( auth.getUserInfo().role.name === "CETManager" && !managerDe.includes( idPagina )){ history.push(`/equipo/${managerDe[0]}`) }
    }
    else if( pagina === "agente" ){
      if( auth.getUserInfo().role.name === "CETAgente" && idUsuario !== idPagina ){ history.push(`/agente/${idUsuario}`) }
      if( auth.getUserInfo().role.name === "CETManager" ){
        const managerDe = ( await request(`${process.env.REACT_APP_API_URL}/users/${ auth.getUserInfo()._id }`) ).managerDe.map(m => m._id)
        const subalternos = flatten(flatten(( await request(process.env.REACT_APP_API_URL + "/graphql?query={equipos{_id%20comerciales{_id}}}") ).data.equipos.filter(e => managerDe.includes(e._id)).map(e => e.comerciales)).map(e => e._id))
        if( !subalternos.includes(idPagina) ){ history.push(`/equipo/${managerDe[0]}`) }
      }
    }
  }
};

export default auth;
