import { 
    CARGAR_DATOS, CARGAR_USUARIOS, CARGAR_ROLES, CARGAR_BASEURL, CARGAR_AGENTES, CARGAR_CONFIG, CARGAR_MEDIAS, CARGAR_DATOBAM, CAMBIAR_FILTRO_FECHA, ESTA_CARGANDO
} from "../constants/action-types"
import request from "../utils/request"
import gql from "../utils/gql"
import moment from 'moment'
import { queryDatos, queryUsuarios, queryMedias, queryDatobam } from '../queries'
import { flattenDeep } from 'lodash'
import { mediaPorResponsabilidad } from '../utils/func';

export function cargarDatos(payload, rol, filtro) {
    var arbol = {}
    var plano = []
    var agentes = {}

    const filtroMod ={
        desde: moment(filtro.desde).isSame(moment(filtro.hasta), "month") ? moment(filtro.desde).subtract(6, "months") : moment(filtro.desde),
        hasta: moment(filtro.hasta),
        unMes: moment(filtro.desde).isSame(moment(filtro.hasta), "month")
    }

    //Que Dios se apiade de mi alma
    if(rol === "Administrator"){
        arbol = payload.data.comitedireccions.map(c => {
            const direccions = c.direccions.map(dir => {
                const divisions = dir.divisions.map(div => {
                    const departamentos = div.departamentos.map(dep => {
                        const unidadareas = dep.unidadareas.map(ar => {
                            const datobams = [...ar.agentes.map(a => a.datobams)].filter(e => e)
                            const medias = mediaPorResponsabilidad(datobams, true, filtroMod)
                            return ({ ...ar, medias, datobams })
                        })
                        const agentes = dep.agentes
                        return ({ ...dep, unidadareas, agentes })
                    })
                    const unidadareas = div.unidadareas.map(ar => {
                        const agentes = div.agentes
                        return ({ ...ar, agentes })
                    })
                    const agentes = div.agentes
                    return ({ ...div, agentes, departamentos, unidadareas })
                })
                const agentes = dir.agentes
                return ({ ...dir, divisions, agentes })
            })      
            return ({ ...c, direccions })
        })

        plano = flattenDeep([
            arbol.map(c => ({ ...c,jerarquia: "comitedireccion"})),
            arbol.map(c => c.direccions.map(e => ({ ...e,jerarquia: "direccion", comite: {nombre: c.nombre, idcet: c.idcet}}))),
            arbol.map(c => c.direccions.map(dir => dir.divisions.map(e => ({ ...e, jerarquia: "division", direccion: { nombre: dir.nombre, idcet: dir.idcet }, comite: { nombre: c.nombre, idcet: c.idcet }})))),
            arbol.map(c => c.direccions.map(dir => dir.divisions.map(div => div.departamentos.map(e => ({ ...e,jerarquia: "departamento", division: { nombre: div.nombre, idcet: div.idcet }, direccion: { nombre: dir.nombre, idcet: dir.idcet }, comite: { nombre: c.nombre, idcet: c.idcet }}))))),
            arbol.map(c => c.direccions.map(dir => dir.divisions.map(div => div.unidadareas.map(e => ({ ...e, jerarquia: "area", division: { nombre: div.nombre, idcet: div.idcet }, direccion: { nombre: dir.nombre, idcet: dir.idcet }, comite: { nombre: c.nombre, idcet: c.idcet }}))))),
            arbol.map(c => c.direccions.map(dir => dir.divisions.map(div => div.departamentos.map(dep => dep.unidadareas.map(e => ({ ...e, jerarquia: "area", departamento: { nombre: dep.nombre, idcet: dep.idcet }, division: { nombre: div.nombre, idcet: div.idcet }, direccion: { nombre: dir.nombre, idcet: dir.idcet }, comite: { nombre: c.nombre, idcet: c.idcet }})))))),
        ])
    
        agentes = flattenDeep([
            arbol.map( c => c.direccions.map( dir => dir.agentes.map(a => ({...a, comite: { nombre: c.nombre, idcet: c.idcet }, direccion: { nombre: dir.nombre, idcet: dir.idcet }}))) ),
            arbol.map( c => c.direccions.map( dir => dir.divisions.map(div => div.agentes.map(a => ({...a, comite: { nombre: c.nombre, idcet: c.idcet}, direccion: { nombre: dir.nombre, idcet: dir.idcet }, division: { nombre: div.nombre, idcet: div.idcet }})))) ),
            arbol.map( c => c.direccions.map( dir => dir.divisions.map(div => div.departamentos.map(dep => dep.agentes.map(a => ({...a, comite: { nombre: c.nombre, idcet: c.idcet }, direccion: { nombre: dir.nombre, idcet: dir.idcet }, division: { nombre: div.nombre, idcet: div.idcet }, departamento: { nombre: dep.nombre, idcet: dep.idcet }}))))) ),
            arbol.map( c => c.direccions.map( dir => dir.divisions.map(div => div.unidadareas.map(ar => ar.agentes.map(a => ({...a, comite: { nombre: c.nombre, idcet: c.idcet }, direccion: { nombre: dir.nombre, idcet: dir.idcet }, division: { nombre: div.nombre, idcet: div.idcet }, area: { nombre: ar.nombre, idcet: ar.idcet }}))))) ),
            arbol.map( c => c.direccions.map( dir => dir.divisions.map(div => div.departamentos.map(dep => dep.unidadareas.map(ar => ar.agentes.map(a => ({...a, comite: { nombre: c.nombre, idcet: c.idcet }, direccion: { nombre: dir.nombre, idcet: dir.idcet }, division: { nombre: div.nombre, idcet: div.idcet }, departamento: { nombre: dep.nombre, idcet: dep.idcet }, area: { nombre: ar.nombre, idcet: ar.idcet }})))))))
        ])
    } else if( rol === "BAMDireccion" ){
        arbol = [payload.data.direccion].map(dir => {
            const datobams = flattenDeep([
                dir.agentes.map(a => a.datobams),
                dir.divisions.map(div => div.agentes.map(a => a.datobams)),
                dir.divisions.map(div => div.departamentos.map(dep => dep.agentes.map(a => a.datobams))),
                dir.divisions.map(div => div.unidadareas.map(ar => ar.agentes.map(a => a.datobams))),
                dir.divisions.map(div => div.departamentos.map(dep => dep.unidadareas.map(ar => ar.agentes.map(a => a.datobams))))
            ]).filter(e => e)
            const medias = mediaPorResponsabilidad(datobams, true, filtroMod)
            const divisions = dir.divisions.map(div => {
                const datobams = flattenDeep([
                    div.agentes.map(a => a.datobams),
                    div.departamentos.map(dep => dep.agentes.map(a => a.datobams)),
                    div.unidadareas.map(ar => ar.agentes.map(a => a.datobams)),
                    div.departamentos.map(dep => dep.unidadareas.map(ar => ar.agentes.map(a => a.datobams)))
                ]).filter(e => e)
                const medias = mediaPorResponsabilidad(datobams, true, filtroMod)
                const departamentos = div.departamentos.map(dep => {
                    const datobams = flattenDeep([
                        dep.agentes.map(a => a.datobams),
                        dep.unidadareas.map(ar => ar.agentes.map(a => a.datobams))
                    ]).filter(e => e)
                    const medias = mediaPorResponsabilidad(datobams, true, filtroMod)
                    const unidadareas = dep.unidadareas.map(ar => {
                        const datobams = [...ar.agentes.map(a => a.datobams)].filter(e => e)
                        const medias = mediaPorResponsabilidad(datobams, true, filtroMod)
                        return ({ ...ar, medias })
                    })
                    const agentes = dep.agentes.map(a => ({ ...a, medias: mediaPorResponsabilidad(a.datobams, false, filtroMod) }) )
                    return ({ ...dep, unidadareas, agentes, medias })
                })
                const unidadareas = div.unidadareas.map(ar => {
                    const datobams = [...ar.agentes.map(a => a.datobams)].filter(e => e)
                    const medias = mediaPorResponsabilidad(datobams, true, filtroMod)
                    const agentes = div.agentes.map(a => ({ ...a, medias: mediaPorResponsabilidad(a.datobams, false, filtroMod) }) )
                    return ({ ...ar, agentes, medias })
                })
                const agentes = div.agentes.map(a => ({ ...a, medias: mediaPorResponsabilidad(a.datobams, false, filtroMod) }) )
                return ({ ...div, agentes, departamentos, unidadareas, medias })
            })
            const agentes = dir.agentes.map(a => ({ ...a, medias: mediaPorResponsabilidad(a.datobams, false, filtroMod) }) )
            return ({ ...dir, divisions, agentes, medias })
        })   

        plano = flattenDeep([
            arbol.map( dir => ({ ...dir,jerarquia: "direccion"})),
            arbol.map( dir => dir.divisions.map(e => ({ ...e, jerarquia: "division", direccion: { nombre: dir.nombre, idcet: dir.idcet }}))),
            arbol.map( dir => dir.divisions.map(div => div.departamentos.map(e => ({ ...e,jerarquia: "departamento", division: { nombre: div.nombre, idcet: div.idcet }, direccion: { nombre: dir.nombre, idcet: dir.idcet }})))),
            arbol.map( dir => dir.divisions.map(div => div.unidadareas.map(e => ({ ...e, jerarquia: "area", division: { nombre: div.nombre, idcet: div.idcet }, direccion: { nombre: dir.nombre, idcet: dir.idcet }})))),
            arbol.map( dir => dir.divisions.map(div => div.departamentos.map(dep => dep.unidadareas.map(e => ({ ...e, jerarquia: "area", departamento: { nombre: dep.nombre, idcet: dep.idcet }, division: { nombre: div.nombre, idcet: div.idcet }, direccion: { nombre: dir.nombre, idcet: dir.idcet }}))))),
        ])
    
        agentes = flattenDeep([
            arbol.map( dir => dir.agentes.map(a => ({...a, direccion: { nombre: dir.nombre, idcet: dir.idcet } })) ),
            arbol.map( dir => dir.divisions.map(div => div.agentes.map(a => ({...a, direccion: { nombre: dir.nombre, idcet: dir.idcet }, division: { nombre: div.nombre, idcet: div.idcet } }))) ),
            arbol.map( dir => dir.divisions.map(div => div.departamentos.map(dep => dep.agentes.map(a => ({...a, direccion: { nombre: dir.nombre, idcet: dir.idcet }, division: { nombre: div.nombre, idcet: div.idcet }, departamento: { nombre: dep.nombre, idcet: dep.idcet } })))) ),
            arbol.map( dir => dir.divisions.map(div => div.unidadareas.map(ar => ar.agentes.map(a => ({...a, direccion: { nombre: dir.nombre, idcet: dir.idcet }, division: { nombre: div.nombre, idcet: div.idcet }, area: { nombre: ar.nombre, idcet: ar.idcet }})))) ),
            arbol.map( dir => dir.divisions.map(div => div.departamentos.map(dep => dep.unidadareas.map(ar => ar.agentes.map(a => ({...a, direccion: { nombre: dir.nombre, idcet: dir.idcet }, division: { nombre: div.nombre, idcet: div.idcet }, departamento: { nombre: dep.nombre, idcet: dep.idcet }, area: { nombre: ar.nombre, idcet: ar.idcet }}))))) ),
        ])   
    } else if( rol === "BAMDivision" ){
        arbol = [payload.data.division].map(div => {
            const datobams = flattenDeep([
                div.agentes.map(a => a.datobams),
                div.departamentos.map(dep => dep.agentes.map(a => a.datobams)),
                div.unidadareas.map(ar => ar.agentes.map(a => a.datobams)),
                div.departamentos.map(dep => dep.unidadareas.map(ar => ar.agentes.map(a => a.datobams)))
            ]).filter(e => e)
            const medias = mediaPorResponsabilidad(datobams, true, filtroMod)
            const departamentos = div.departamentos.map(dep => {
                const datobams = flattenDeep([
                    dep.agentes.map(a => a.datobams),
                    dep.unidadareas.map(ar => ar.agentes.map(a => a.datobams))
                ]).filter(e => e)
                const medias = mediaPorResponsabilidad(datobams, true, filtroMod)
                const unidadareas = dep.unidadareas.map(ar => {
                    const datobams = [...ar.agentes.map(a => a.datobams)].filter(e => e)
                    const medias = mediaPorResponsabilidad(datobams, true, filtroMod)
                    return ({ ...ar, medias })
                })
                const agentes = dep.agentes.map(a => ({ ...a, medias: mediaPorResponsabilidad(a.datobams, false, filtroMod) }) )
                return ({ ...dep, unidadareas, agentes, medias })
            })
            const unidadareas = div.unidadareas.map(ar => {
                const datobams = [...ar.agentes.map(a => a.datobams)].filter(e => e)
                const medias = mediaPorResponsabilidad(datobams, true, filtroMod)
                const agentes = div.agentes.map(a => ({ ...a, medias: mediaPorResponsabilidad(a.datobams, false, filtroMod) }) )
                return ({ ...ar, agentes, medias })
            })
            const agentes = div.agentes.map(a => ({ ...a, medias: mediaPorResponsabilidad(a.datobams, false, filtroMod) }) )
            return ({ ...div, agentes, departamentos, unidadareas, medias })
        })   

        plano = flattenDeep([
            arbol.map( div => ({ ...div,jerarquia: "division"})),
            arbol.map( div => div.departamentos.map(e => ({ ...e,jerarquia: "departamento", division: { nombre: div.nombre, idcet: div.idcet }}))),
            arbol.map( div => div.unidadareas.map(e => ({ ...e, jerarquia: "area", division: { nombre: div.nombre, idcet: div.idcet }}))),
            arbol.map( div => div.departamentos.map(dep => dep.unidadareas.map(e => ({ ...e, jerarquia: "area", departamento: { nombre: dep.nombre, idcet: dep.idcet }, division: { nombre: div.nombre, idcet: div.idcet }}))))
        ])
    
        agentes = flattenDeep([
            arbol.map( div => div.agentes.map(a => ({...a, division: { nombre: div.nombre, idcet: div.idcet }})) ),
            arbol.map( div => div.departamentos.map(dep => dep.agentes.map(a => ({...a, division: { nombre: div.nombre, idcet: div.idcet }, departamento: { nombre: dep.nombre, idcet: dep.idcet }}))) ),
            arbol.map( div => div.unidadareas.map(ar => ar.agentes.map(a => ({...a, division: { nombre: div.nombre, idcet: div.idcet }, area: { nombre: ar.nombre, idcet: ar.idcet }}))) ),
            arbol.map( div => div.departamentos.map(dep => dep.unidadareas.map(ar => ar.agentes.map(a => ({...a, division: { nombre: div.nombre, idcet: div.idcet }, departamento: { nombre: dep.nombre, idcet: dep.idcet }, area: { nombre: ar.nombre, idcet: ar.idcet }})))) ),
        ])   
    } else if( rol === "BAMDepartamento" ){
        arbol = [payload.data.departamento].map(dep => {
            const datobams = flattenDeep([
                dep.agentes.map(a => a.datobams),
                dep.unidadareas.map(ar => ar.agentes.map(a => a.datobams))
            ]).filter(e => e)
            const medias = mediaPorResponsabilidad(datobams, true, filtroMod)

            const unidadareas = dep.unidadareas.map(ar => {
                const datobams = [...ar.agentes.map(a => a.datobams)].filter(e => e)
                const medias = mediaPorResponsabilidad(datobams, true, filtroMod)
                return ({ ...ar, medias })
            })
            const agentes = dep.agentes.map(a => ({ ...a, medias: mediaPorResponsabilidad(a.datobams, false, filtroMod) }) )
            return ({ ...dep, unidadareas, agentes, medias })
        })   

        plano = flattenDeep([
            arbol.map( dep => ({ ...dep,jerarquia: "departamento"})),
            arbol.map( dep => dep.unidadareas.map(e => ({ ...e, jerarquia: "area", departamento: { nombre: dep.nombre, idcet: dep.idcet }})))
        ])
    
        agentes = flattenDeep([
            arbol.map( dep => dep.agentes.map(a => ({...a, departamento: { nombre: dep.nombre, idcet: dep.idcet }})) ),
            arbol.map( dep => dep.unidadareas.map(ar => ar.agentes.map(a => ({...a, departamento: { nombre: dep.nombre, idcet: dep.idcet }, area: ar.nombre }))) ),
        ])   
    } else if( rol === "BAMArea" ){
        arbol = [payload.data.unidadarea].map(ar => {
            const datobams = [...ar.agentes.map(a => a.datobams)].filter(e => e)
            const medias = mediaPorResponsabilidad(datobams, true, filtroMod)
            const agentes = ar.agentes.map(a => ({ ...a, medias: mediaPorResponsabilidad(a.datobams, false, filtroMod) }) )
            return ({ ...ar, agentes, medias })
        })   

        plano = [ arbol.map( ar => ({ ...ar,jerarquia: "area"})) ]
        agentes = [...arbol.map( ar => ar.agentes.map(a => ({...a, area: { nombre: ar.nombre, idcet: ar.idcet }})) )] 
    } else if( rol === "BAMAgente" ){
        const medias = mediaPorResponsabilidad(payload.data.user.datobams, true, filtroMod)
        agentes = [{...payload.data.user, medias}]
    }

    return {
        type: CARGAR_DATOS,
        payload: { arbol, plano, agentes }
    }
}

export const fetchDatos = (rol, idGrupo, filtro, opciones = {}) => {
    return (dispatch) => {
        if(!opciones.sinPantallaCarga){
            dispatch(estaCargando(true))
        }
        return request(gql(queryDatos(rol, idGrupo, filtro))).then(response => {
            dispatch(cargarDatos(response, rol, filtro))
            if(!opciones.noQuitarCargando && !opciones.sinPantallaCarga){
                dispatch(estaCargando(false))
            }
        })
        .catch(err => {
            dispatch(estaCargando(false))
            console.error(err);
        });
    };
};

export function cargarMedias(payload) {
    const medias = payload.data.mediabams
    return {
        type: CARGAR_MEDIAS,
        payload: medias
    }
}
export const fetchMedias = (rol, filtro) => {
    return (dispatch) => {
        return request(gql(queryMedias(filtro))).then(response => {
            dispatch(cargarMedias(response))
        })
        .catch(error => {
            throw(error);
        });
    };
};

export function cargarUsuarios(payload) {
    return {
        type: CARGAR_USUARIOS,
        payload: payload.data.users
    }
}

export function cargarAgentes(payload) {
    const agentes = payload.data.users.filter(u => u.role && u.role.name === "BAMAgente")
    return {
        type: CARGAR_AGENTES,
        payload: agentes
    }
}
  
export const fetchUsuarios = (rol) => {
    return (dispatch) => {
        return request(gql(queryUsuarios(rol))).then(response => {
            dispatch(cargarUsuarios(response))
            dispatch(cargarAgentes(response))
        })
        .catch(error => {
            throw(error);
        });
    };
};

export function cargarRoles(payload) {
    return {
        type: CARGAR_ROLES,
        payload: payload.roles
    }
}
  
export const fetchRoles = (rol) => {
    return (dispatch) => {
        return request(process.env.REACT_APP_API_URL + "/users-permissions/roles").then(response => {
            dispatch(cargarRoles(response))
        })
        .catch(error => {
            throw(error);
        });
    };
};

export function cargarDatobam(payload) {
    return {
        type: CARGAR_DATOBAM,
        payload: payload.data.user || payload.data.datobams
    }
}
  
export const fetchDatobam = (filtro, id) => {
    return (dispatch) => {
        return request(gql(queryDatobam(filtro, id))).then(response => {
            dispatch(cargarDatobam(response))
        })
        .catch(error => {
            throw(error);
        });
    };
};

export function cargarConfig(payload) {
    return {
        type: CARGAR_CONFIG,
        payload: payload[0]
    }
}
  
export const fetchConfig = () => {
    return (dispatch) => {
        return request(process.env.REACT_APP_API_URL + "/configs").then(response => {
            dispatch(cargarConfig(response))
        })
        .catch(error => {
            throw(error);
        });
    };
};

export function cargarBaseURL(payload) {
    return {
        type: CARGAR_BASEURL,
        payload
    }
}

export function cambiarFiltroFecha(payload) {
    return {
        type: CAMBIAR_FILTRO_FECHA,
        payload
    }
}

export function estaCargando(payload) {
    return {
        type: ESTA_CARGANDO,
        payload
    }
}