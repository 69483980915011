import { 
    CAMBIAR_FILTRO_FECHA
} from "../constants/action-types";
import moment from 'moment'

export default function fechaReducer(state = {
    desde: moment().subtract(6, "months").startOf("month"),
    hasta: moment().endOf("month")
}, action) {
    switch (action.type) {
        case CAMBIAR_FILTRO_FECHA:
            // console.log(action)
            return action.payload;
        default:
            return state;
    }
}