import React from 'react';
import BarBlock from '../BarBlock'
import './styles.scss';
import { Rate, Icon, Tooltip, Button } from 'antd'
import moment from 'moment'
import auth from '../../utils/auth';

const Responsabilidades = ({ title, indicador, tipid = "", tip, dataIndicadores = [{}, {}, {}, {}], indMax = 100, indCol1 = 33, indCol2 = 66, stars = false, agenteId, unSoloMes, handleModal }) => {
    const dataIndicadoresFiltrados = unSoloMes ? (Array.isArray(dataIndicadores) && dataIndicadores.filter(d => d.fecha && moment(d.fecha).isSame(unSoloMes, "month"))) : dataIndicadores
    const data4 = Array.isArray(dataIndicadoresFiltrados) ? [
        dataIndicadoresFiltrados.find(d => (d.responsabilidad && (d.responsabilidad.toString() === indicador && d.kpi === 1))) || undefined,
        dataIndicadoresFiltrados.find(d => (d.responsabilidad && (d.responsabilidad.toString() === indicador && d.kpi === 2))) || undefined,
        dataIndicadoresFiltrados.find(d => (d.responsabilidad && (d.responsabilidad.toString() === indicador && d.kpi === 3))) || undefined,
        dataIndicadoresFiltrados.find(d => (d.responsabilidad && (d.responsabilidad.toString() === indicador && d.kpi === 4))) || undefined,
        dataIndicadoresFiltrados.find(d => (d.responsabilidad && (d.responsabilidad.toString() === indicador && d.kpi === 5))) || undefined,
        dataIndicadoresFiltrados.find(d => (d.responsabilidad && (d.responsabilidad.toString() === indicador && d.kpi === 6))) || undefined
] : []

    return (
        <div className="card shadow" style={{ minHeight: 250 }}>
            <div className="titleblock">
                <Tooltip id={tipid} title={tip}>
                    <Icon style={{ width: 15, height: 15 }} type="info-circle" />
                    <h4>{title}</h4>
                </Tooltip>
            </div>
            { stars === false ?
                <div className="indblock">
                    {
                        data4.filter(e => e).map((indicador, i) => {
                            const isPerc = (typeof indicador.descripcionkpi === "string" && indicador.descripcionkpi.includes("%")) ? "%" : ""
                            return (
                                <BarBlock 
                                    desc={indicador ? indicador.descripcionkpi : ""}
                                    key={i} 
                                    val={indicador ? indicador.puntuacion : 0} 
                                    obj={50} 
                                    max={indMax} 
                                    color1={indCol1} 
                                    color2={indCol2} 
                                    // etiq={indicador ? indicador.etiqueta : undefined} 
                                    etiq={
                                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", height: "100%", alignItems: "center" }}>
                                            <strong>{indicador.objetivo + isPerc}</strong>
                                            <strong>{indicador.resultado + isPerc}</strong>
                                        </div>
                                    }
                                    objReal={indicador.objetivo + isPerc}
                                    resReal={indicador.resultado + isPerc}
                                    isPerc={isPerc}
                                    etiqin={true}
                                />
                            )
                        })
                    }
                    {
                        data4.filter(e => e).length === 0 &&
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: 175 }}><span style={{ textAlign: "center" }}>No hay KPI de esa responsabilidad</span></div>
                    }
                </div> :
                <div className="indblock">
                    {
                        data4.filter(e => e).map((indicador, i) =>
                            <div key={ indicador._id } style={{ height: 175, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-evenly" }}>
                                <Rate count={6} defaultValue={(indicador.puntuacion / 20) + 1} allowHalf disabled />
                                {
                                    unSoloMes ? 
                                        <Button type="primary" onClick={() => handleModal(true, indicador)}>{ indicador.respuesta || agenteId !== auth.getUserInfo()._id ? "Ver detalles" : "Validar valoración" }</Button> :
                                        <small style={{ color: "gainsboro", padding: 20 }}>Selecciona un solo mes para ver detalles</small>
                                }
                            </div>
                        )
                    }
                    {
                        data4.filter(e => e).length === 0 &&
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: 175 }}><span style={{ textAlign: "center" }}>No hay valoraciones</span></div>
                    }              
                </div>
            }
        </div>
    );
}

export default Responsabilidades;