import { 
    CARGAR_MEDIAS
} from "../constants/action-types";

export default function mediasReducer(state = [], action) {
    switch (action.type) {
        case CARGAR_MEDIAS:
            // console.log(action)
            return action.payload;
        default:
            return state;
    }
}