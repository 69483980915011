// ACEPTA
// Width en pixeles o 100% 
// Height y Width se pasan como un string
//El porcentaje se pasa como un numero

import React from 'react';
import { detect } from 'detect-browser'

const SquareIndicator = ({heigth = 0, width = 0, marginBottom = 0, strokeWidth = 10, percentaje, title}) => {

    let ajusteporcentaje = (1 - percentaje / 100)
    return ( 
        
        <svg 
            style={{
                boxShadow: '0px 2px 15px rgba(0,0,0,0.2)', 
                //width:`calc(${width} + ${strokeWidth}px)`, 
                width:`calc(${width})`, 
                marginBottom,
                height:`calc( ${heigth} + ${strokeWidth}px)`, 
                borderRadius:30
            }} 
        >
          { detect().name !== "ie" ?
            <foreignObject width="100%" height={heigth} style={{height:`calc(${heigth} + ${strokeWidth}px)`}}>
                  <div style={{display:'flex', backgroundColor:'white', flexDirection:'column', height:"100%", alignItems:'center', justifyContent:'center'}}>
                    <span style={{fontSize:20, color:'gray', fontWeight:400}}>{title}</span>
                    <span style={{fontSize:50, fontWeight:800, lineHeight:'50px'}}>{percentaje}%</span>
                    <span style={{fontSize:20, color:'gray', fontWeight:400}}>Media</span>
                  </div>
            </foreignObject> : <div></div>
          }

          <defs>
            <linearGradient id="red" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#F5515F" />
              <stop offset="100%" stopColor="#9F041B" />
            </linearGradient>
            <linearGradient id="orange" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#FAD961" />
              <stop offset="100%" stopColor="#F76B1C" />
            </linearGradient>
            <linearGradient id="green" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#B4EC51" />
              <stop offset="100%" stopColor="#429321" />
            </linearGradient>
          </defs>
          <rect 
            style={{
                // width:`calc(${width} - ${width==='100%'? strokeWidth : 0 }px )`,
                width:`calc(${width} - ${strokeWidth}px )`,
                strokeDasharray: `calc((${width} * 2) + (${heigth} * 2)) calc((${width} * 2) + (${heigth} *2))`,
                strokeDashoffset: `calc( ( (${width} * 2) + (${heigth} * 2)) * ${ajusteporcentaje} ) `,
                height:`calc( ${heigth} )`
            }}
            className="shape" 
            shapeRendering= 'geometricPrecision'
            strokeLinecap='round'
            rx="25" ry="25"
            
            x={strokeWidth/2}
            y={strokeWidth/2}
            stroke={`url(#${percentaje <= 33 ? 'red' : percentaje <= 66 ? 'orange': 'green'})`}
            strokeWidth={strokeWidth}
            fillOpacity="0"
            />
            
        </svg>
        
     );
}
 
export default SquareIndicator;