import React, { Component } from 'react'
import { Col, Row, Container } from 'react-grid-system'
import FichaFoto from '../components/FichaFoto'
import SpiderBlock from '../components/SpiderBlock'
import BarFiltro from '../components/BarFiltro'
import CardNivel from '../components/CardNivel'
import Ranking from '../components/Ranking'
import ButtonBlock from '../components/ButtonBlock'
import CardTeam from '../components/CardTeam'
import Loader from '../components/Loader'
import { connect } from 'react-redux'
import moment from 'moment'
import ModalEditarDatos from '../components/ModalEditarDatos'
import ModalValoracion from '../components/ModalValoracion'
import ModalComentario from '../components/ModalComentario'
import { mediaVariosMeses, getGrupo, getBaseURL } from '../utils/func'
import auth from '../utils/auth'
import { fetchDatos, fetchMedias } from '../actions'
import "./styles.scss"
import "./styles.css"
import ModalCambiarPw from '../components/ModalCambiarPw';


class DireccionPage extends Component {
  state = {
    direccion: {
      nombre: "",
      medias: {},
      divisions: [],
      managerdireccion: [{
        name: "",
        lastname: ""
      }]
    },
    departamentos: [],
    rankingSelected: {"NEG": 1},
    equipoactivo: "Activos"
  }

  async componentDidMount(){
    if(Array.isArray(this.props.datos.plano) && this.props.medias){
      // const slug = this.props.match.params.idDireccion
      // const indice = slug.slice(-2, -1) === "_" ? parseInt(slug.slice(-1), 10) : 0
      // let direccion = this.props.datos.plano.filter(e => {
      //   const slugTrim = indice > 0 ? slug.slice(0, -2) : slug
      //   return slugTrim === slugify(e.nombre) && e.jerarquia === "direccion"
      // })[indice]
      let direccion = this.props.datos.plano.find(d => d.idcet.toLowerCase() === this.props.match.params.idDireccion)
      if(!direccion){ this.props.history.push(await getBaseURL()) }
      else{
        if(Array.isArray(direccion.agentes)){ direccion.agentes = direccion.agentes.filter(a => !a.blocked) }
        if(Array.isArray(direccion.divisions)){ direccion.divisions = direccion.divisions.filter(d => d.activo || (Array.isArray(d.datobams) && d.datobams.length > 0)) }
        this.setState({ direccion })
        this.seteoInicial(direccion)
      }
    }
  }
  
  async componentDidUpdate(prevProps){
    if((prevProps.datos !== this.props.datos || this.props.medias !== prevProps.medias) && Array.isArray(this.props.datos.plano) && this.props.medias ){
      let direccion = this.props.datos.plano.find(d => d.idcet.toLowerCase() === this.props.match.params.idDireccion)
      // console.log(direccion)
      if(!direccion){ this.props.history.push(await getBaseURL()) }
      else{
        if(Array.isArray(direccion.agentes)){ direccion.agentes = direccion.agentes.filter(a => !a.blocked) }
        if(Array.isArray(direccion.divisions)){ direccion.divisions = direccion.divisions.filter(d => d.activo || (Array.isArray(d.datobams) && d.datobams.length > 0)) }
        this.setState({ direccion })
        this.seteoInicial(direccion)
      }
    }
    if(this.props.fecha !== prevProps.fecha){
      this.props.dispatch(fetchDatos(auth.getUserInfo().role.name, getGrupo(auth.getUserInfo().role.name), this.props.fecha))
      this.props.dispatch(fetchMedias(auth.getUserInfo().role.name, this.props.fecha))
    }
  }

  procesaMedias = direccion => {
    const unMes = moment(this.props.fecha.desde).isSame(this.props.fecha.hasta, "month")
    const divisiones = direccion.divisions
    const mdiv = this.props.medias.filter(m => m.division).filter(m => moment(m.fecha).isSameOrAfter(this.props.fecha.desde, "month") && moment(m.fecha).isSameOrBefore(this.props.fecha.hasta, "month"))
    const mediaPorDivision = divisiones.map(d => {
      const mdFilt = mdiv.filter(m => {
        if(!unMes){ return m.division._id === d._id }
        else{ return m.division._id === d._id && moment(m.fecha).isSame(this.props.fecha.desde, "month") }
      })
      return { 
        _id: d._id, 
        mediasMensuales: mdFilt.map(m => ({[moment.utc(m.fecha).format()]: m})).reduce((a, b) => ({...a, ...b}), {}), 
        medias: mediaVariosMeses(mdFilt)
      }
    })
    const agentes = direccion.agentes
    const muser = this.props.medias.filter(m => m.user).filter(m => moment(m.fecha).isSameOrAfter(this.props.fecha.desde, "month") && moment(m.fecha).isSameOrBefore(this.props.fecha.hasta, "month"))
    const mediaPorAgente = agentes.map(a => {
      const mdFilt = muser.filter(m => m.user._id === a._id)
      return { 
        _id: a._id, 
        mediasMensuales: mdFilt.map(m => ({[moment.utc(m.fecha).format()]: m})).reduce((a, b) => ({...a, ...b}), {}), 
        medias: mediaVariosMeses(mdFilt)
      }
    })

    const mdir = this.props.medias.filter(m => m.direccion)
                                  .filter(m => m.direccion._id === direccion._id)
                                  .filter(m => moment(m.fecha).isSameOrAfter(unMes ? moment(this.props.fecha.desde).subtract(6, "months") : this.props.fecha.desde, "month") && moment(m.fecha).isSameOrBefore(this.props.fecha.hasta, "month"))
    const mediaGlobal = {
      mediasMensuales: mdir.map(m => ({[moment.utc(m.fecha).format()]: m})).reduce((a, b) => ({...a, ...b}), {}), 
      medias: mediaVariosMeses(mdir.filter(m => !unMes ? mdir : mdir && moment(m.fecha).isSame(this.props.fecha.desde, "month")))
    }
    //console.log({ mediaGlobal })
    this.setState({ mediaPorDivision, mediaPorAgente, mediaGlobal })
  }

  seteoInicial = direccion => {
    const managerStr = Object.keys(this.props.datos.arbol[0]).find(k => k.slice(0, 7) === "manager")
    if(auth.getUserInfo().tieneQueCambiarPw){
      this.setState({ modalCambiarPw: true })
    }
    this.procesaMedias(direccion)
    try{
      if((this.props.datos.arbol[0][managerStr][0]._id === auth.getUserInfo()._id) && 
        auth.getUserInfo().entraEncuesta && 
        !(this.props.location.state && !this.props.location.state.sinEncuesta) &&
        this.props.datos.arbol[0].agentes.length > 0){
        this.setState({ modalValoracion: true })
      }
    }
    catch(err){}
  }

  abreModal = (datos, accionModal) => {
    this.setState({
      modalComentario: true,
      datosModalComentario: datos,
      accionModal
    })
  }


  render() {
    const { direccion } = this.state
    return (
          <div>
            <Loader loading={this.props.cargando} />
            <Container fluid style={{ margin: 0, padding: 0 }}>
              <Row style={{ margin: 0, padding: 0 }}>
                <Col style={{ margin: 0, padding: 0 }}>
                  <BarFiltro breadcrumbs={{ comite: direccion.comite, direccion: { nombre: direccion.nombre, idcet:direccion.idcet }}} />
                </Col>
              </Row>
            </Container>

            <Container style={{ marginTop: 50 }}>
              <Row>
                <Col md={6} >
                  <FichaFoto 
                    img={(direccion && direccion.picture) ? process.env.REACT_APP_API_URL + direccion.picture.url : "https://images.pexels.com/photos/273209/pexels-photo-273209.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"} 
                    name={ direccion.nombre } 
                    puesto={ "Director: " + (direccion.managerdireccion && direccion.managerdireccion[0] && ( direccion.managerdireccion[0].name + " " + direccion.managerdireccion[0].lastname ))}
                    alturatit={150} 
                    alturafoto={250} 
                    mostrarBtn={true}
                    action={() => this.setState({ modalEdicion: true })} 
                    actionTitle="Editar datos" 
                  />
                </Col>
                <Col md={6} ><SpiderBlock data={this.state.mediaGlobal && this.state.mediaGlobal.medias} height={400} /></Col>
              </Row>
            </Container>

            <Container style={{ marginTop: 100, padding: 30, border: '3px solid gainsboro', borderRadius: 25, height: 750, marginBottom: 200 }}>
              <Row justify="center">
                <Col md={5} style={{ marginTop: -50, marginBottom: 30, textAlign: 'center', backgroundColor: 'white' }}><h2>Media Nivel x Responsabilidad</h2></Col>
              </Row>
              <Row justify="center">
                <Col md={3}>
                  <CardNivel 
                    title="Garantizar el cumplimiento de los objetivos principales" 
                    currentVal={ this.state.mediaGlobal && this.state.mediaGlobal.medias[1] } 
                    historicVal={ this.state.mediaGlobal && this.state.mediaGlobal.mediasMensuales } 
                    datobams={direccion.datobams}
                    fecha={this.props.fecha}
                    resp={1}
                  />
                </Col>
                <Col md={3}>
                  <CardNivel 
                    title="Garantizar el cumplimiento de los objetivos intermedios" 
                    currentVal={ this.state.mediaGlobal && this.state.mediaGlobal.medias[2] } 
                    historicVal={ this.state.mediaGlobal && this.state.mediaGlobal.mediasMensuales } 
                    datobams={direccion.datobams}
                    fecha={this.props.fecha}
                    resp={2}
                  />
                </Col>
                <Col md={3}>
                  <CardNivel 
                    title="Garantizar la satisfacción del cliente" 
                    currentVal={ this.state.mediaGlobal && this.state.mediaGlobal.medias[3] } 
                    historicVal={ this.state.mediaGlobal && this.state.mediaGlobal.mediasMensuales } 
                    datobams={direccion.datobams}
                    fecha={this.props.fecha}
                    resp={3}
                  />
                </Col>
              </Row>

              <Row justify="center" style={{ marginTop: 50 }}>
                <Col md={3}>
                  <CardNivel 
                    title="Aprovechamiento de las acciones formativas" 
                    currentVal={ this.state.mediaGlobal && this.state.mediaGlobal.medias[4] } 
                    historicVal={ this.state.mediaGlobal && this.state.mediaGlobal.mediasMensuales } 
                    datobams={direccion.datobams}
                    fecha={this.props.fecha}
                    resp={4}
                  />
                </Col>
                <Col md={3}>
                  <CardNivel 
                    title="Garantizar el cumplimiento normativo" 
                    currentVal={ this.state.mediaGlobal && this.state.mediaGlobal.medias[5] } 
                    historicVal={ this.state.mediaGlobal && this.state.mediaGlobal.mediasMensuales } 
                    datobams={direccion.datobams}
                    fecha={this.props.fecha}
                    resp={5}
                  />
                </Col>
                <Col md={3}>
                  <CardNivel stars={true} 
                    title="Valoración del manager" 
                    currentVal={ this.state.mediaGlobal && this.state.mediaGlobal.medias[6] } 
                    historicVal={ this.state.mediaGlobal && this.state.mediaGlobal.mediasMensuales } 
                    datobams={direccion.datobams}
                    fecha={this.props.fecha}
                    resp={6}
                  />
                </Col>
              </Row>
            </Container>


            {/* BLOQUE RANKINGS */}
            { Array.isArray(direccion.divisions) && direccion.divisions.length > 0 &&
              <div>
                <Container style={{ marginTop: 50 }}>
                  <Row align="center" style={{ marginBottom: "1em" }}>
                    <Col md={4}>
                      <h2 style={{ textAlign: "left" }}>Ranking de Divisiones</h2>
                    </Col>
                    <Col offset={{ md: 3 }} md={5}>
                      <ButtonBlock 
                        changeRankingSelected={(rankingSelected) => this.setState({ rankingSelected })} 
                        rankingSelected={Object.keys(this.state.rankingSelected)[0]} 
                        indicadores={[{"NEG": 1}, {"INT": 2}, {"CAL": 3}, {"FOR": 4}, {"NOR": 5}, {"MAN": 6}]} 
                        />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Ranking tipo="division" dataRanking={direccion.divisions} medias={this.state.mediaPorDivision} height={300} rankingSelected={Object.values(this.state.rankingSelected)} placeholder="/stickmangroup.jpg" />
                    </Col>
                  </Row>
                </Container>

                {/* FINAL BLOQUE RANKINGS */}


                <Container style={{ marginTop: 100, marginBottom: 100 }}>
                  <Row >
                    <Col md={4}>
                      <h2 style={{ textAlign: "left", paddingBottom: "1em" }}>Divisiones</h2>
                    </Col>
                  </Row>
                  <Row>
                    { direccion.divisions.map((d, i) => (
                      <Col key={i} md={3}>
                          <CardTeam 
                            indicator={d.estado} 
                            linkTo={"/division/" + d.idcet.toLowerCase()}
                            image={d.picture ? process.env.REACT_APP_API_URL + d.picture.url : "/stickmangroup.jpg"} 
                            title={d.nombre} 
                            abreModal={this.abreModal}
                            datos={d}
                            comentarios={d.comentarios}
                            subtitle={d.managerdivision[0] && (d.managerdivision[0].name + " " + d.managerdivision[0].lastname)} 
                            dataIndicadores={Array.isArray(this.state.mediaPorDivision) && this.state.mediaPorDivision.find(m => m._id === d._id).medias} 
                          />
                      </Col>
                    ))}
                  </Row>
                </Container>
              </div>
            }

            { Array.isArray(direccion.agentes) && direccion.agentes.length > 0 &&
              <div>
                <Container style={{ marginTop: 50 }}>
                  <Row align="center">
                    <Col md={4}>
                      <h2>Ranking de Agentes</h2>
                    </Col>
                    <Col offset={{ md: 3 }} md={5}>
                      <ButtonBlock
                        changeRankingSelected={rankingSelected =>
                          this.setState({ rankingSelected })
                        }
                        rankingSelected={
                          Object.keys(this.state.rankingSelected)[0]
                        }
                        indicadores={[
                          { NEG: 1 },
                          { INT: 2 },
                          { CAL: 3 },
                          { FOR: 4 },
                          { NOR: 5 },
                          { MAN: 6 }
                        ]}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Ranking
                        tipo="agente"
                        dataRanking={direccion.agentes}
                        height={300}
                        rankingSelected={Object.values(
                          this.state.rankingSelected
                        )}
                        placeholder="/stickman.jpg"
                      />
                    </Col>
                  </Row>
                </Container>
                  <Container style={{ marginTop: 100, marginBottom: 50 }}>
                    <Row align="center" style={{ marginBottom: 30 }}>
                        <Col md={4}>
                            <h2>Equipo</h2>
                        </Col>
                        <Col offset={{ md: 5 }} md={3}>
                            <ButtonBlock 
                                changeRankingSelected={(equipoactivo) => this.setState({ equipoactivo })} 
                                rankingSelected={this.state.equipoactivo} 
                                indicadores={[{Activos: "Activos"}, {Inactivos: "Inactivos"}]} 
                                />
                            {/* <ButtonBlockEquipo changeTeamSelected={(equipoactivo) => this.setState({ equipoactivo })} teamSelected={this.state.equipoactivo} /> */}
                        </Col>
                    </Row>
                    <Row>
                        {direccion.agentes.map((a, i) =>
                            <Col key={i} md={3}>
                              <CardTeam 
                                indicator={a.estado} 
                                image={a.imagen ? process.env.REACT_APP_API_URL + a.imagen.url : "/stickman.jpg" } 
                                title={a.name + " " + a.lastname} 
                                dataIndicadores={this.state.mediaPorAgente && this.state.mediaPorAgente.find(m => m._id === a._id).medias} 
                                abreModal={this.abreModal}
                                datos={a}
                                comentarios={a.comentarios}
                                linkTo={"/agente/" + a.idusuario}
                              />
                            </Col>
                        )}
                    </Row>
                  </Container>
              </div>
            }
            
            <ModalValoracion
              visible={this.state.modalValoracion}
              cb={bool => {
                if(bool){ this.props.history.push("/valoraciones") } 
                else{ this.setState({ modalValoracion: false }) }
              }}
            />
            <ModalComentario
              visible={this.state.modalComentario}
              cb={() => this.setState({ modalComentario: false }) }
              comentado={this.state.datosModalComentario}
              accionModal={this.state.accionModal}
            />
            <ModalCambiarPw visible={this.state.modalCambiarPw} cb={bool => this.setState({ modalCambiarPw: false }) } history={this.props.history} />
            <ModalEditarDatos
              visible={this.state.modalEdicion}
              id={direccion._id}
              tipo="direccion"
              fotoActual={direccion.picture}
              cb={refresh => {
                this.setState({ modalEdicion: false })
                if(refresh){ this.props.dispatch(fetchDatos(auth.getUserInfo().role.name, getGrupo(auth.getUserInfo().role.name))) }
              }}
            />

          </div>
    );
  }
}

const mapStateToProps = state => ({
  datos: state.datos,
  medias: state.medias,
  fecha: state.fecha,
  cargando: state.cargando
})

export default connect(mapStateToProps)(DireccionPage);