import React, { Component } from 'react'
import { Col, Row, Container } from 'react-grid-system'
import FichaFoto from '../components/FichaFoto'
import SpiderBlock from '../components/SpiderBlock'
import BarFiltro from '../components/BarFiltro'
import CardNivel from '../components/CardNivel'
import Ranking from '../components/Ranking'
import ButtonBlock from '../components/ButtonBlock'
import CardTeam from '../components/CardTeam'
import Loader from '../components/Loader'
import { connect } from 'react-redux'
import ModalEditarDatos from '../components/ModalEditarDatos'
import ModalValoracion from '../components/ModalValoracion'
import ModalComentario from '../components/ModalComentario';
import { fetchDatos, fetchMedias } from '../actions'
import auth from '../utils/auth'
import moment from 'moment'
import { mediaVariosMeses, getGrupo, getBaseURL } from '../utils/func'
import ModalCambiarPw from '../components/ModalCambiarPw';

class AreaPage extends Component {
    state = {
        area: {
          nombre: "",
          managerarea: [],
          agentes: [],
          medias: {}
        },
        rankingSelected: "1",
        equipoactivo: "Activos"
    }

    async componentDidMount(){
      console.log(this.props.datos.plano)
        if(Array.isArray(this.props.datos.plano)){
          const area = this.props.datos.plano.find(d => d.idcet.toLowerCase() === this.props.match.params.idArea)
          console.log({ area })
          if(Array.isArray(area.agentes)){ area.agentes = area.agentes.filter(a => !a.blocked) }
          if(!area){ this.props.history.push(await getBaseURL()) }          
          this.setState({ area })
          this.seteoInicial(area)
        }
    }
    
    async componentDidUpdate(prevProps){
        if(prevProps.datos !== this.props.datos){
          const area = this.props.datos.plano.find(d => d.idcet.toLowerCase() === this.props.match.params.idArea)
          if(Array.isArray(area.agentes)){ area.agentes = area.agentes.filter(a => !a.blocked) }
          if(!area){ this.props.history.push(await getBaseURL()) }          
          this.setState({ area })
          this.seteoInicial(area)
        }
        if(this.props.fecha !== prevProps.fecha){
            this.props.dispatch(fetchDatos(auth.getUserInfo().role.name, getGrupo(auth.getUserInfo().role.name), this.props.fecha))
            this.props.dispatch(fetchMedias(auth.getUserInfo().role.name, this.props.fecha))
        }
    }

    seteoInicial = area => {
      const managerStr = Object.keys(this.props.datos.arbol[0]).find(k => k.slice(0, 7) === "manager")
      if(auth.getUserInfo().tieneQueCambiarPw){
        this.setState({ modalCambiarPw: true })
      }
      this.procesaMedias(area)
      try{
        if((this.props.datos.arbol[0][managerStr][0]._id === auth.getUserInfo()._id) && 
          auth.getUserInfo().entraEncuesta && 
          !(this.props.location.state && !this.props.location.state.sinEncuesta) &&
          this.props.datos.arbol[0].agentes.length > 0){
          this.setState({ modalValoracion: true })
        }
      }
      catch(err){}
    }

    procesaMedias = area => {
      const unMes = moment(this.props.fecha.desde).isSame(this.props.fecha.hasta, "month")
      const agentes = area.agentes
      const muser = Array.isArray(this.props.medias) ? this.props.medias.filter(m => m.user) : []
      const mediaPorAgente = agentes.map(a => {
        const mdFilt = muser.filter(m => {
          if(!unMes){ return m.user._id === a._id }
          else{ return m.user._id === a._id && moment(m.fecha).isSame(this.props.fecha.desde, "month") }
        })
        return { 
          _id: a._id, 
          mediasMensuales: mdFilt.map(m => ({[moment.utc(m.fecha).format()]: m})).reduce((a, b) => ({...a, ...b}), {}), 
          medias: mediaVariosMeses(mdFilt)
        }
      })
      const mar = this.props.medias.filter(m => m.unidadarea)
                                  .filter(m => m.unidadarea._id === area._id)
                                  .filter(m => moment(m.fecha).isSameOrAfter(unMes ? moment(this.props.fecha.desde).subtract(6, "months") : this.props.fecha.desde, "month") && moment(m.fecha).isSameOrBefore(this.props.fecha.hasta, "month"))
    
      const mediaGlobal = {
        mediasMensuales: mar.map(m => ({[moment.utc(m.fecha).format()]: m})).reduce((a, b) => ({...a, ...b}), {}), 
        medias: mediaVariosMeses(mar.filter(m => !unMes ? mar : mar && moment(m.fecha).isSame(this.props.fecha.desde, "month")))
      }
      console.log({ mediaPorAgente, mediaGlobal })
      this.setState({ mediaPorAgente, mediaGlobal })
    }

    abreModal = (datos, accionModal) => {
      this.setState({
        modalComentario: true,
        datosModalComentario: datos,
        accionModal
      })
    }

    render() {
        const { area } = this.state
        return (
          <div>
            <Loader loading={this.props.cargando} />
            <Container fluid style={{ margin: 0, padding: 0 }}>
              <Row style={{ margin: 0, padding: 0 }}>
                <Col style={{ margin: 0, padding: 0 }}>
                  <BarFiltro
                    // title={area.nombre}
                    fecha={true}
                    centro={true}
                    breadcrumbs={{
                      comite: area.comite,
                      direccion: area.direccion,
                      division: area.division,
                      departamento: area.departamento,
                      area: { nombre: area.nombre, idcet: area.idcet }
                    }}
                  />
                </Col>
              </Row>
            </Container>

            <Container style={{ marginTop: 50 }}>
              <Row debug>
                <Col md={6}>
                  <FichaFoto
                    img={
                      area.picture
                        ? process.env.REACT_APP_API_URL +
                          area.picture.url
                        : "https://images.pexels.com/photos/1595385/pexels-photo-1595385.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                    }
                    name={area.nombre}
                    puesto={
                      "Jefe de equipo: " + area.managerarea &&
                      area.managerarea[0] &&
                      area.managerarea[0].nombre
                    }
                    alturatit={150}
                    alturafoto={250}
                    mostrarBtn={true}
                    action={() => this.setState({ modalEdicion: true })}
                    actionTitle="Editar datos"
                    action2={() => this.abreModal(area, "ver")}
                    actionTitle2="Comentarios"
                  />
                </Col>
                <Col md={6}>
                  <SpiderBlock data={this.state.mediaGlobal && this.state.mediaGlobal.medias} height={400} />
                </Col>
              </Row>
            </Container>

            <Container
              style={{
                marginTop: 100,
                padding: 30,
                border: "3px solid gainsboro",
                borderRadius: 25,
                height: 600,
                marginBottom: 300
              }}
            >
              <Row justify="center">
                <Col
                  md={5}
                  style={{
                    marginTop: -70,
                    marginBottom: 30,
                    textAlign: "center",
                    backgroundColor: "white"
                  }}
                >
                  <h2>Media Nivel x Responsabilidad</h2>
                </Col>
              </Row>
              <Row justify="center">
                <Col md={3}>
                  <CardNivel
                    title="Garantizar el cumplimiento de los objetivos principales"
                    currentVal={ this.state.mediaGlobal && this.state.mediaGlobal.medias[1] } 
                    historicVal={ this.state.mediaGlobal && this.state.mediaGlobal.mediasMensuales } 
                    datobams={area.datobams}
                    fecha={this.props.fecha}
                    resp={1}
                  />
                </Col>
                <Col md={3}>
                  <CardNivel
                    title="Garantizar el cumplimiento de los objetivos intermedios"
                    currentVal={ this.state.mediaGlobal && this.state.mediaGlobal.medias[2] } 
                    historicVal={ this.state.mediaGlobal && this.state.mediaGlobal.mediasMensuales } 
                    datobams={area.datobams}
                    fecha={this.props.fecha}
                    resp={2}
                  />
                </Col>
                <Col md={3}>
                  <CardNivel
                    title="Garantizar la satisfacción del cliente"
                    currentVal={ this.state.mediaGlobal && this.state.mediaGlobal.medias[3] } 
                    historicVal={ this.state.mediaGlobal && this.state.mediaGlobal.mediasMensuales } 
                    datobams={area.datobams}
                    fecha={this.props.fecha}
                    resp={3}
                  />
                </Col>
              </Row>

              <Row justify="center" style={{ marginTop: 50 }}>
                <Col md={3}>
                  <CardNivel
                    title="Aprovechamiento de las acciones formativas"
                    currentVal={ this.state.mediaGlobal && this.state.mediaGlobal.medias[4] } 
                    historicVal={ this.state.mediaGlobal && this.state.mediaGlobal.mediasMensuales } 
                    datobams={area.datobams}
                    fecha={this.props.fecha}
                    resp={4}
                  />
                </Col>
                <Col md={3}>
                  <CardNivel
                    title="Garantizar el cumplimiento normativo"
                    currentVal={ this.state.mediaGlobal && this.state.mediaGlobal.medias[5] } 
                    historicVal={ this.state.mediaGlobal && this.state.mediaGlobal.mediasMensuales } 
                    datobams={area.datobams}
                    fecha={this.props.fecha}
                    resp={5}
                  />
                </Col>
                <Col md={3}>
                  <CardNivel
                    title="Valoración del manager"
                    currentVal={ this.state.mediaGlobal && this.state.mediaGlobal.medias[6] } 
                    historicVal={ this.state.mediaGlobal && this.state.mediaGlobal.mediasMensuales } 
                    datobams={area.datobams}
                    fecha={this.props.fecha}
                    resp={6}
                  />
                </Col>
              </Row>
            </Container>

            {Array.isArray(area.agentes) && area.agentes.length > 0 && (
              <div>
                <Container style={{ marginTop: 50 }}>
                  <Row align="center" style={{ marginBottom: "1em" }}>
                    <Col md={4}>
                      <h2 style={{ textAlign: "left" }}>Ranking de Agentes</h2>
                    </Col>
                    <Col offset={{ md: 3 }} md={5}>
                      <ButtonBlock
                        changeRankingSelected={rankingSelected =>
                          this.setState({ rankingSelected })
                        }
                        rankingSelected={
                          Object.keys(this.state.rankingSelected)[0]
                        }
                        indicadores={[
                          { NEG: 1 },
                          { INT: 2 },
                          { CAL: 3 },
                          { FOR: 4 },
                          { NOR: 5 },
                          { MAN: 6 }
                        ]}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Ranking
                        tipo="agente"
                        dataRanking={area.agentes}
                        medias={this.state.mediaPorAgente}
                        height={300}
                        rankingSelected={this.state.rankingSelected}
                      />
                    </Col>
                  </Row>
                </Container>

                <Container style={{ marginTop: 100, marginBottom: 50 }}>
                  <Row align="center" style={{ marginBottom: "1em" }}>
                    <Col md={4}>
                      <h2 style={{ textAlign: "left" }}>Equipo</h2>
                    </Col>
                  </Row>
                  <Row>
                    {area.agentes.map((a, i) => (
                      <Col key={i} md={3}>
                          <CardTeam
                            indicator={a.estado}
                            title={a.nombre}
                            subtitle={a.jefe}
                            dataIndicadores={this.state.mediaPorAgente && this.state.mediaPorAgente.find(m => m._id === a._id).medias} 
                            linkTo={"/agente/" + a.idusuario}
                            abreModal={this.abreModal}
                            datos={a}
                            comentarios={a.comentarios}
                            image={
                              (a.imagen &&
                                process.env.REACT_APP_API_URL +
                                  a.imagen.url) ||
                              process.env.PUBLIC_URL + "/stickman.jpg"
                            }
                          />
                      </Col>
                    ))}
                  </Row>
                </Container>
              </div>
            )}

            <ModalValoracion
              visible={this.state.modalValoracion}
              cb={bool => {
                if (bool) {
                  this.props.history.push("/valoraciones");
                } else {
                  this.setState({ modalValoracion: false });
                }
              }}
            />
            <ModalComentario
              visible={this.state.modalComentario}
              cb={() => this.setState({ modalComentario: false }) }
              comentado={this.state.datosModalComentario}
              accionModal={this.state.accionModal}
            />
            <ModalCambiarPw visible={this.state.modalCambiarPw} cb={bool => this.setState({ modalCambiarPw: false }) } history={this.props.history} />
            <ModalEditarDatos
              visible={this.state.modalEdicion}
              id={area._id}
              tipo="unidadarea"
              fotoActual={area.picture}
              cb={refresh => {
                this.setState({ modalEdicion: false });
                if (refresh) {
                  this.props.dispatch(
                    fetchDatos(
                      auth.getUserInfo().role.name,
                      getGrupo(auth.getUserInfo().role.name)
                    )
                  );
                }
              }}
            />
          </div>
        );
    }
}

const mapStateToProps = state => ({
    datos: state.datos,
    fecha: state.fecha,
    cargando: state.cargando
})

export default connect(mapStateToProps)(AreaPage);