import React, { Component } from 'react';

class NotFoundPage extends Component {
  constructor(props){
    super(props)
    this.state = {
      mensaje: "Aquí no hay nada",
      opacity: 1
    }
  }
  
  componentDidMount(){
    setInterval( async () => {
      await this.setState({ opacity: 0 })
      setTimeout(() => {
        this.setState({ mensaje: this.state.mensaje === "404" ? "Aquí no hay nada" : "404", opacity: 0 })
      }, 500)
    }, 4000)
  }
  
  async componentDidUpdate(prevProps, prevState){
    if( prevState.mensaje !== this.state.mensaje ){
      setTimeout(() => this.setState({ opacity: 1 }), 500)
    }
  }
  
  render(){
    return (
      <div className="d-flex w-100 justify-content-center align-items-center flex-column" style={{height: "70vh", opacity: this.state.opacity, transition: "all 1000ms"}}>
        <h1 style={{fontSize: 70, color: "gainsboro"}}>{ this.state.mensaje }</h1>
      </div>
    );
  }
}

export default NotFoundPage;
