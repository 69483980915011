// COMO USAR ESTE COMPONENTE
// 1 - SE LE PASA UN ARRAY CON LOS INDICADORES "[{indicador1: bla},{indicador2: ble},{indicador3: blu}]"
// 2 - SE LE PASA UN ESTADO CON EL QUE ESTÁ SELECCIONADO "rankingSelected"
// 3 - SE LE PASA LA FUNCION DE CALLBACK QUE DESDE EL PADRE MODIFICA EL ESTADO rankingSelected "changeRankingSelected"

import React from 'react';
import './styles.scss'

const ButtonBlock = ({rankingSelected, changeRankingSelected, indicadores=[]}) => {
    return (
        <div className="shadow card buttonblock" style={{ flexDirection: "row", flexWrap: "wrap" }}>
            {
                indicadores.map((indicador, i) => (
                    <div key={i} onClick={() => changeRankingSelected(indicador) } style={{ minWidth: 0 }} className={`${Object.keys(indicador)[0] === rankingSelected ? "selected" : ""}  button`}>{Object.keys(indicador)[0]}</div>
                ))
            }
        </div>
    );
}

export default ButtonBlock;