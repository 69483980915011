import React, { useState } from 'react';
import './styles.scss';
import { Link } from 'react-router-dom'
import moment from 'moment'
import { DatePicker, Icon, Button } from 'antd';
import { connect } from 'react-redux'
import { cambiarFiltroFecha } from '../../actions'
import { get } from 'lodash'

const BarFiltro = ({fecha, title="", breadcrumbs = {}, bgImage = "", centro = false, height= 80, maxWidth = 1240, dispatch}) => {

    const [fechaSel, setFechaSel] = useState({
        desde: fecha.desde,
        hasta: fecha.hasta
    })
    const [fechaMod, setFechaMod] = useState(false)

    const bread = [
        breadcrumbs.comite ? <span key="breadcomite"><Link to={"/global"}>{ breadcrumbs.comite.nombre }&nbsp;</Link></span> : "",
        breadcrumbs.direccion ? <span key="breaddireccion"> &gt; <Link to={"/direccion/" + get(breadcrumbs, "direccion.idcet", "").toLowerCase()}>{ breadcrumbs.direccion.nombre }</Link>&nbsp;</span> : "",
        breadcrumbs.division ? <span key="breaddivision"> &gt; <Link to={"/division/" + get(breadcrumbs, "division.idcet", "").toLowerCase()}>{ breadcrumbs.division.nombre }</Link>&nbsp;</span> : "",
        breadcrumbs.departamento ? <span key="breaddepartamento"> &gt; <Link to={"/departamento/" + get(breadcrumbs, "departamento.idcet", "").toLowerCase()}>{ breadcrumbs.departamento.nombre }</Link>&nbsp;</span> : "",
        breadcrumbs.area ? <span key="breadarea"> &gt; <Link to={"/area/" + get(breadcrumbs, "area.idcet", "").toLowerCase()}>{ breadcrumbs.area.nombre }</Link>&nbsp;</span> : "",
        get(breadcrumbs, "agente.id") ? <span key="breadagente"> &gt; <Link to={"/agente/" + breadcrumbs.agente.id}>{ breadcrumbs.agente.nombre }</Link>&nbsp;</span> : ""
    ]

    const showBread = bread.filter(b => b !== "").length > 0

    return (
        <div className="bgfiltro" style={{height: height, backgroundImage:`url("${bgImage}"), linear-gradient(#52977F, #277E6C) `}}>
            <div className="innerFiltro" style={{ maxWidth: maxWidth, margin: "0 auto" }}>
                { showBread &&
                    <div className="breadcrumbs" style={{ 
                        color: "white", 
                        display: "flex", 
                        alignItems: "center", 
                        paddingLeft: "1em", 
                        width: "60%", 
                        flexWrap: "wrap",
                        alignContent: "center"
                    }}>{ bread }</div>
                }
                { title && (
                    <div className="tituloblock">
                        <h1>{title}</h1>
                    </div>
                )}
                <div className="containerFiltro">
                    <div style={{ padding: 20, display: "flex" }}>
                        <DatePicker.MonthPicker 
                            style={{
                                maxWidth: 160,
                                margin: "0 10px",
                            }}
                            placeholder="Desde"
                            onChange={desde => {
                                setFechaSel({ ...fechaSel, desde })
                                setFechaMod(true)
                            }}
                            value={fechaSel && moment(fechaSel.desde)}
                            allowClear={false}
                            suffixIcon={
                                <Icon 
                                type="calendar" 
                                    onClick={() => {
                                        // setFechaSel({ ...fechaSel, hasta: moment(fechaSel.desde).endOf("month") })
                                        // setFechaMod(true)
                                    }}
                                />
                            }
                        />
                        <DatePicker.MonthPicker 
                            style={{
                                maxWidth: 160,
                                margin: "0 10px",
                            }}
                            placeholder="Hasta"
                            onChange={hasta => {
                                setFechaSel({ ...fechaSel, hasta })
                                setFechaMod(true)
                            }}
                            value={fechaSel && moment(fechaSel.hasta)}
                            allowClear={false}
                            suffixIcon={
                                <Icon 
                                    type="calendar" 
                                    onClick={() => {
                                        // setFechaSel({ ...fechaSel, desde: moment(fechaSel.hasta).startOf("month") })
                                        // setFechaMod(true)
                                    }}
                                />
                            }
                        />
                        <Button style={{ 
                            width: fechaMod ? "auto" : 0,  
                            padding: fechaMod ? "0 15px" : 0, 
                            border: fechaMod ? "1px solid #d9d9d9" : 0,
                            overflow: "hidden",
                            marginRight: "10px", 
                            transition: "all 300ms",
                        }} onClick={() => {
                            if(moment(fechaSel.hasta).endOf("month").isBefore(moment(fechaSel.desde).startOf("month"))){
                                setFechaSel({ desde: moment(fechaSel.hasta).startOf("month"), hasta: moment(fechaSel.desde).startOf("month") })
                                dispatch(cambiarFiltroFecha({desde: moment(fechaSel.hasta).startOf("month"), hasta: moment(fechaSel.desde).endOf("month") })) 
                                setFechaMod(false)
                            }
                            else{
                                dispatch(cambiarFiltroFecha({desde: moment(fechaSel.desde).startOf("month"), hasta: moment(fechaSel.hasta).endOf("month") })) 
                                setFechaMod(false)
                            }
                        }}>Aplicar</Button>

                        <Button style={{ 
                            width: fechaMod ? "auto" : 0,  
                            padding: fechaMod ? "0 15px" : 0, 
                            border: fechaMod ? "1px solid #d9d9d9" : 0,
                            overflow: "hidden",
                            transition: "all 300ms",
                        }} onClick={() => {
                            setFechaSel({ desde: moment(fecha.desde).startOf("month"), hasta: moment(fecha.hasta).startOf("month") })
                            setFechaMod(false)
                        }}>&times;</Button>
                    </div>
                </div>
            </div>
        </div>
    )}

const mapStateToProps = state => ({
    fecha: state.fecha
})

export default connect(mapStateToProps)(BarFiltro);