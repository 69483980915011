import React, { Component } from 'react'
import { Modal, Button, Rate, Input, message } from 'antd'
import request from '../utils/request';
import auth from '../utils/auth';
import { fetchDatos } from '../actions';
import { getGrupo, mayusculizer } from '../utils/func';
import _ from 'lodash'
import moment from 'moment'

class ModalResponderValoracion extends Component{ 

    state = {
        respuesta: ""
    }

    acsion = bool => {
        if((bool && this.state.respuesta !== "") || !bool){
            request(process.env.REACT_APP_API_URL + "/users/" + auth.getUserInfo()._id, {
                method: "PUT",
                body: { nuevoComentario: false }
            }).catch(err => console.log("err"))

            if(bool){
                request(process.env.REACT_APP_API_URL + "/datobams/" + this.props.valSeleccionada._id, {
                    method: "PUT",
                    body: { respuesta: this.state.respuesta }
                }).then(data => message.success("La valoración se validó correctamente"))
                  .catch(err => {
                      console.log(err)
                      message.error("Se produjo un error durante la validación de la valoración")
                  })
            }
            this.props.dispatch(fetchDatos(auth.getUserInfo().role.name, getGrupo(auth.getUserInfo().role.name), this.props.fecha));
            this.props.cb()
        }
        else{
            message.warning("No has introducido un comentario")
        }
    }

    render(){
        const { visible, cb, valSeleccionada, agente } = this.props
        const mes = moment(_.get(valSeleccionada, "fecha", ""))
        const mesStr = mes.isValid() ? ", " + mayusculizer(mes.format("MMMM YYYY")) : ""

        return (
            <Modal
                title={ valSeleccionada && valSeleccionada.respuesta ? "Valoración" + mesStr : "Nueva valoración del mánager" + mesStr }
                visible={visible}
                onCancel={() => { if(!!(valSeleccionada && valSeleccionada.respuesta)){cb()}} }
                footer={[
                    <Button editable="true" key="mastarde" onClick={() => this.acsion(false)}>Más tarde</Button>,
                    <Button editable="true" key="validar" type="primary" onClick={() => this.acsion(true)}>Validar</Button>,
                    <Button editable="false" key="aceptar" type="primary" onClick={() => cb()}>Aceptar</Button>
                ].filter(it => !(valSeleccionada && valSeleccionada.respuesta) && (agente && (agente._id === auth.getUserInfo()._id)) === JSON.parse(it.props.editable))}
                closable={!!(valSeleccionada && valSeleccionada.respuesta)}
            >
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div style={{ marginBottom: 30 }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around", marginBottom: 20, width: "100%" }}>
                            <strong>Valoración</strong>
                            <Rate count={6} value={valSeleccionada ? (valSeleccionada.puntuacion / 20 ) + 1 : 0 } />
                        </div>
                        { valSeleccionada && <p style={{ margin: 10 }}><strong>Comentario del jefe de equipo:</strong> { valSeleccionada && valSeleccionada.comentario }</p> }
                    </div>

                    { !!(valSeleccionada && valSeleccionada.respuesta) ? (
                            <p><strong>{agente._id !== auth.getUserInfo()._id ? "Respuesta:" : "Tu comentario: "}</strong>{ valSeleccionada && valSeleccionada.respuesta }</p>
                        ) : ( agente && (agente._id === auth.getUserInfo()._id) ? (
                            <div>
                                <strong style={{ marginBottom: 10 }}>Añadir comentario</strong>
                                <Input.TextArea autosize={{ minRows: 2 }} onChange={evt => this.setState({ respuesta: evt.target.value })} />
                            </div> 
                        ) : <div></div> )
                    }
                </div>
            </Modal>
        )
    }
}

export default ModalResponderValoracion