import React, { Component } from 'react'
import { Card, message, Button, DatePicker, Form, Radio, Statistic, Row, Modal, Switch } from 'antd'
import { connect } from 'react-redux'
import XLSX from 'xlsx'
import moment from 'moment'
import request from '../../utils/request'
import Dropzone from 'react-dropzone'
import { fetchDatos, fetchMedias, estaCargando, fetchDatobam, fetchConfig } from '../../actions'
import auth from '../../utils/auth'
import hadouken from '../../utils/hadouken'
import gql from "../../utils/gql"
import { getGrupo, mayusculizer } from '../../utils/func'
import { partition, chunk, flattenDeep } from 'lodash'

class PanelIE extends Component{
    state = {
        borrando: false,
        desdeExp: moment(),
        hastaExp: moment(),
        desdeRecal: moment(),
        hastaRecal: moment(),
        desdeBorrDatos: moment(),
        hastaBorrDatos: moment(),
        desdeBorrSubida: moment(),
        hastaBorrSubida: moment(),
        tipoBorrado: "datos",
        tambienValoraciones: false
    }

    componentDidUpdate(prevProps){
        if((prevProps.datobam !== this.props.datobam) && this.state.borrando){
            this.borrarDatos2()
        }
    }
    
    descargarDatos = async () => {
        this.props.dispatch(estaCargando(true))
        const datobam = await request(`${process.env.REACT_APP_API_URL}/datobams?_limit=0&fecha_gte=${this.state.desdeExp.startOf("month").utc().format()}&fecha_lte=${this.state.hastaExp.endOf("month").utc().format()}`)
        // const datobam = [].concat.apply([], this.props.datos.agentes.map(a => a.datobams.map(d => ({...d, user: a.email, idusuario: a.idusuario}))))
        // .filter(d => moment(d.fecha).isBetween(this.state.desde, this.state.hasta, "month", "[]"))

        if(datobam.length === 0){
            message.info("No hay datos en ese periodo de tiempo", 5)
        }
        else{
            const json = datobam.map(d => {
                // console.log(d)
                return {
                "FECHA": d.fecha,
                "AGENTE": d.user && d.user.email,
                "UID": d.user && d.user.idusuario,
                "RESPONSABILIDAD": d.responsabilidad,
                "KPI": d.kpi,
                "DESC KPI": d.descripcionkpi,
                "RESULTADO": d.resultado,
                "OBJETIVO": d.objetivo,
                "PONDERACIÓN": d.ponderacion,
                "PUNTUACIÓN": d.puntuacion,
                "ESCALA": d.escala
            }
        })

            const ws = XLSX.utils.json_to_sheet(json);
            const wb = XLSX.utils.book_new();
            const mismoMes = this.state.desdeExp.add(2, "hours").isSame(this.state.hastaExp, "month")
            XLSX.utils.book_append_sheet(wb, ws, `Exportación BAM`);
            XLSX.writeFile(wb, `Exportación BAM ${ mismoMes ? 
                this.state.desdeExp.format("YYYY-MM") : 
                `de ${ this.state.desdeExp.add(2, "hours").format("YYYY-MM")} a ${this.state.hastaExp.format("YYYY-MM")}`}.xlsx`)
        }
        this.props.dispatch(estaCargando(false))
    }

    recalcularMedias = mes => {
        this.props.dispatch(estaCargando(true))
        request("https://83zkx6tv67.execute-api.eu-west-1.amazonaws.com/default/mediasbam", {
            method: "POST",
            body: {
                fechas: {
                    desde: moment.utc(mes).startOf("month").format(),
                    // hasta: moment.utc(this.state.hastaRecal).endOf("month").format()
                    hasta: moment.utc(mes).endOf("month").format()
                },
                jwt: auth.getToken(),
                entorno: process.env.NODE_ENV
            }
        }).then(data => {
            console.log(data)
            if(!data.errorType){
                this.props.dispatch(fetchMedias(auth.getUserInfo().role.name, { desde: moment.utc().startOf("month").subtract(6, "months").format(), hasta: moment.utc().format() }))
                this.props.dispatch(estaCargando(false))
                message.success("Las medias se han recalculado correctamente", 5)
            }
            else{
                message.error("Ha ocurrido un problema al recalcular las medias. (" + data.errorType + ". " + data.errorMessage + ")", 5)
                this.props.dispatch(estaCargando(false))
            }
            
        }).catch(err => {
            console.log(err)
            this.props.dispatch(estaCargando(false))
            message.error("Ha ocurrido un problema al recalcular las medias", 5)
        })
    }
    
    cargarBAM = async uploaded => {
        var errors = []
        var norel = []
        var file = uploaded[0], read = new FileReader();

        const body = new FormData()
        body.append("files", uploaded[0])
        request(process.env.REACT_APP_API_URL + "/upload", {
            method: "POST",
            body
        }, false).then(data => console.log(data)).catch(err => console.log(err))

        this.props.dispatch(estaCargando(true))
        read.readAsBinaryString(file);
        read.onloadend = async () => {
            try{
                var oFile = XLSX.read(read.result, {type: 'binary' })

                const data = XLSX.utils.sheet_to_json(oFile.Sheets[oFile.SheetNames[0]], {cellNF:true, raw:false})

                const fechaUltimosDatosSubidos = moment.utc("2000-01-01T02:00:00+02:00").year(parseInt(data[0]["FECHA"].slice(0, 4), 10)).month(parseInt(data[0]["FECHA"].slice(4), 10) - 1)

                const funsion = async () => {
                    const datosProcesados = data.map(fila => {
                        const finduser = this.props.usuarios.find(e => e.idusuario === fila["UID"].toLowerCase())
                        if(finduser){
                            const pertenencia = finduser.direccionagente    ? { direccion:    finduser.direccionagente._id    } : (
                                                finduser.divisionagente     ? { division:     finduser.divisionagente._id     } : (
                                                finduser.departamentoagente ? { departamento: finduser.departamentoagente._id } : (
                                                finduser.areaagente         ? { unidadarea:   finduser.areaagente._id         } : {}
                            )))

                            return {
                                fecha: moment.utc("2000-01-01T02:00:00+02:00").year(parseInt(fila["FECHA"].slice(0, 4), 10)).month(parseInt(fila["FECHA"].slice(4), 10) - 1).format(),
                                user: finduser ? finduser._id : undefined,
                                responsabilidad: parseFloat(fila["RESPONSABILIDAD"], 10),
                                kpi: parseFloat(fila["KPI"], 10),
                                resultado: parseFloat(fila["RESULTADO"], 10),
                                objetivo: parseFloat(fila["OBJETIVO"], 10),
                                ponderacion: parseFloat(fila["PONDERACIÓN"], 10),
                                descripcionkpi: fila["DESC KPI"],
                                descresponsabilidad: fila["DESC RESPONSABILIDAD"],
                                ...pertenencia
                            }
                        }
                        else{
                            norel.push(fila)
                            return undefined
                        }
                    }).filter(e => e)

            
                    let arrayito = []
                    const dataChunkis = chunk(datosProcesados, 50)
                    for(const dataChunki of dataChunkis){
                        const promesitas = dataChunki.map(async body => {
                            const resp = await request(process.env.REACT_APP_API_URL + "/datobams", {
                                method: "POST",
                                body
                            })
                            if(resp.status >= 400){
                                console.log(resp)
                                errors.push(resp)
                            }
                            return resp
                        })
                        arrayito.push = await Promise.all(promesitas)
                    }


                    // const promesitas = datosProcesados.map(async body => {
                    //     const resp = await request(process.env.REACT_APP_API_URL + "/datobams", {
                    //         method: "POST",
                    //         body
                    //     })
                    //     if(resp.status >= 400){
                    //         console.log(resp)
                    //         errors.push(resp)
                    //     }
                    //     return resp
                    // })
                    
                    // const arrayito = await Promise.all(promesitas)
                    
                    this.props.dispatch(estaCargando(false))
                    if( errors.length > 0 ){
                        console.log({ arrayito, errors })
                        message.error("Ocurrió un error durante la subida de datos", 5)
                    }
                    else{
                        if( norel.length > 0 ){
                            console.log("Los siguientes datos no se corresponden con ningún usuario de la base de datos:")
                            console.log({ norel })
                            message.warning("Los datos que no correspondían a ningún usuario de la base de datos han sido ignorados", 5)
                        }

                        this.recalcularMedias(fechaUltimosDatosSubidos)

                        this.props.dispatch(fetchDatos(auth.getUserInfo().role.name, getGrupo(auth.getUserInfo().role.name), this.props.fecha))
                        request(process.env.REACT_APP_API_URL + "/configs/" + this.props.config._id, {
                            method: "PUT",
                            body: { 
                                fechaUltimaSubidaDatos: moment().utc().format(),
                                fechaUltimosDatosSubidos
                            }
                        }).then(data => {
                            this.props.dispatch(fetchConfig())
                        }).catch(err => console.log(err))
                        message.info("Se han cargado los datos correctamente", 5)

                    }
                }

                if(moment(this.props.config.fechaUltimaEstructura).isSame(fechaUltimosDatosSubidos, "month")){
                    funsion()
                }
                else{
                    this.props.dispatch(estaCargando(false))
                    Modal.warning({
                        title: `La fecha de los datos que intentas subir (${ fechaUltimosDatosSubidos.format("MMMM YYYY")}) no concuerda con la estructura actual (${moment(this.props.config.fechaUltimaEstructura).format("MMMM YYYY")}).

                        Sube la estructura correspondiente antes de subir los datos de ese mes`
                    })
                }
            }
            catch(err) {
                this.props.dispatch(estaCargando(false))
                if(err.message === "File is password-protected"){
                    message.error("El archivo está protegido con contraseña. Por favor, desbloquéalo antes de subirlo a BAM.", 5)
                }
                else{
                    message.error(`Se ha producido un error al leer el archivo (${err.message})`, 5)
                }
            }
        }
    }  

    // borrarDatos = async () => {
    //     this.setState({ borrando: true })
    //     const medias = await request(gql("{ mediabams(limit: 0) { _id createdAt fecha }}"))
    //     this.setState({ medias: medias.data.mediabams })
    //     this.props.dispatch(estaCargando(true))
    //     this.props.dispatch(fetchDatobam())
    // }
    borrarDatos = () => {
        Modal.confirm({
            title: `Confirmar borrado`,
            content: `Vas a borrar datos${this.state.tambienValoraciones ? "," : " y"} medias${this.state.tambienValoraciones ? " y valoraciones" : ""}. ¿Deseas continuar?`,
            onOk: async () => {
                this.setState({ borrando: true })
                const medias = await request(gql("{ mediabams(limit: 0) { _id createdAt fecha }}"))
                this.setState({ medias: medias.data.mediabams })
                this.props.dispatch(estaCargando(true))
                this.props.dispatch(fetchDatobam())
            },
            okText: "Borrar",
            cancelText: "Cancelar"
        });
    }
    borrarDatos2 = async () => {
        const { tipoBorrado, desdeBorrDatos, hastaBorrDatos, desdeBorrSubida, hastaBorrSubida, medias } = this.state
        const { datobam } = this.props

        const desde = tipoBorrado === "datos" ? desdeBorrDatos : desdeBorrSubida
        const hasta = tipoBorrado === "datos" ? hastaBorrDatos : hastaBorrSubida

        const [dbFilter, dbNoBorr] = partition(datobam, d => {
            if(tipoBorrado === "datos"){
                const fecha = moment(d.fecha)
                if(fecha.isAfter(desde.startOf("month")) && fecha.isBefore(hasta.endOf("month")) && (this.state.tambienValoraciones || d.responsabilidad !== 6)){ return d }
            }
            else if(tipoBorrado === "subida"){
                const created = moment(d.createdAt)
                if(created.isAfter(desde.startOf("day")) && created.isBefore(hasta.endOf("day")) && (this.state.tambienValoraciones || d.responsabilidad !== 6)){ return d }
            }
        })

        const mbFilter = medias.filter(m => {
            if(tipoBorrado === "datos"){
                const fecha = moment(m.fecha)
                if(fecha.isAfter(desde.startOf("month")) && fecha.isBefore(hasta.endOf("month"))){ return m }
            }
            else if(tipoBorrado === "subida"){
                const created = moment(m.createdAt)
                if(created.isAfter(desde.startOf("day")) && created.isBefore(hasta.endOf("day"))){ return m }
            }
        })
        console.log({ dbFilter, dbNoBorr, mbFilter })

        const ultimoDatoNoBorrado = dbNoBorr[0]

        const dbFilterChunks = chunk(dbFilter, 50)
        const mbFilterChunks = chunk(mbFilter, 50)

        let dbLog = []
        let mbLog = []

        for(const chunk of dbFilterChunks){
            const promDbChunkiBorr = chunk.map(d => request(process.env.REACT_APP_API_URL + "/datobams/" + d._id, { method: "DELETE" }))
            const respDbChunkiBorr = await Promise.all(promDbChunkiBorr)
            dbLog.push(respDbChunkiBorr)
        }

        for(const chunk of mbFilterChunks){
            const promMbChunkiBorr = chunk.map(m => request(process.env.REACT_APP_API_URL + "/mediabams/" + m._id, { method: "DELETE" }))
            const respMbChunkiBorr = await Promise.all(promMbChunkiBorr)
            mbLog.push(respMbChunkiBorr)
        }

        // console.log({
        //     db: flattenDeep(dbLog), 
        //     mb: flattenDeep(mbLog)
        // })

        request(process.env.REACT_APP_API_URL + "/configs/" + this.props.config._id, {
            method: "PUT",
            body: {
                fechaUltimaSubidaDatos: moment(ultimoDatoNoBorrado.createdAt).utc().format(),
                fechaUltimosDatosSubidos: moment(ultimoDatoNoBorrado.fecha).utc().format()
            }
        }).then(data => this.props.dispatch(fetchConfig())).catch(err => console.log(err))
        this.props.dispatch(estaCargando(false))
    }
    
    render(){
        return(
            <div style={{
                padding: "2em 0"
            }}>
                <h2 style={{ paddingBottom: "1em" }}>Importación y exportación de datos</h2>
                <Card style={{ display: "flex", flexDirection: "column", marginBottom: "1em" }}>
                    <h3 style={{ marginBottom: "1em" }}>Carga de usuarios y estructura</h3>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <div style={{ margin: "0 1em" }}>
                            <small>Fecha de subida de la última estuctura</small>
                            <h4 style={{ marginTop: 2 }}>{this.props.config.fechaSubidaUltimaEstructura ? moment(this.props.config.fechaSubidaUltimaEstructura).format("YYYY - MM - DD") : "-"}</h4>
                        </div>
                        <div style={{ margin: "0 1em" }}>
                            <small>Estructura actual</small>
                            <h4 style={{ marginTop: 2 }}>{this.props.config.fechaUltimaEstructura ? mayusculizer(moment(this.props.config.fechaUltimaEstructura).format("MMMM YYYY")) : "-"}</h4>
                        </div>
                    </div>
                    <div className="dropzoneCleaner" style={{ marginTop: "2em" }}>
                        <Dropzone onDrop={file => hadouken(file, this.props.datos, this.props.fecha, this.props.roles, this.props.dispatch, this.props.config, this.props.usuarios)}>
                        <Button>Carga combinada</Button>
                        </Dropzone>
                    </div>
                </Card>
                <Card style={{ display: "flex", flexDirection: "column", marginBottom: "1em" }}>
                    <h3 style={{ marginBottom: "1em" }}>Carga de datos</h3>
                    {/* <div style={{
                        width: "300px",
                        margin: "0 auto",
                        background: "#fafafa",
                        padding: "2em 0",
                        boxShadow: "1px 3px 3px 0px rgba(0, 0, 0, 0.05)"
                    }}>
                        <p>Muestra de carga de datos</p>
                        <a href="/muestradatos.xlsx"><Button>Descargar</Button></a>
                    </div> */}
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <div style={{ margin: "0 1em" }}>
                            <small>Fecha de subida de los últimos datos</small>
                            <h4 style={{ marginTop: 2 }}>{this.props.config.fechaUltimaSubidaDatos ? moment(this.props.config.fechaUltimaSubidaDatos).format("YYYY - MM - DD") : "-"}</h4>
                        </div>
                        <div style={{ margin: "0 1em" }}>
                            <small>Últimos datos subidos</small>
                            <h4 style={{ marginTop: 2 }}>{this.props.config.fechaUltimosDatosSubidos ? mayusculizer(moment(this.props.config.fechaUltimosDatosSubidos).format("MMMM YYYY")) : "-"}</h4>
                        </div>
                    </div>
                    <div className="dropzoneCleaner" style={{ marginTop: "2em" }}>
                        <Dropzone onDrop={file => this.cargarBAM(file)}>
                            <Button>Cargar datos de BAM</Button>
                        </Dropzone>
                    </div>
                </Card>
                <Card style={{ display: "flex", flexDirection: "column", marginBottom: "1em" }}>
                    <h3 style={{ marginBottom: "1em" }}>Recalcular medias</h3>
                    <Form.Item><DatePicker.MonthPicker allowClear={false} value={this.state.desdeRecal} onChange={desdeRecal => this.setState({ desdeRecal }) }/></Form.Item>
                    {/* <Form.Item label="Hasta"><DatePicker.MonthPicker allowClear={false} value={this.state.hastaRecal} onChange={hastaRecal => this.setState({ hastaRecal }) }/></Form.Item> */}
                    <Button 
                        loading={!(this.props.datos && this.props.datos.agentes)} 
                        disabled={!(this.props.datos && this.props.datos.agentes)} 
                        onClick={() => this.recalcularMedias(this.state.desdeRecal)}
                    >Recalcular</Button>
                </Card>
                <Card style={{ display: "flex", flexDirection: "column", marginBottom: "1em" }}>
                    <h3 style={{ marginBottom: "1em" }}>Exportación de datos</h3>
                    <Form.Item label="Desde"><DatePicker.MonthPicker allowClear={false} value={this.state.desdeExp} onChange={desdeExp => this.setState({ desdeExp }) }/></Form.Item>
                    <Form.Item label="Hasta"><DatePicker.MonthPicker allowClear={false} value={this.state.hastaExp} onChange={hastaExp => this.setState({ hastaExp }) }/></Form.Item>
                    <Button 
                        loading={!(this.props.datos && this.props.datos.agentes)} 
                        disabled={!(this.props.datos && this.props.datos.agentes)} 
                        onClick={this.descargarDatos}
                    >Descargar datos</Button>
                </Card>
                <Card style={{ display: "flex", flexDirection: "column", marginBottom: "1em" }}>
                    <h3 style={{ marginBottom: "1em" }}>Borrado de datos y medias</h3>
                    <Radio.Group defaultValue="datos" buttonStyle="solid" onChange={e => this.setState({ tipoBorrado: e.target.value })} style={{ marginBottom: 20 }}>
                        <Radio.Button value="datos">Por fecha de los datos</Radio.Button>
                        <Radio.Button value="subida">Por fecha de subida</Radio.Button>
                    </Radio.Group>
                    <br />
                    <label htmlFor="tambienValoraciones" style={{ marginRight: "1em" }}>¿Borrar también valoraciones?</label>
                    <Switch name="tambienValoraciones" checked={this.state.tambienValoraciones} onChange={chk => this.setState({ tambienValoraciones: chk })} />
                    { this.state.tipoBorrado === "datos" &&
                        <div>
                        	<Form.Item label="Desde">
                        	    <DatePicker.MonthPicker allowClear={false} value={this.state.desdeBorrDatos} onChange={desdeBorrDatos => this.setState({ desdeBorrDatos }) }/>
                        	</Form.Item>
                        	<Form.Item label="Hasta">
                        	    <DatePicker.MonthPicker allowClear={false} value={this.state.hastaBorrDatos} onChange={hastaBorrDatos => this.setState({ hastaBorrDatos }) }/>
                        	</Form.Item>
                        </div>
                    }
                    { this.state.tipoBorrado === "subida" &&
                        <div>
                        	<Form.Item label="Desde">
                        	    <DatePicker allowClear={false} value={this.state.desdeBorrSubida} onChange={desdeBorrSubida => this.setState({ desdeBorrSubida }) }/>
                        	</Form.Item>
                        	<Form.Item label="Hasta">
                        	    <DatePicker allowClear={false} value={this.state.hastaBorrSubida} onChange={hastaBorrSubida => this.setState({ hastaBorrSubida }) }/>
                        	</Form.Item>
                        </div>
                    }
                    <Button 
                        type="danger"
                        loading={!(this.props.datos && this.props.datos.agentes)} 
                        disabled={!(this.props.datos && this.props.datos.agentes)} 
                        onClick={this.borrarDatos}
                    >Borrar</Button>
                </Card>
            </div>
            )
        }
    }
    
    const mapStateToProps = state => ({
        datos: state.datos,
        medias: state.medias,
        datobam: state.datobam,
        usuarios: state.usuarios,
        fecha: state.fecha,
        roles: state.roles,
        config: state.config
    })
    
    export default connect(mapStateToProps)(PanelIE)