import React from 'react';

const CircularIndicator = ({r, strokeWidth = 15, percentaje}) => {
    
    let length = 2 * Math.PI * r;
    return ( 
        <svg height={(r*2)+(strokeWidth)} width={(r*2)+(strokeWidth)}>
          <defs>
            <linearGradient id="red" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#F5515F" />
              <stop offset="100%" stopColor="#9F041B" />
            </linearGradient>
            <linearGradient id="orange" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#FAD961" />
              <stop offset="100%" stopColor="#F76B1C" />
            </linearGradient>
            <linearGradient id="green" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#B4EC51" />
              <stop offset="100%" stopColor="#429321" />
            </linearGradient>
            <linearGradient id="grey" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#DDDDDD" />
              <stop offset="100%" stopColor="#EEEEEE" />
            </linearGradient>
          </defs>
          <circle 
            strokeDashoffset={percentaje ? length*(1 - percentaje/100) : 0} 
            strokeDasharray={`${length} ${length}`}
            strokeLinecap="round"
            className="circle" 
            cx={(r)+(strokeWidth/2)} 
            cy={(r)+(strokeWidth/2)} 
            r={r} 
            stroke={`url(#${percentaje === undefined ? "grey" : (
                            percentaje <= 33 ? "red" : (
                            percentaje <= 66 ? "orange" : "green"
            ))})`}
            strokeWidth={strokeWidth} 
            fillOpacity="0"
            // fill="red"
            />
            <foreignObject width="100%" height={(r*2)+(strokeWidth)}>
                  
                  <div style={{display:'flex', height:"100%", alignItems:'center', justifyContent:'center'}}>
                    {percentaje === undefined ? <span style={{fontSize:20, fontWeight:600, color:'gainsboro'}}>Sin Datos</span> : <span style={{fontSize: 50, fontWeight:800}}>{percentaje}%</span>}
                  </div>
            </foreignObject>
          
        </svg>
     );
}
 
export default CircularIndicator;