import React from 'react';
import './styles.scss'
import BarBlock from '../BarBlock'


const RankingItem = ({itemImageUrl = "https://pbs.twimg.com/profile_images/1057675340758347782/iGcRjuM-_400x400.jpg", itemTitle, itemVal, itemObj, suff = "%", itemMax = 100, itemColor1 = 33, itemColor2 = 66 }) => {
    
    return (
    <div className="rankingElement">
        <div className="itemImage">
            <img alt="" src={itemImageUrl} />
        </div>
        <div className="itemTitle">
            <span>{itemTitle}</span>
        </div>
        <div className="itemBar">
            <BarBlock val={itemVal} obj={itemObj} max={itemMax} color1={itemColor1} color2={itemColor2} suff={suff} />
        </div>
        <div className="itemValue">
            <span>{itemVal + suff}</span>
        </div>
    </div>
    )
}

export default RankingItem;