import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { getBaseURL } from '../utils/func'
import { connect } from 'react-redux'
import './styles.scss'
import { cargarBaseURL } from '../actions';


const ActualizarPage = () => {
    return (
      <div style={{ display: "flex" }}>
        <div style={{ padding: "0 2em", textAlign: "left"}}>
            <h1 style={{ margin: "20px 0" }}>Instrucciones de actualización de BAM</h1>
            <p>Puesto que BAM es una aplicación web en la mayoría de los casos no es necesaria ningún tipo de interacción del usuario para poder tener la última versión de BAM. Sin embargo en algunos casos el navegador puede tener guardada una versión antigua e ignorar la nueva. Los pasos para forzar la carga de la nueva versión dependen del navegador que estemos utilizando para acceder a BAM</p>
            <h3 style={{ marginBottom: 10 }}>Google Chrome</h3>
            <ul>
                <li>Abra las herramientas de desarrollo con Ctrl+Mayus+I (Cmd+Shift+I en Mac)</li>
                <li>Sin cerrarlas, presione con el botón derecho en el icono de recargar la pestaña y pulse en "Vaciar la caché y recargar de forma forzada"</li>
            </ul>
            <h3 style={{ marginBottom: 10 }}>Mozilla Firefox</h3>
            <ul>
                <li>Presione Ctrl+Shift+R (Cmd+Shift+R en Mac)</li>
            </ul>
            <h3 style={{ marginBottom: 10 }}>Microsoft Edge / Internet Explorer</h3>
            <ul>
                <li>Presione Ctrl+F5</li>
            </ul>
        </div>
      </div>
    )
}

export default ActualizarPage