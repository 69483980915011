import React, { useState, useRef } from 'react'
import { Card, Button, message, Icon } from 'antd'
import Dropzone from 'react-dropzone'
import XLSX from 'xlsx'
import request from '../../utils/request'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { mayusculizer, getGrupo } from '../../utils/func';
import { uniqBy, chunk, flattenDeep, partition } from 'lodash'
import { fetchDatos, fetchUsuarios, estaCargando } from '../../actions';
import auth from '../../utils/auth';
import moment from 'moment';
import { Table, Input } from 'antd'
import Loader from '../../components/Loader';

const PanelUsuarios = ({ cargando, usuarios }) => {
    
    const [searchedColumn, setSearchedColumn] = useState("")
    const [searchText, setSearchText] = useState("")
    const searchInput = useRef(null)

    const getColumnSearchProps = (dataIndex, label) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Buscar por ${label}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<Icon type="search" />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >Buscar</Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>Cancelar</Button>
            </div>
        ),
        filterIcon: filtered => <Icon type="search" style={{ margin: "-20px 10px 0px 0px", width: 15, backgroundColor: "transparent", color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => {
            if(dataIndex === "name"){
                return (
                    (typeof record["name"] === "string" && record["name"].toString().toLowerCase().includes(value.toLowerCase())) ||
                    (typeof record["lastname"] === "string" && record["lastname"].toString().toLowerCase().includes(value.toLowerCase()))
                )
            }
            else{
                return typeof record[dataIndex] === "string" && record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            }
        },
        onFilterDropdownVisibleChange: visible => {
            if (visible) { setTimeout(() => searchInput.current.select()); }
        }
    });
                
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    };
                
    const handleReset = clearFilters => {
        clearFilters();
        setSearchText()
    };
                
                
    return(
        <div style={{
            padding: "2em 0"
        }}>
            <Loader loading={cargando} />
            <h2 style={{ paddingBottom: "1em" }}>Usuarios</h2>
            <Table 
                rowKey="_id"
                dataSource={usuarios}
                columns={[
                    {
                        title: "Nombre",
                        key: "name",
                        render: row => row.name + " " + row.lastname,
                        ...getColumnSearchProps("name", "nombre")
                    },
                    {
                        title: "ID",
                        key: "id",
                        dataIndex: "username",
                        ...getColumnSearchProps("username", "ID")
                    },
                    {
                        title: "Email",
                        key: "email",
                        dataIndex: "email",
                        ...getColumnSearchProps("email", "email")
                    },
                    {
                        title: "Rol",
                        key: "rol",
                        render: row => row.role.name,
                        filters: [
                            {
                                text: 'Administrator',
                                value: 'Administrator',
                            },
                            {
                                text: 'Manager de Dirección',
                                value: 'BAMDireccion',
                            },
                            {
                                text: 'Manager de División',
                                value: 'BAMDivision',
                            },
                            {
                                text: 'Manager de Departamento',
                                value: 'BAMDepartamento',
                            },
                            {
                                text: 'Manager de Área',
                                value: 'BAMArea',
                            },
                            {
                                text: 'Agente',
                                value: 'BAMAgente',
                            }
                        ],
                        onFilter: (value, record) => record.role.name === value,
                        filterIcon: filtered => <Icon type="filter" style={{ margin: "-20px 10px 0px 0px", width: 15, backgroundColor: "transparent", color: filtered ? '#1890ff' : undefined }} />,
                    },
                    {
                        title: "Activo",
                        key: "activo",
                        render: row => !row.blocked ? "Sí" : "No",
                        filters: [
                            {
                                text: 'Sí',
                                value: true,
                            },
                            {
                                text: 'No',
                                value: false,
                            },
                        ],
                        onFilter: (value, record) => !record.blocked === value,
                        filterIcon: filtered => <Icon type="filter" style={{ margin: "-20px 10px 0px 0px", width: 15, backgroundColor: "transparent", color: filtered ? '#1890ff' : undefined }} />,
                    },
                    {
                        title: "",
                        key: "btn",
                        render: row => row.role.name !== "Administrator" && <Link to={"/agente/" + row.username}><Icon type="eye" /></Link>
                    }
                ]}
            />
        </div>
    )
}

const mapStateToProps = state => ({
    usuarios: state.usuarios,
    datos: state.datos,
    roles: state.roles,
    fecha: state.fecha,
    config: state.config,
    cargando: state.cargando
})

export default connect(mapStateToProps)(PanelUsuarios)