import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Tag } from 'antd'
import SortableTree from 'react-sortable-tree'
import 'react-sortable-tree/style.css';

const styles = {
    tag: {
        height: 35,
        display: "flex",
        alignItems: "center"
    }
}

class PanelSecciones extends Component{
    state = {
        cambiado: false,
        treeData: []
    }

    componentDidMount(){
        this.procesaArbol()
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.datos !== this.props.datos){
            this.procesaArbol()
        }
    }

    procesaArbol = () => {
        if(this.props.datos && this.props.datos.arbol){
            const treeData = this.props.datos.arbol.map(c => ({
                title: <Tag color="red" style={styles.tag}>{ c.nombre + " (CET" + c.idcet + ")" }</Tag>,
                children: c.direccions.map(dir => ({
                    title: <Link to={`/direccion/${dir.idcet.toLowerCase()}`}><Tag color="volcano" style={styles.tag}>{ dir.nombre + " (CET" + dir.idcet + ")" }</Tag></Link>,
                    children: dir.divisions.map(div => ({
                        title: <Link to={`/division/${div.idcet.toLowerCase()}`}><Tag color="orange" style={styles.tag}>{ div.nombre + " (CET" + div.idcet + ")" }</Tag></Link>,
                        children: [
                            ...div.departamentos.map(dep => ({
                                title: <Link to={`/departamento/${dep.idcet.toLowerCase()}`}><Tag color="gold" style={styles.tag}>{ dep.nombre + " (CET" + dep.idcet + ")" }</Tag></Link>,
                                children: dep.unidadareas.map(ar => ({
                                    title: <Link to={`/area/${ar.idcet.toLowerCase()}`}><Tag color="lime" style={styles.tag}>{ ar.nombre + " (CET" + ar.idcet + ")" }</Tag></Link>
                                }))
                            })),
                            ...div.unidadareas.map(ar => ({
                                title: <Link to={`/area/${ar.idcet.toLowerCase()}`}><Tag color="lime" style={styles.tag}>{ ar.nombre + " (CET" + ar.idcet + ")" }</Tag></Link>
                            }))
                        ]
                    }))
                }))
            }))
            this.setState({ treeData })
        }
    }

    render(){
        return(
            <div style={{
                padding: "2em 0",
                display: "flex",
                flexDirection: "column"
            }}>
                <h2 style={{ paddingBottom: "1em" }}>Árbol de secciones</h2>
                <div style={{ marginBottom: "1em", height: 800 }}>
                    <SortableTree 
                        treeData={this.state.treeData}
                        onChange={treeData => this.setState({ treeData })}
                        canDrag={ false }
                        canDrop={ () => false }
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    datos: state.datos
})

export default connect(mapStateToProps)(PanelSecciones)