import { 
    CARGAR_CONFIG
} from "../constants/action-types";

export default function configReducer(state = [], action) {
    switch (action.type) {
        case CARGAR_CONFIG:
            // console.log(action)
            return action.payload;
        default:
            return state;
    }
}