import { 
    ESTA_CARGANDO
} from "../constants/action-types";

export default function cargandoReducer(state = true, action) {
    switch (action.type) {
        case ESTA_CARGANDO:
            // console.log(action)
            return action.payload;
        default:
            return state;
    }
}