import React, { Component } from 'react'
import { Tabs } from 'antd'
import PanelEncuesta from './PanelesAdmin/PanelEncuesta'
import PanelMapas from './PanelesAdmin/PanelMapas'
import PanelUsuarios from './PanelesAdmin/PanelUsuarios'
import PanelIE from './PanelesAdmin/PanelIE'
import PanelSecciones from './PanelesAdmin/PanelSecciones'
import PanelEmail from './PanelesAdmin/PanelEmail'
import PanelArchivos from './PanelesAdmin/PanelArchivos'
import Loader from '../components/Loader'
import { connect } from 'react-redux'

class AdminPage extends Component{
    state = {
        user: ""
    }

    componentDidMount(){
        let userInfo = window.sessionStorage.getItem("userInfo")
        if(userInfo){ userInfo = JSON.parse(userInfo) }
        this.setState({ 
            tab: window.localStorage.getItem("ultimaTabAdmin") || "encuesta",
            user: userInfo && userInfo.username
        })
    }

    cambiaTab = tab => { 
        window.localStorage.setItem("ultimaTabAdmin", tab) 
        this.setState({ tab })
    }

    render(){
        const { tab } = this.state
        const { TabPane } = Tabs
        return(
            <div style={{
                display: "flex",
                flexDirection: "column",
                margin: "0 auto",
                width: "100%",
                padding: "1em",
                maxWidth: "1200px"
            }}>
                <Loader loading={this.props.cargando} />
                <Tabs onChange={this.cambiaTab} activeKey={tab}>
                    <TabPane tab="Encuesta" key="encuesta">
                        <PanelEncuesta />
                    </TabPane>
                    <TabPane tab="Mapas" key="mapas">
                        <PanelMapas />
                    </TabPane>
                    <TabPane tab="Importar/Exportar" key="ie">
                        <PanelIE />
                    </TabPane>
                    <TabPane tab="Usuarios" key="usuarios">
                        <PanelUsuarios />
                    </TabPane>
                    <TabPane tab="Secciones" key="secciones">
                        <PanelSecciones />
                    </TabPane>
                    <TabPane tab="Emails" key="email">
                        <PanelEmail />
                    </TabPane>
                    { this.state.user === "root" &&
                        <TabPane tab="Archivos" key="archivos">
                            <PanelArchivos />
                        </TabPane>
                    }
                </Tabs>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    cargando: state.cargando
})

export default connect(mapStateToProps)(AdminPage)