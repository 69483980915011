import React, { Component } from 'react'
import { Container } from 'react-grid-system'
import { Button, message, Skeleton, Rate, Input }  from 'antd'
import { connect } from 'react-redux'
import auth from '../utils/auth';
import request from '../utils/request';
import { getGrupo, plantillaMail, mayusculizer } from '../utils/func';
import { fetchDatos } from '../actions'
import moment from 'moment'
import _ from 'lodash'

class ValoracionesPage extends Component{
    state = {
        loading: true,
        loadingButton: false,
        responderEncuesta: false,
        agentes: []
    }

    componentDidMount(){
        if(this.props.datos.arbol && this.props.datos.arbol[0] && this.props.datos.arbol[0].agentes){
            this.cargaAgentes(this.props.datos.arbol[0].agentes.filter(a => !a.blocked), this.props.datos.plano.find(p => p._id === this.props.datos.arbol[0]._id))
        }
    }
    
    componentDidUpdate(prevProps){
        if(this.props.datos !== prevProps.datos){
            if(this.props.datos.arbol && this.props.datos.arbol[0] && this.props.datos.arbol[0].agentes){
                this.cargaAgentes(this.props.datos.arbol[0].agentes.filter(a => !a.blocked), this.props.datos.plano.find(p => p._id === this.props.datos.arbol[0]._id))
            }
        }
    }

    cargaAgentes = (agentes, equipo) => {
        const valoraciones = agentes.map(a => ({ ["val_" + a._id]: 0, ["com_" + a._id]: ""})).reduce((a, b) => ({...a, ...b}), {})
        this.setState({
            loading: false,
            responderEncuesta: auth.getUserInfo().entraEncuesta,
            agentes,
            equipo,
            ...valoraciones
        })
    }

    handleRowChange = (valoracion, comentario, id) => {
        if(valoracion){ this.setState({["val_" + id]: valoracion }) }
        if(comentario){ this.setState({["com_" + id]: comentario }) }
    }

    enviarValoraciones = async () => {
        const reconstrucsio = this.state.agentes.map(a => ({
            valoracion: this.state["val_" + a._id],
            comentario: this.state["com_" + a._id]
        }))
        const faltanValoraciones = reconstrucsio.some(r => r.valoracion === 0)
        const faltanComentarios = reconstrucsio.some(r => r.comentario === "")

        if(faltanValoraciones || faltanComentarios){
            if(faltanValoraciones){ message.warning("Faltan agentes por valorar")}
            if(faltanComentarios){ message.warning("Faltan agentes por comentar")}
        }
        else{
            this.setState({ loadingButton: true })
            const prom = this.state.agentes.map(a => (
                request(process.env.REACT_APP_API_URL + "/datobams", {
                    method: "POST",
                    body: {
                      fecha: moment(_.get(this.props, "config.fechaValoracion", new Date())).utc().format(),
                      responsabilidad: 6,
                      kpi: 1,
                      ponderacion: 10,
                      comentario: this.state["com_" + a._id],
                      puntuacion: 20 * (this.state["val_" + a._id] - 1),
                      puntponderada: 20 * (this.state["val_" + a._id] - 1) * 0.1,
                      manager: auth.getUserInfo()._id,
                      user: a._id,
                      [this.state.equipo.jerarquia]: this.state.equipo._id,
                      descripcionkpi: "Valoración del manager"
                    }
                }).then(resp => {
                    request(process.env.REACT_APP_API_URL + "/users/" + a._id, {
                        method: "PUT",
                        body: { nuevoComentario: true }
                    }).catch(err => { console.log(err) })

                    if(this.props.config.enviarAgenteValorado){
                        request(process.env.REACT_APP_API_URL + "/email", {
                            method: "POST",
                            body: {
                                from: this.props.config.correobam,
                                to: a.email,
                                // to: "alvarogil@blacknosaur.com",
                                ...plantillaMail(this.props.config.asuntoAgenteValorado, this.props.config.plantillaAgenteValorado, { 
                                    nombre: mayusculizer(a.name), 
                                    jefe: mayusculizer(auth.getUserInfo().name + " " + auth.getUserInfo().lastname)
                                })
                            }
                        }).catch(err => {
                            console.log(err)
                        })
                    }
                    return true
                }).catch(err => {
                    console.log(err)
                    return false
                })
            ))

            const gud = await Promise.all(prom)
            this.setState({ loadingButton: false })
            if(gud.some(e => !e)){
                message.error("Ha ocurrido un error al guardar las valoraciones")
            }
            else{
                message.success("La valoración ha sido enviada correctamente") 
                if(!this.props.config.enviarAgenteValorado){
                    message.info("Las notificaciones a los agentes están deshabilitadas")
                }
                request(process.env.REACT_APP_API_URL + "/users/" + auth.getUserInfo()._id, {
                    method: "PUT",
                    body: { entraEncuesta: false }
                }).then(data => { console.log(data) }).catch(err => console.log(err))
                await this.props.dispatch(fetchDatos(auth.getUserInfo().role.name, getGrupo(auth.getUserInfo().role.name), this.props.fecha))
                const userInfoMod = {...auth.getUserInfo(), entraEncuesta: false}
                auth.setUserInfo(userInfoMod)
                this.props.history.push({
                    pathname: this.props.baseURL,
                    state: { sinEncuesta: true }
                })
            }
        }
    }

    render(){
        return (
            <Container>
                <h2>Valoración del equipo, {mayusculizer(moment(_.get(this.props, "config.fechaValoracion", new Date())).format("MMMM YYYY"))}</h2>
                <div style={{ paddingTop: 50 }}>
                    { !this.state.loading ?
                        <div>
                            {(this.state.responderEncuesta && this.state.agentes.length > 0) ?
                                <div style={{ marginBottom: 50 }}>
                                    { this.state.agentes.map((dato, i) => 
                                        <div key={dato._id} style={{ 
                                            display: "flex", 
                                            flexDirection: "column", 
                                            flex: 2, 
                                            padding: "0 1em 1em 1em", 
                                            alignItems: "flex-start", 
                                            width: "100%", 
                                            marginBottom: "1em", 
                                            borderBottom: this.state.agentes.length !== i + 1 ? "1px solid gainsboro" : "none"
                                        }}>
                                            <span style={{ flex: 1 }}>{ dato.name + " " + dato.lastname }</span>
                                            <div style={{ display: "flex", alignItems: "center", marginBottom: 5 }}>
                                                <small style={{marginRight: "1em"}}>Valoración</small>
                                                <Rate count={6} onChange={val => this.handleRowChange(val, undefined, dato._id)} />
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", width: "100%" }}>
                                                <small>Comentario</small>
                                                <Input.TextArea style={{ width: "100%" }} autosize={{minRows: 2}} onChange={evt => this.handleRowChange(undefined, evt.target.value, dato._id)} />
                                            </div>
                                            {/* <Link to={"/agente/" + dato.idusuario}>
                                                <Button>Ir al perfil</Button>
                                            </Link> */}
                                        </div>
                                    )}
                                    <Button onClick={this.enviarValoraciones} loading={this.state.loadingButton}>Enviar valoraciones</Button>
                                </div>
                                :
                                <h4>{ this.state.agentes.length === 0 ? "No hay agentes que valorar en su equipo": "Actualmente no hay una encuesta de valoración de agentes disponible"}</h4>
                            }
                        </div>
                        : <Skeleton />
                    }
                </div>
            </Container>
        )
    }
}

const mapStateToProps = state => ({
    datos: state.datos,
    baseURL: state.baseURL,
    fecha: state.fecha,
    config: state.config
})

export default connect(mapStateToProps)(ValoracionesPage)