import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Card, Button, message, Modal, DatePicker } from 'antd'
import request from '../../utils/request';
import { plantillaMail } from '../../utils/func'
import moment from 'moment';

class PanelEncuesta extends Component{
    state = {
        notificar: false,
        modalEncuestas: false,
        fechaValoracion: moment()
    }

    componentDidUpdate(prevProps, prevState){
        if((prevProps.config !== this.props.config) && this.props.config){
            let fechaValoracion = moment(this.props.config.fechaValoracion)
            if(!fechaValoracion.isValid()){ fechaValoracion = moment() }
            this.setState({ fechaValoracion })
        }
    }

    habilitarEncuestas = async bool => {
        var errors = []
        this.setState({ modalEncuestasLoading: true })

        request(process.env.REACT_APP_API_URL + "/configs/" + this.props.config._id, {
            method: "PUT",
            body: {
                fechaValoracion: moment.utc(this.state.fechaValoracion).startOf("month").format()
            }
        }).then(resp => {
            console.log({ resp })
        })
        const managerDeAlgo = this.props.usuarios.filter(u => ["BAMArea", "BAMDepartamento", "BAMDivision", "BAMDireccion"].includes(u.role.name))
        const promesitas = managerDeAlgo.map(u => {
            return request(process.env.REACT_APP_API_URL + "/users/" + u._id, {
                method: "PUT",
                body: {
                    entraEncuesta: bool
                }
            }).catch(err => {
                console.log(err)
                errors.push(err)
            })
        })

        await Promise.all(promesitas)

        if(errors.length > 0){
            message.error("Algo falló durante el proceso")
            console.log(errors)
        }
        else{
            if(bool){ 
                message.success("Encuestas habilitadas correctamente") 
                var errCorr = []
                const managerDeAlguien = managerDeAlgo.filter(m => {
                    const agentes = [].concat.apply([], [
                        ...m.direccions,
                        ...m.divisions,
                        ...m.departamentos,
                        ...m.unidadareas
                    ].map(e => e.agentes))
                    return agentes.length
                })

                if(this.props.config.enviarManagerEncuesta){
                    const masPromesitas = managerDeAlguien.map(u => {
                        return request(process.env.REACT_APP_API_URL + "/email", {
                            method: "POST",
                            body: {
                                from: this.props.config.correobam,
                                to: u.email,
                                ...plantillaMail(this.props.config.asuntoManagerEncuesta, this.props.config.plantillaManagerEncuesta, { nombre: u.name })
                            }
                        }).catch(err => {
                            console.log(err)
                            errCorr.push(err)
                        })
                    })

                    await Promise.all(masPromesitas)
                    if(errCorr.length > 0){
                        message.error("Algunos de los correos no fueron enviados")
                        console.log(errCorr)
                    }
                    else{
                        message.success("Todos los manager fueron notificados correctamente")
                    }
                }
                else{
                    message.info("Las notificaciones para avisar a los manager de que las encuestas están disponibles están desactivadas")
                }
            }
            else{ message.success("Encuestas deshabilitadas correctamente")}
        }


        this.setState({ modalEncuestas: false, modalEncuestasDesh: false, modalEncuestasLoading: false })
    }

    render(){
        return(
            <div style={{
                padding: "2em 0"
            }}>
                <h2 style={{ paddingBottom: "1em" }}>Encuesta</h2>
                <Card style={{ display: "flex", flexDirection: "column", marginBottom: "1em" }}>
                    <h3 style={{ marginBottom: "1em" }}>Mostrar encuesta sobre los agentes a los manager la próxima vez que accedan a BAM</h3>
                    <div>
                        <label htmlFor="fechaValoracion" style={{ marginRight: "1em" }}>Fecha de las valoraciones</label>
                        <DatePicker.MonthPicker
                            onChange={e => this.setState({ fechaValoracion: e })} 
                            value={this.state.fechaValoracion}
                            style={{ marginBottom: "1em" }}
                        />
                    </div>
                    <Button onClick={() => this.setState({ modalEncuestas: true })} >Aceptar</Button>
                </Card>
                <Card style={{ display: "flex", flexDirection: "column" }}>
                    <h3 style={{ marginBottom: "1em" }}>Deshabilitar encuesta para los manager que no la hayan respondido aun</h3>
                    <Button onClick={() => this.setState({ modalEncuestasDesh: true })} >Aceptar</Button>
                </Card>
                <Modal
                    visible={this.state.modalEncuestas}
                    onOk={() => this.habilitarEncuestas(true)}
                    onCancel={() => this.setState({ modalEncuestas: false })}
                    okButtonProps={{ loading: this.state.modalEncuestasLoading }}
                    okText="Continuar"
                    cancelText="Cancelar"
                    closable={false}
                >
                    <p>Todos los manager tendrán que responder a la encuesta sobre sus agentes la próxima vez que accedan a la plataforma. ¿Desea continuar?</p>
                </Modal>
                <Modal
                    visible={this.state.modalEncuestasDesh}
                    onOk={() => this.habilitarEncuestas(false)}
                    onCancel={() => this.setState({ modalEncuestasDesh: false })}
                    okButtonProps={{ loading: this.state.modalEncuestasLoading }}
                    okText="Continuar"
                    cancelText="Cancelar"
                    closable={false}
                >
                    <p>Todos los manager que no hayan respondido a la encuesta no podrán acceder a ella hasta que se vuelva a habilitar. ¿Desea continuar?</p>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    usuarios: state.usuarios,
    config: state.config
})

export default connect(mapStateToProps)(PanelEncuesta)