import React, { Component } from 'react'
import { Modal, Form, Input, Comment, Button, message } from 'antd'
import moment from 'moment'
import { connect } from 'react-redux'
import auth from '../utils/auth';
import request from '../utils/request';
import { getGrupo, plantillaMail, mayusculizer } from '../utils/func'
import { fetchDatos } from '../actions';

class ModalComentario extends Component{
    state = {
        value: ""
    }

    enviarComentario = () => {
        if(this.state.value === ""){ message.warning("El comentario está vacío") }
        else{
            request(process.env.REACT_APP_API_URL + "/comentarios", {
                method: "POST",
                body: {
                    contenido: this.state.value,
                    nuevo: true,
                    direccion: this.props.comentado._id,
                    division: this.props.comentado._id,
                    departamento: this.props.comentado._id,
                    area: this.props.comentado._id,
                    para: this.props.comentado._id,
                    de: auth.getUserInfo()._id
                }
            }).then(async com => {
                this.props.cb()
                if(this.props.config.enviarManagerComentario){
                    const aQuien = (!com.direccion && !com.division && !com.departamento && !com.area) ? 
                        {...this.props.usuarios.find(u => u._id === com.para.id), tipo: "agente"} : //Es un agente
                        {...(com.direccion || com.division || com.departamento || com.area ), tipo: "equipo"} //Es un equipo

                    const responsable = aQuien.tipo === "agente" ? (
                            aQuien.direccionagente || 
                            aQuien.divisionagente || 
                            aQuien.departamentoagente|| 
                            aQuien.areaagente
                        ) :
                        this.props.datos.plano.find(e => e._id === aQuien._id)

                    const email = responsable[Object.keys(responsable).find(k => k.slice(0, 7) === "manager")].map(u => u.email)     
                    
                    if(email.length === 0){ message.warning(`No se notificará el envío del comentario, no hay asignado ningún responsable del ${aQuien.tipo === "agente" ? "agente" : "equipo"}`)}
                    else{
                        const prom = email.map((e, i) => (
                            request(process.env.REACT_APP_API_URL + "/email", {
                                method: "POST",
                                body: {
                                    from: this.props.config.correobam,
                                    to: e,
                                    //to: "alvarogil@blacknosaur.com",
                                    ...plantillaMail(this.props.config.asuntoManagerComentario, this.props.config.plantillaManagerComentario, { 
                                        nombre: mayusculizer(responsable.name || responsable[Object.keys(responsable).find(k => k.slice(0, 7) === "manager")][i].name), 
                                        jefe: mayusculizer(auth.getUserInfo().name + " " + auth.getUserInfo().lastname)
                                    })
                                }
                            }).then(email => true).catch(err => {
                                console.log(err)
                                return false
                            })
                        ))
                        const resp = await Promise.all(prom)
                        if(resp.some(r => !r)){
                            message.error("Ocurrió un error durante el envío de la notificación por email al responsable")
                        }
                    }
                }
                else{
                    message.info("El responsable no ha sido notificado, las notificaciones están deshabilitadas")
                }
                await this.props.dispatch(fetchDatos(auth.getUserInfo().role.name, getGrupo(auth.getUserInfo().role.name), this.props.fecha, { sinPantallaCarga: true }))
                message.success("El comentario se publicó correctamente")
            }).catch(err => {
                console.log(err)
                message.error("Ocurrió un error durante el envío del comentario")
            })
        }
    }

    render(){
        const { comentado = {comentariosRecibidos: []}, cb, visible, accionModal } = this.props
        return (
            <Modal
                title="Comentarios"
                visible={visible}
                onCancel={cb}
                footer={
                    accionModal === "comentar" ?
                        [
                            <Button key="comentarioCancelar" onClick={cb}>Cancelar</Button>,
                            <Button key="comentarioEnviar" type="primary" onClick={this.enviarComentario}>Enviar</Button>
                        ] : <Button type="primary" onClick={cb}>Aceptar</Button>
                }
            >
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    { accionModal === "comentar" ? 
                        comentado && (
                            <Form.Item>
                                <p>Vas a escribir un comentario a {comentado.name ? (comentado.name + " " + comentado.lastname) : comentado.nombre}</p>
                                <Input.TextArea autosize={true} value={this.state.value} onChange={evt => this.setState({ value: evt.target.value })} />
                            </Form.Item>
                        ) :
                        <div>
                            {
                                comentado.comentariosRecibidos.length > 0 ? 
                                    comentado.comentariosRecibidos.map((c, i) => (
                                        <Comment
                                            key={`comentario${i}`}
                                            author={c.de.name + " " + c.de.lastname}
                                            content={<div><span>{ c.contenido }</span><small style={{ color: "#ccc", marginLeft: "10px" }}>{ c.nuevo ? "(no leído)" : "" }</small></div>}
                                            datetime={<span>{moment(c.createdAt).format("LLL")}</span>}
                                        />
                                    )) :
                                    <p>{comentado.name ? (comentado.name + " " + comentado.lastname) : comentado.nombre} aún no tiene ningún comentario </p>
                            }
                        </div>
                    }
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = state => ({
    datos: state.datos,
    fecha: state.fecha,
    config: state.config,
    usuarios: state.usuarios
})

export default connect(mapStateToProps)(ModalComentario)