import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Card, Button, Modal, Table, Input } from 'antd'
import { getGrupo, asociarCoord } from '../../utils/func';
import auth from '../../utils/auth'
import { fetchDatos } from '../../actions'

class PanelMapas extends Component{
    reset = { lat: 40, lng: -3 }
    state = {
        coordenadas: this.reset
    }

    componentDidUpdate(prevProps, prevState){
        if(prevState.modalAsociar !== this.state.modalAsociar && this.state.modalAsociar === true){
            if(this.state.divisionAsociar && this.state.divisionAsociar.coordenadas){
                if(this.state.divisionAsociar.coordenadas.lat !== undefined && this.state.divisionAsociar.coordenadas.lng !== undefined){
                    this.setState({ coordenadas: {
                        lat: this.state.divisionAsociar.coordenadas.lat,
                        lng: this.state.divisionAsociar.coordenadas.lng
                    }})
                }
            }
        }
    }

    render(){
        return(
            <div style={{
                padding: "2em 0"
            }}>
                <h2 style={{ paddingBottom: "1em" }}>Coordenadas</h2>
                <Card style={{ display: "flex", flexDirection: "column", marginBottom: "1em" }}>
                    <h3 style={{ marginBottom: "1em" }}>Divisiones</h3>
                    <Table rowKey="_id" dataSource={Array.isArray(this.props.datos.plano) ? this.props.datos.plano.filter(s => s.jerarquia === "division") : []} columns={[{
                        title: "División",
                        dataIndex: "nombre",
                        key: "_id"
                    }, {
                        title: 'Coordenadas',
                        key: 'coordenadas',
                        render: (text, record) => (
                                    (record.coordenadas && record.coordenadas.lat) ?
                                        <a  key={record._id + "maplink"} href={`http://www.google.com/maps/place/${record.coordenadas.lat},${record.coordenadas.lng}`}>{`${record.coordenadas.lat}, ${record.coordenadas.lng}`}</a> :
                                        <span key={record._id + "nomap"}>La división no tiene coordenadas asociadas</span>
                        )
                    }, {
                        title: '',
                        key: 'btn',
                        render: (text, record) => (
                            <Button key={record._id + "btn"} onClick={() => this.setState({ modalAsociar: true, divisionAsociar: record })}>Asociar</Button>
                        )
                    }]}/>
                </Card>

                <Modal
                    visible={this.state.modalAsociar}
                    onOk={async () => {
                        this.setState({ modalAsociarLoading: true })
                        await asociarCoord(this.state.divisionAsociar, this.state.coordenadas)
                        this.setState({ modalAsociar: false, modalAsociarLoading: false, divisionAsociar: undefined, coordenadas: this.reset })
                        this.props.dispatch(fetchDatos(auth.getUserInfo().role.name, getGrupo(auth.getUserInfo().role.name), this.props.fecha))
                    }}
                    onCancel={() => this.setState({ modalAsociar: false, divisionAsociar: undefined, coordenadas: this.reset })}
                    okButtonProps={{ loading: this.state.modalEncuestasLoading }}
                    okText="Continuar"
                    cancelText="Cancelar"
                    closable={false}
                >
                    <h3 style={{ marginBottom: 20 }}>Editando las coordenadas de {this.state.divisionAsociar && this.state.divisionAsociar.nombre}</h3>
                    <p>Latitud<Input type="number" value={this.state.coordenadas.lat} onChange={evt => this.setState({ coordenadas: { lat: evt.target.value, lng: this.state.coordenadas.lng } }) }/></p>
                    <p>Longitud<Input type="number" value={this.state.coordenadas.lng} onChange={evt => this.setState({ coordenadas: { lat: this.state.coordenadas.lat, lng: evt.target.value } }) }/></p>
                    <a href={`http://www.google.com/maps/place/${this.state.coordenadas.lat},${this.state.coordenadas.lng}`} rel="noopener noreferrer" target="_blank"><Button>Probar ubicación</Button></a>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    datos: state.datos,
    fecha: state.fecha
})

export default connect(mapStateToProps)(PanelMapas)