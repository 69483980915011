import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Col, Row, Container } from 'react-grid-system'
import { Link } from "react-router-dom"
import SquareIndicator from '../components/SquareIndicator'
import BarFiltro from '../components/BarFiltro'
import CardNivel from '../components/CardNivel'
import SpiderBlock from '../components/SpiderBlock'
import ButtonBlock from '../components/ButtonBlock'
import Ranking from '../components/Ranking'
import Loader from '../components/Loader'
import bgImage from '../assets/bgbam.jpg'
import { redondear, getGrupo, mediaVariosMeses } from '../utils/func'
import auth from '../utils/auth'
import { fetchDatos, fetchMedias } from '../actions'
import ModalCambiarPw from '../components/ModalCambiarPw';
import moment from 'moment'

class GlobalPage extends Component {

  state = {
    rankingSelected: {"NEG": 1},
    direccions: [],
    loading: true
  }

  componentDidMount() {
    if(this.props.datos && this.props.datos.arbol){
      if(auth.getUserInfo().tieneQueCambiarPw){
        this.setState({ modalCambiarPw: true })
      }
    }
    if(this.props.medias && this.props.datos && this.props.datos.arbol){
      this.procesaMedias()
    }
  }

  componentDidUpdate(prevProps) {
    if(this.props.fecha !== prevProps.fecha){
      this.props.dispatch(fetchDatos(auth.getUserInfo().role.name, getGrupo(auth.getUserInfo().role.name), this.props.fecha))
      this.props.dispatch(fetchMedias(auth.getUserInfo().role.name, this.props.fecha))
    }
    else if(this.props.medias !== prevProps.medias){
      this.procesaMedias()
    }
  }

  procesaMedias = () => {
    const unMes = moment(this.props.fecha.desde).isSame(this.props.fecha.hasta, "month")
    // console.log({ unMes })
    const md = this.props.medias.filter(m => m.direccion).filter(m => moment(m.fecha).isSameOrAfter(this.props.fecha.desde, "month") && moment(m.fecha).isSameOrBefore(this.props.fecha.hasta, "month"))
    const direcciones = [...new Set(md.map(d => d.direccion._id))]
    const mediaPorDireccion = direcciones.map(d => {
      const mdFilt = md.filter(m => {
        if(!unMes){ return m.direccion._id === d }
        else{ return m.direccion._id === d && moment(m.fecha).isSame(this.props.fecha.desde, "month") }
      })
      return { 
        _id: d, 
        mediasMensuales: mdFilt.map(m => ({[moment.utc(m.fecha).format()]: m})).reduce((a, b) => ({...a, ...b}), {}), 
        medias: mediaVariosMeses(mdFilt)
      }
    })
    const mediaGlobal = {
      mediasMensuales: this.props.medias.filter(m => m.comitedireccion).map(m => ({[moment.utc(m.fecha).format()]: m})).reduce((a, b) => ({...a, ...b}), {}), 
      medias: mediaVariosMeses( this.props.medias.filter(m => {
        if(!unMes){ return m.comitedireccion }
        else{ return (m.comitedireccion && moment(m.fecha).isSame(this.props.fecha.desde, "month")) }
      }))
    }
    this.setState({ mediaPorDireccion, mediaGlobal })
  }

  render() {
    return (
          <div>
            <Loader loading={this.props.cargando} />
            {/* CABECERA CON FILTRO DE FECHAS E INDICADORES */}
            <Container fluid style={{ margin: 0, padding: 0 }}>
              <Row style={{ margin: 0, padding: 0 }}>
                <Col style={{ margin: 0, padding: 0 }}>
                  <BarFiltro height={160} title={"Posición Global"} bgImage={bgImage} dispatch={this.props.dispatch} />
                </Col>
              </Row>
            </Container>

            <Container style={{ marginTop: '-75px' }}>
              <Row>
                {( this.props.datos.arbol && this.props.datos.arbol[0] && Array.isArray(this.props.datos.arbol[0].direccions)) ? 
                  this.props.datos.arbol[0].direccions.filter(d => d.activo || (d && Array.isArray(d.datobams) && d.datobams.length > 0)).map((d, i) => {
                    return (
                      <Col key={`fila${i}`} md={3}>
                        <Link to={`/direccion/${d.idcet.toLowerCase()}`}>
                          <SquareIndicator md={3} title={d.nombre} percentaje={redondear(this.state.mediaPorDireccion && this.state.mediaPorDireccion.find(m => m._id === d._id) && this.state.mediaPorDireccion.find(m => m._id === d._id).medias.global)} heigth="180px" width='100%' marginBottom={20} />
                        </Link>
                      </Col>
                    )
                  }) : <div></div>} 
              </Row>

            </Container>

            {/* FIN CABECERA CON FILTRO DE FECHAS E INDICADORES */}

            {/* BLOQUE RANKING Y GRAFICO ARAÑA*/}

            <Container style={{ marginTop: 50, marginBottom: 50, }}>
              <Row>

                <Col md={7}>
                  <h2>Ranking de Areas</h2>
                  <ButtonBlock 
                    changeRankingSelected={rankingSelected => this.setState({ rankingSelected })} 
                    rankingSelected={Object.keys(this.state.rankingSelected)[0]} 
                    indicadores={[{"NEG": 1}, {"INT": 2}, {"CAL": 3}, {"FOR": 4}, {"NOR": 5}, {"MAN": 6}]} 
                  />
                  <div style={{ marginTop: '15px' }}></div>
                  <Ranking tipo="direccion" dataRanking={this.props.datos.arbol && this.props.datos.arbol[0] && this.props.datos.arbol[0].direccions} medias={this.state.mediaPorDireccion} rankingSelected={Object.values(this.state.rankingSelected)} placeholder="/stickmangroup.jpg" />
                </Col>


                <Col md={5} >
                  <h2>Media de nivel x Responsabilidad</h2>
                  <SpiderBlock data={this.state.mediaGlobal && this.state.mediaGlobal.medias} height={350} />
                </Col>
              </Row>
            </Container>

            {/* FIN BLOQUE RANKING Y GRAFICO ARAÑA*/}

            {/* BLOQUE INDICADORES MEDIAS */}

            <Container style={{ marginTop: 100, padding: 30, border: '3px solid gainsboro', borderRadius: 25, height: 750, marginBottom: 300 }}>
              <Row justify="center">
                <Col md={5} style={{ marginTop: -50, marginBottom: 30, textAlign: 'center', backgroundColor: 'white' }}><h2>Media Nivel x Responsabilidad</h2></Col>
              </Row>
              <Row justify="center">
                <Col md={3}>
                  <CardNivel 
                    title="Garantizar el cumplimiento de los objetivos principales" 
                    currentVal={ this.state.mediaGlobal && this.state.mediaGlobal.medias[1] } 
                    historicVal={ this.state.mediaGlobal && this.state.mediaGlobal.mediasMensuales } 
                    fecha={this.props.fecha}
                    datobams={this.props.datos.arbol && this.props.datos.arbol[0] && this.props.datos.arbol[0].datobams }
                    resp={1}
                  />
                </Col>
                <Col md={3}>
                  <CardNivel 
                    title="Garantizar el cumplimiento de los objetivos intermedios" 
                    currentVal={ this.state.mediaGlobal && this.state.mediaGlobal.medias[2] } 
                    historicVal={ this.state.mediaGlobal && this.state.mediaGlobal.mediasMensuales } 
                    fecha={this.props.fecha}
                    datobams={this.props.datos.arbol && this.props.datos.arbol[0] && this.props.datos.arbol[0].datobams }
                    resp={2}
                  />
                </Col>
                <Col md={3}>
                  <CardNivel 
                    title="Garantizar la satisfacción del cliente" 
                    currentVal={ this.state.mediaGlobal && this.state.mediaGlobal.medias[3] } 
                    historicVal={ this.state.mediaGlobal && this.state.mediaGlobal.mediasMensuales } 
                    fecha={this.props.fecha}
                    datobams={this.props.datos.arbol && this.props.datos.arbol[0] && this.props.datos.arbol[0].datobams }
                    resp={3}
                  />
                </Col>
              </Row>

              <Row justify="center" style={{ marginTop: 50 }}>
                <Col md={3}>
                  <CardNivel 
                    title="Aprovechamiento de las acciones formativas" 
                    currentVal={ this.state.mediaGlobal && this.state.mediaGlobal.medias[4] } 
                    historicVal={ this.state.mediaGlobal && this.state.mediaGlobal.mediasMensuales } 
                    fecha={this.props.fecha}
                    datobams={this.props.datos.arbol && this.props.datos.arbol[0] && this.props.datos.arbol[0].datobams }
                    resp={4}
                  />
                </Col>
                <Col md={3}>
                  <CardNivel 
                    title="Garantizar el cumplimiento normativo" 
                    currentVal={ this.state.mediaGlobal && this.state.mediaGlobal.medias[5] } 
                    historicVal={ this.state.mediaGlobal && this.state.mediaGlobal.mediasMensuales } 
                    fecha={this.props.fecha}
                    datobams={this.props.datos.arbol && this.props.datos.arbol[0] && this.props.datos.arbol[0].datobams }
                    resp={5}
                  />
                </Col>
                <Col md={3}>
                  <CardNivel stars={true} 
                    title="Valoración del manager" 
                    currentVal={ this.state.mediaGlobal && this.state.mediaGlobal.medias[6] } 
                    historicVal={ this.state.mediaGlobal && this.state.mediaGlobal.mediasMensuales } 
                    fecha={this.props.fecha}
                    datobams={this.props.datos.arbol && this.props.datos.arbol[0] && this.props.datos.arbol[0].datobams }
                    resp={6}
                  />
                </Col>
              </Row>
            </Container>
            <ModalCambiarPw visible={this.state.modalCambiarPw} cb={bool => this.setState({ modalCambiarPw: false }) } history={this.props.history} />

            {/* FIN BLOQUE INDICADORES MEDIAS */}

          </div>
    );
  }
}

const mapStateToProps = state => ({
  datos: state.datos,
  medias: state.medias,
  fecha: state.fecha,
  cargando: state.cargando
})

export default connect(mapStateToProps)(GlobalPage);