import React from 'react'
import { Modal } from 'antd'

const ModalCambiarPw = ({ cb, visible, history }) => (
    <Modal
        title="Contraseña por defecto"
        visible={visible}
        onCancel={cb}
        cancelText="Omitir"
        onOk={() => history.push("/perfil")}
        okText="Cambiar contraseña"
    >
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            Actualmente tienes la contraseña asignada por defecto a tu cuenta. Para una mayor seguridad de tu cuenta es necesario que cambies tu contraseña.
        </div>
    </Modal>
)



export default ModalCambiarPw