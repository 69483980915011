import React, { Component } from 'react';

import { Col, Row, Container } from 'react-grid-system'
import FichaFoto from '../components/FichaFoto'
import CardNivel from '../components/CardNivel'
import SpiderBlock from '../components/SpiderBlock'
import Responsabilidades from '../components/Responsabilidades'
import BarFiltro from '../components/BarFiltro';
import Loader from '../components/Loader'
import { connect } from 'react-redux'
import { fetchDatos, fetchDatobam, fetchMedias } from '../actions'
import { mediaVariosMeses, getGrupo, subirFotoPerfil, getBaseURL } from '../utils/func'
import auth from '../utils/auth'
import { Modal, Button, message, Input } from 'antd'
import request from '../utils/request';
import Dropzone from 'react-dropzone'
import moment from 'moment'
import ModalResponderValoracion from '../components/ModalResponderValoracion';
import ModalCambiarPw from '../components/ModalCambiarPw';

class Tecnico extends Component {

  state = {
    agente: {
      name: "",
      lastname: "",
      medias: {},
      seccion: {
        manager: {}
      }
    },
    modalValManager: false,
    acabaDeResponderEncuesta: false,
    modalValManagerLoading: false,
    modalResponderValoracion: false,
    modalCambiarPw: false,
    valoracionManager: 0,
    actualPw: "",
    pw: "",
    pw2: ""
  }
  async componentDidMount(){
    if(this.props.datos.agentes){
      const agente = this.props.datos.agentes.find(e => e.idusuario === this.props.match.params.idAgente)
      if(!agente){ this.props.history.push(await getBaseURL()) }    
      else{
        this.props.dispatch(fetchDatobam({ desde: moment.utc().startOf("month").subtract(6, "months").format(), hasta: moment.utc().format() }, agente._id ))
        this.seteoInicial(agente)
      }
    }
    else{ this.setState({ cdm: true })}
    if(this.props.fecha && this.props.fecha.desde){
      this.setState({
        unSoloMes: moment(this.props.fecha.desde).diff(this.props.fecha.hasta, "months") === 0
      })
    }
  }
  
  async componentDidUpdate(prevProps, prevState){
    if((prevProps.datos !== this.props.datos || this.props.medias !== prevProps.medias) && Array.isArray(this.props.datos.plano) && this.props.medias ){
      const agente = this.props.datos.agentes.find(e => e.idusuario === this.props.match.params.idAgente)
      if(!agente){ this.props.history.push(await getBaseURL()) }    
      else{
        if(this.state.cdm){ 
          this.setState({ cdm: false })
        }
        this.props.dispatch(fetchDatobam(this.props.fecha, agente._id )) 
        this.seteoInicial(agente)
      }
    }
    if(this.props.fecha !== prevProps.fecha){
      this.props.dispatch(fetchDatos(auth.getUserInfo().role.name, getGrupo(auth.getUserInfo().role.name), this.props.fecha))
      this.props.dispatch(fetchMedias(auth.getUserInfo().role.name, this.props.fecha))
      this.props.dispatch(fetchDatobam(this.props.fecha, this.props.datos.agentes.find(e => e.idusuario === this.props.match.params.idAgente)._id )) 
      this.setState({
        unSoloMes: moment(this.props.fecha.desde).diff(this.props.fecha.hasta, "months") === 0
      })
    }
    if((prevState.refetchDatobam !== this.state.refetchDatobam) && this.state.refetchDatobam){
      this.props.dispatch(fetchDatobam(this.props.fecha, this.state.agente._id )) 
      this.setState({ refetchDatobam: false })
    }
  }

  seteoInicial = agente => {
    if(auth.getUserInfo().tieneQueCambiarPw){
      this.setState({ modalCambiarPw: true })
    }
    const seccionRaw = agente && [agente.areaagente, agente.departamentoagente, agente.divisionagente, agente.direccionagente].find(e => e)
    if((agente._id === auth.getUserInfo()._id) && agente.nuevoComentario){
      const ultimaVal = this.props.datobam.datobams && this.props.datobam.datobams.filter(d => d.responsabilidad === "6" || d.responsabilidad === 6 )[0]
      console.log(ultimaVal)
      this.setState({ modalResponderValoracion: true, valSeleccionada: ultimaVal })
    }
    if(seccionRaw){
      const seccion = {
        nombre: seccionRaw.nombre,
        manager: seccionRaw[Object.keys(seccionRaw).find(o => o.slice(0, 7) === "manager")][0]
      }

      if(agente.entraEncuesta && !this.state.acabaDeResponderEncuesta && (agente._id === auth.getUserInfo()._id)){
        this.setState({ modalValManager: true })
      }
      this.setState({ agente: {...agente, seccion }})
    }
    else if(auth.getUserInfo().role.name !== "BAMAgente"){
      this.setState({ agente })
    }
    this.procesaMedias(agente)
  }

  procesaMedias = agente => {
    const unMes = moment(this.props.fecha.desde).isSame(this.props.fecha.hasta, "month")
    const mar = this.props.medias.filter(m => m.user && (m.user._id === agente._id))
                                 .filter(m => moment(m.fecha).isSameOrAfter(unMes ? moment(this.props.fecha.desde).subtract(6, "months") : this.props.fecha.desde, "month") && moment(m.fecha).isSameOrBefore(this.props.fecha.hasta, "month"))
    const mediaGlobal = {
      mediasMensuales: mar.map(m => ({[moment.utc(m.fecha).format()]: m})).reduce((a, b) => ({...a, ...b}), {}), 
      medias: mediaVariosMeses(!unMes ? mar : mar.filter(m => moment(m.fecha).isSame(this.props.fecha.desde, "month")))
    }

    this.setState({ mediaGlobal })
  }

  cambiarPw = () => {
    const { actualPw, pw, pw2 } = this.state
    if(pw.length >= 6){
      if(pw === pw2){
        fetch(process.env.REACT_APP_API_URL + "/auth/local", {
          method: "POST",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            "identifier": auth.getUserInfo().username,
            "password": actualPw
          })
        }).then(resp => {
          if(resp.status === 200){
            request(process.env.REACT_APP_API_URL + "/users/" + auth.getUserInfo()._id, {
              method: "PUT",
              body: { password: pw }
            }).then(data => {
              console.log(data)
              message.success("La contraseña se actualizó correctamente")
            }).catch(err => {
              console.log(err)
              message.error("Ocurrió un error durante el proceso de cambio de contraseña")
            })
          }
          else{ message.error("La contraseña actual es incorrecta")}
        }).catch(err => {
          console.log(err)
          message.error("Ocurrió un problema en el proceso de autenticación, la contraseña no se ha cambiado")
        })
      } else { message.warning("Las contraseñas no coinciden")}
    } else{ message.warning("La contraseña ha de ser de al menos 6 caracteres")}
  }

  handleModalComentario = (modalResponderValoracion, valSeleccionada) => {
    this.setState({ modalResponderValoracion, valSeleccionada })
  }

  render() {

    const { agente } = this.state
    const { datobam } = this.props

    return (
      <div>
        <Loader loading={this.props.cargando} />
        <Container fluid style={{ margin: 0, padding: 0 }}>
          <Row style={{ margin: 0, padding: 0 }}>
            <Col style={{ margin: 0, padding: 0 }}>
              <BarFiltro
                breadcrumbs={{
                  comite: agente.comite,
                  direccion: agente.direccion,
                  division: agente.division,
                  departamento: agente.departamento,
                  area: agente.area,
                  agente: {
                    nombre: agente.name + " " + agente.lastname,
                    id: agente.idusuario
                  }
                }}
              />
            </Col>
          </Row>
        </Container>

        <Container style={{ marginTop: 50 }}>
          <Row>
            <Col md={6}>
              <FichaFoto
                img={
                  (agente.imagen &&
                    process.env.REACT_APP_API_URL + agente.imagen.url) ||
                  process.env.PUBLIC_URL + "/stickman.jpg"
                }
                alturatit={180}
                alturafoto={300}
                name={agente.name + " " + agente.lastname}
                puesto={agente.puesto}
                fechaContrato={agente.fechaContrato ? moment(agente.fechaContrato).format("DD-MM-YYYY") : ""}
                centro={
                  agente.area
                    ? agente.area
                    : agente.departamento
                    ? agente.departamento
                    : agente.division
                    ? agente.division
                    : agente.direccion
                    ? agente.direccion
                    : agente.seccion && agente.seccion.nombre
                }
                mostrarBtn={auth.getUserInfo()._id === agente._id}
                action={() => this.setState({ modalEdicion: true })}
                actionTitle="Editar datos"
                agente={true}
                activo={!agente.blocked}
              />
            </Col>
            <Col md={6}>
              <SpiderBlock data={this.state.mediaGlobal && this.state.mediaGlobal.medias} height={480} />
            </Col>
          </Row>
        </Container>

        <Container
          style={{
            marginTop: 100,
            padding: 30,
            border: "3px solid gainsboro",
            borderRadius: 25,
            height: 800,
            marginBottom: 200
          }}
        >
          <Row justify="center">
            <Col
              md={5}
              style={{
                marginTop: -50,
                marginBottom: 30,
                textAlign: "center",
                backgroundColor: "white"
              }}
            >
              <h2>Media Nivel x Responsabilidad</h2>
            </Col>
          </Row>
          <Row justify="center">
            <Col md={3}>
              <CardNivel
                title="Garantizar el cumplimiento de los objetivos principales"
                currentVal={ this.state.mediaGlobal && this.state.mediaGlobal.medias[1] } 
                historicVal={ this.state.mediaGlobal && this.state.mediaGlobal.mediasMensuales } 
                fecha={this.props.fecha}
                resp={1}
              />
            </Col>
            <Col md={3}>
              <CardNivel
                title="Garantizar el cumplimiento de los objetivos intermedios"
                currentVal={ this.state.mediaGlobal && this.state.mediaGlobal.medias[2] } 
                historicVal={ this.state.mediaGlobal && this.state.mediaGlobal.mediasMensuales } 
                fecha={this.props.fecha}
                resp={2}
              />
            </Col>
            <Col md={3}>
              <CardNivel
                title="Garantizar la satisfacción del cliente"
                currentVal={ this.state.mediaGlobal && this.state.mediaGlobal.medias[3] } 
                historicVal={ this.state.mediaGlobal && this.state.mediaGlobal.mediasMensuales } 
                fecha={this.props.fecha}
                resp={3}
              />
            </Col>
          </Row>

          <Row justify="center" style={{ marginTop: 50 }}>
            <Col md={3}>
              <CardNivel
                title="Aprovechamiento de las acciones formativas"
                currentVal={ this.state.mediaGlobal && this.state.mediaGlobal.medias[4] } 
                historicVal={ this.state.mediaGlobal && this.state.mediaGlobal.mediasMensuales } 
                fecha={this.props.fecha}
                resp={4}
              />
            </Col>
            <Col md={3}>
              <CardNivel
                title="Garantizar el cumplimiento normativo"
                currentVal={ this.state.mediaGlobal && this.state.mediaGlobal.medias[5] } 
                historicVal={ this.state.mediaGlobal && this.state.mediaGlobal.mediasMensuales } 
                fecha={this.props.fecha}
                resp={5}
              />
            </Col>
            <Col md={3}>
              <CardNivel
                stars={true}
                title="Media de valoraciones"
                datobams={datobam && datobam.datobams}
                currentVal={ this.state.mediaGlobal && this.state.mediaGlobal.medias[6] } 
                historicVal={ this.state.mediaGlobal && this.state.mediaGlobal.mediasMensuales } 
                fecha={this.props.fecha}
                resp={6}
              />
            </Col>
          </Row>
        </Container>

        <Container
          style={{
            marginTop: 50,
            padding: 30,
            border: "3px solid gainsboro",
            borderRadius: 25,
            height: 550,
            marginBottom: 200
          }}
        >
          <Row justify="center">
            <Col
              md={5}
              style={{
                marginTop: -50,
                marginBottom: 30,
                textAlign: "center",
                backgroundColor: "white"
              }}
            >
              <h2>Responsabilidades</h2>
            </Col>
          </Row>
          <Row justify="center">
            <Col md={3}>
              <Responsabilidades
                indicador="1"
                dataIndicadores={datobam && datobam.datobams}
                title="Garantizar el cumplimiento de los objetivos principales"
                unSoloMes={this.state.unSoloMes ? this.props.fecha.desde : false}
              />
            </Col>
            <Col md={3}>
              <Responsabilidades
                indicador="2"
                dataIndicadores={datobam && datobam.datobams}
                title="Garantizar el cumplimiento de los objetivos intermedios"
                unSoloMes={this.state.unSoloMes ? this.props.fecha.desde : false}
              />
            </Col>
            <Col md={3}>
              <Responsabilidades
                indicador="3"
                dataIndicadores={datobam && datobam.datobams}
                title="Garantizar la satisfacción del cliente"
                unSoloMes={this.state.unSoloMes ? this.props.fecha.desde : false}
              />
            </Col>
          </Row>

          <Row justify="center" style={{ marginTop: 50 }}>
            <Col md={3}>
              <Responsabilidades
                indicador="4"
                dataIndicadores={datobam && datobam.datobams}
                title="Aprovechamiento de las acciones formativas"
                unSoloMes={this.state.unSoloMes ? this.props.fecha.desde : false}
              />
            </Col>
            <Col md={3}>
              <Responsabilidades
                indicador="5"
                dataIndicadores={datobam && datobam.datobams}
                title="Garantizar el cumplimiento normativo"
                unSoloMes={this.state.unSoloMes ? this.props.fecha.desde : false}
              />
            </Col>
            <Col md={3}>
              <Responsabilidades
                stars={true}
                handleModal={this.handleModalComentario}
                indicador="6"
                dataIndicadores={datobam && datobam.datobams}
                title="Valoración del manager"
                unSoloMes={this.state.unSoloMes ? this.props.fecha.desde : false}
                agenteId={agente._id}
              />
            </Col>
          </Row>
        </Container>

        <Modal
          visible={this.state.modalEdicion}
          onOk={() => this.editarDatos()}
          onCancel={() =>
            this.setState({
              modalEdicion: false,
              pwAct: "",
              pw: "",
              pw2: ""
            })
          }
          okButtonProps={{ loading: this.state.modalEditarLoading }}
          okText="Continuar"
          cancelText="Cancelar"
          footer={null}
        >
          <h3 style={{ marginBottom: 20 }}>Cambiar foto</h3>
          {agente.imagen ? (
            <img
              style={{
                width: "100%",
                maxHeight: 300,
                borderRadius: 5,
                marginBottom: "1em",
                objectFit: "cover",
              }}
              src={process.env.REACT_APP_API_URL + agente.imagen.url}
              alt={agente.name + " " + agente.lastname}
            />
          ) : (
            <p style={{ marginBottom: 20 }}>Sin foto de perfil</p>
          )}

          <div className="dropzoneCleaner" style={{ marginBottom: 40 }}>
            <Dropzone
              accept={[
                "image/jpeg",
                "image/jpeg",
                "image/png",
                "image/svg+xml"
              ]}
              onDrop={files =>
                subirFotoPerfil(
                  files,
                  "user",
                  auth.getUserInfo()._id,
                  refresh => {
                    if (refresh) {
                      this.props.dispatch(
                        fetchDatos(
                          auth.getUserInfo().role.name,
                          getGrupo(auth.getUserInfo().role.name)
                        )
                      );
                    }
                  }
                )
              }
              onDropRejected={() =>
                message.warning(
                  "Las imagenes han de ser JPG, PNG y SVG con un tamaño máximo de 2MiB"
                )
              }
              multiple={false}
              maxSize={2097152}
            >
              <Button size="large">Subir nueva imagen</Button>
            </Dropzone>
          </div>

          <h3 style={{ marginBottom: 20 }}>Cambiar contraseña</h3>
          <p>
            Contraseña actual
            <Input
              type="password"
              value={this.state.actualPw}
              onChange={evt =>
                this.setState({ actualPw: evt.target.value })
              }
            />
          </p>
          <p>
            Nueva contraseña
            <Input
              type="password"
              value={this.state.pw}
              onChange={evt => this.setState({ pw: evt.target.value })}
            />
          </p>
          <p>
            Repetir contraseña
            <Input
              type="password"
              value={this.state.pw2}
              onChange={evt => this.setState({ pw2: evt.target.value })}
            />
          </p>
          <Button onClick={() => this.cambiarPw()}>
            Cambiar contraseña
          </Button>
        </Modal>
        <ModalResponderValoracion
          agente={this.state.agente}
          visible={this.state.modalResponderValoracion}
          valSeleccionada={this.state.valSeleccionada}
          cb={bool => this.setState({ modalResponderValoracion: false, refetchDatobam: true }) }
          dispatch={this.props.dispatch}
          fecha={this.props.fecha}
        />
        <ModalCambiarPw visible={this.state.modalCambiarPw} cb={bool => this.setState({ modalCambiarPw: false }) } history={this.props.history} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  datos: state.datos,
  fecha: state.fecha,
  medias: state.medias,
  cargando: state.cargando,
  auth: state.auth,
  datobam: state.datobam
})

export default connect(mapStateToProps)(Tecnico);