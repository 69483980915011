import React, { Component } from 'react'
import request from '../utils/request'
import auth from '../utils/auth'
import { getGrupo } from '../utils/func'
import moment from 'moment';
import Dropzone from 'react-dropzone'
import { connect } from 'react-redux'
import XLSX from 'xlsx'
import { idemail } from '../constants/idemail'
import { Button, Modal, message, DatePicker } from 'antd'
import { fetchDatos, estaCargando, fetchConfig } from '../actions';
import Loader from '../components/Loader'

/* eslint-disable */
class HoliPage extends Component {
  
  state = {
    desde: moment()
  }

  componentDidMount = async() => {
    if(auth.getUserInfo().username !== "root" && auth.getUserInfo().username !== "miguel@blacknosaur.com"){
      this.props.history.push("/")
    }
  }
  
  meterGruposBAM = async () => {
    if( window.confirm("Seguro?") ){
      try{
        const jsonazo = JSON.parse(this.state.jsonete)
        console.log(jsonazo)
        
        jsonazo.comitesDeDireccion.forEach(cdd => {
          request(process.env.REACT_APP_API_URL + "/comitedireccions", {
            method: "POST",
            body: {
              nombre: cdd.nombre,
              idcet: cdd.id
            }
          }).then(resp1 => cdd.direcciones.forEach( d => {
            request(process.env.REACT_APP_API_URL + "/direccions", {
              method: "POST",
              body: {
                nombre: d.nombre,
                idcet: d.id,
                comitedireccion: resp1._id
              }
            }).then(resp2 => d.divisiones.forEach(di => {
              request(process.env.REACT_APP_API_URL + "/divisions", {
                method: "POST",
                body: {
                  nombre: di.nombre,
                  idcet: di.id,
                  direccion: resp2._id
                }
              }).then(resp3 => {
                if(Array.isArray(di.departamentos)){
                  di.departamentos.forEach( de => {
                    request(process.env.REACT_APP_API_URL + "/departamentos", {
                      method: "POST",
                      body: {
                        nombre: de.nombre,
                        idcet: de.id,
                        division: resp3._id
                      }
                    }).then(resp4 => {
                      if(Array.isArray(de.areas)){
                        de.areas.forEach( ar => {
                          request(process.env.REACT_APP_API_URL + "/unidadareas", {
                            method: "POST",
                            body: {
                              nombre: ar.nombre,
                              idcet: ar.id,
                              departamento: resp4._id
                            }
                          })
                        })
                      }
                    })
                  })
                }
                if(Array.isArray(di.areas)){
                  di.areas.forEach( ar => {
                    request(process.env.REACT_APP_API_URL + "/unidadareas", {
                      method: "POST",
                      body: {
                        nombre: ar.nombre,
                        idcet: ar.id,
                        division: resp3._id
                      }
                    })
                  })
                }
              })
            }))
          }))
        })
      }
      catch(err){
        console.log(err)
        alert("Ese JSON huele raro")
      }
    }
    else{
      
    }
  }
  
  borrarGruposBAM = bool => {
    const borrar = async () => {
      // const comitedireccions = await request(process.env.REACT_APP_API_URL + "/comitedireccions?_limit=0")
      const direccions = await request(process.env.REACT_APP_API_URL + "/direccions?_limit=0")
      const divisions = await request(process.env.REACT_APP_API_URL + "/divisions?_limit=0")
      const departamentos = await request(process.env.REACT_APP_API_URL + "/departamentos?_limit=0")
      const areas = await request(process.env.REACT_APP_API_URL + "/unidadareas?_limit=0")
      // const p1 = comitedireccions.map(e => request(process.env.REACT_APP_API_URL + "/comitedireccions/" + e._id, { method: "DELETE" }))
      // const r1 = await Promise.all(p1)
      const p2 = direccions.map(e => request(process.env.REACT_APP_API_URL + "/direccions/" + e._id, { method: "DELETE" }))
      const r2 = await Promise.all(p2)
      const p3 = divisions.map(e => request(process.env.REACT_APP_API_URL + "/divisions/" + e._id, { method: "DELETE" }))
      const r3 = await Promise.all(p3)
      const p4 = departamentos.map(e => request(process.env.REACT_APP_API_URL + "/departamentos/" + e._id, { method: "DELETE" }))
      const r4 = await Promise.all(p4)
      const p5 = areas.map(e => request(process.env.REACT_APP_API_URL + "/unidadareas/" + e._id, { method: "DELETE" }))
      const r5 = await Promise.all(p5)
      if( 
          // comitedireccions.length === r1.length &&
          direccions.length       === r2.length &&
          divisions.length        === r3.length &&
          departamentos.length    === r4.length &&
          areas.length            === r5.length
      ){ return true }
      else{ return false }
    }

    if(bool){ return borrar() }
    else{
      console.log(process.env)
      Modal.confirm({
        content: 'Vas a borrar todos los grupos de BAM, seguro que nadie los echara de menos, pero estás seguro? ' + (process.env.NODE_ENV !== "development" ? "¡¡¡OJO, ESTÁS EN PRODUCCIÓN!!!" : ""),
        onOk: () => { return borrar() }
      })
    }
  }

  borrarUsuariosBAM = bool => {
    const borrar = async () => {
      const users = await request(process.env.REACT_APP_API_URL + "/users?_limit=0")
      const usersBAM = users.filter(u => ((u.role && ["BAMAgente", "BAMArea", "BAMDepartamento", "BAMDireccion", "BAMDivision"].includes(u.role.name)) || !u.role))
      const p = usersBAM.map(u => request(process.env.REACT_APP_API_URL + "/users/" + u._id, { method: "DELETE" }) )
      const r = await Promise.all(p)
      if(usersBAM.length === r.length){ return true }
      else{ return false }
    }

    if(bool){ return borrar() }
    else{
      Modal.confirm({
        content: 'Vas a borrar todos los usuarios del sistema, probablemente lo merezcan, vamos con ello? ' + (process.env.NODE_ENV !== "development" ? "¡¡¡OJO, ESTÁS EN PRODUCCIÓN!!!" : ""),
        onOk: () => { return borrar() }
      })
    }
  }

  borrarDatosBAM = bool => {
    const borrar = async () => {
      const datobams = await request(process.env.REACT_APP_API_URL + "/datobams?_limit=0")
      const p = datobams.map(d => request(process.env.REACT_APP_API_URL + "/datobams/" + d._id, { method: "DELETE" }) )
      const r = await Promise.all(p)
      if(datobams.length === r.length){ return true }
      else{ return false }
    }
    if(bool){ return borrar() }
    else{
      Modal.confirm({
        content: 'Vas a borrar todos los registros de BAM, dudo que nadie los vaya a echar de menos, le doy? ' + (process.env.NODE_ENV !== "development" ? "¡¡¡OJO, ESTÁS EN PRODUCCIÓN!!!" : ""),
        onOk: () => { return borrar() }
      })
    }
  }

  borrarMediasBAM = bool => {
    const borrar = async () => {
      const mediabams = await request(process.env.REACT_APP_API_URL + "/mediabams?_limit=0")
      const p = mediabams.map(d => request(process.env.REACT_APP_API_URL + "/mediabams/" + d._id, { method: "DELETE" }) )
      const r = await Promise.all(p)
      if(mediabams.length === r.length){ return true }
      else{ return false }
    }
    if(bool){ return borrar() }
    else{
      Modal.confirm({
        content: 'Vas a borrar todos las medias de BAM, oh, que pena ' + (process.env.NODE_ENV !== "development" ? "¡¡¡OJO, ESTÁS EN PRODUCCIÓN!!!" : ""),
        onOk: () => { return borrar() }
      })
    }
  }

  borrarTodo = () => {
    Modal.confirm({
      content: 'Oh sí, has pulsado el gran botón rojo de la destrucción, a tope con el borrar? ' + (process.env.NODE_ENV !== "development" ? "No es por coartar tu apetito destructivo pero... ¡¡¡OJO GORDO, ESTÁS EN PRODUCCIÓN!!!" : ""),
      onOk: async () => {
        this.props.dispatch(estaCargando(true))
        const a = this.borrarDatosBAM(true)
        const b = this.borrarUsuariosBAM(true)
        const c = this.borrarGruposBAM(true)
        const d = this.borrarMediasBAM(true)
        a.then(ar => {
          b.then(br => {
            c.then(cr => {
              d.then(dr => {
                if(ar && br && cr && dr){ 
                  this.props.dispatch(estaCargando(false))
                  message.success("Mundo destruido satisfactoriamente") 
                  this.props.dispatch(fetchDatos(auth.getUserInfo().role.name, getGrupo(auth.getUserInfo().role.name), this.props.fecha))
                }
                else{
                  this.props.dispatch(estaCargando(false))
                  if(ar){ message.error("Han ocurrido errores durante el borrado de los datos de BAM") }
                  if(br){ message.error("Han ocurrido errores durante el borrado de los usuarios") }
                  if(cr){ message.error("Han ocurrido errores durante el borrado de los grupos") }
                  if(dr){ message.error("Han ocurrido errores durante el borrado de las medias") }
                }
              })
            })
          })
        })
      }
    })
  }
  
  cargarBAM = async uploaded => {
    var errors = []
    var file = uploaded[0], read = new FileReader();
    read.readAsBinaryString(file);
    read.onloadend = async () => {
      var oFile = XLSX.read(read.result, {type: 'binary' })
      const data = XLSX.utils.sheet_to_json(oFile.Sheets[oFile.SheetNames[0]], {cellNF:true, raw:false})
      console.log(data)
      const datosProcesados = data.map(fila => {
        const finduser = this.props.usuarios.find(e => e.idusuario === fila["UID"].toLowerCase())
        return {
          fecha: moment.utc("2000-01-01T02:00:00+02:00").year(parseInt(fila["FECHA"].slice(0, 4), 10)).month(parseInt(fila["FECHA"].slice(4), 10) - 1).format(),
          user: finduser ? finduser._id : undefined,
          responsabilidad: parseFloat(fila["RESPONSABILIDAD"], 10),
          kpi: parseFloat(fila["KPI"], 10),
          resultado: parseFloat(fila["RESULTADO"], 10),
          objetivo: parseFloat(fila["OBJETIVO"], 10),
          ponderacion: parseFloat(fila["PONDERACIÓN"], 10),
          descripcionkpi: fila["DESC KPI"],
          descresponsabilidad: fila["DESC RESPONSABILIDAD"]
        }
      })
      console.log(datosProcesados)
      
      const promesitas = datosProcesados.map(async body => {
        const resp = await request(process.env.REACT_APP_API_URL + "/datobams", {
          method: "POST",
          body
        })
        if(resp.status >= 400){
          console.log(resp)
          errors.push(resp)
        }
        return resp
      })

      const arrayito = await Promise.all(promesitas)

      if( errors.length > 0){
        console.log({ arrayito, errors })
        alert("Fuck")
      }
      else{
        alert("Todo correcto")
      }
    }
  }  
  
  cargarUsuariosBAM = async uploaded => {
    var errors = []
    var file = uploaded[0], read = new FileReader();
    read.readAsBinaryString(file);
    read.onloadend = async () => {
      var oFile = XLSX.read(read.result, {type: 'binary' })
      const data = XLSX.utils.sheet_to_json(oFile.Sheets[oFile.SheetNames[0]], {cellNF:true, raw:false})
      this.setState({ data })
      const usuariosProcesados = data.map( fila => {
        const cln = fila["Código de UO por tipo de unidad Línea de negocio"]
        const ccd = fila["Código de UO por tipo de unidad Comité de Dirección"]
        const cdir = fila["Código de UO por tipo de unidad Direccion"]
        const cdiv = fila["Código de UO por tipo de unidad División"]
        const cdep = fila["Código de UO por tipo de unidad Departamento"]
        const car = fila["Código de UO por tipo de unidad Área"]
        const csub = fila["Código de UO por tipo de unidad Subarea"]
        
        const codigoUnidad = (cln  === ccd ) ? cln  : (
                       (ccd  === cdir) ? ccd  : (
                       (cdir === cdiv) ? cdir : (
                       (cdiv === cdep) ? cdiv : (
                       (cdep === car ) ? cdep : (
                       (car  === csub) ? car  : csub 
        ))))).slice(3)

        const grupo = this.props.datos.plano.find(g => g.idcet === codigoUnidad)
        
        const { roles } = this.props
        const esJefe = fila["Puesto"].toLowerCase().includes("jef") || fila["Puesto"].toLowerCase().includes("director")

        const rol = esJefe ? (
          grupo.jerarquia === "comitedireccion" ? roles.find(r => r.name === "Administrator") : (
          grupo.jerarquia === "direccion" ? roles.find(r => r.name === "BAMDireccion") : (
          grupo.jerarquia === "division" ? roles.find(r => r.name === "BAMDivision") : (
          grupo.jerarquia === "departamento" ? roles.find(r => r.name === "BAMDepartamento") : (
          grupo.jerarquia === "area" ? roles.find(r => r.name === "BAMArea") : undefined 
        ))))) : roles.find(r => r.name === "BAMAgente")
        
        const pertenencia = esJefe ? (
          grupo.jerarquia === "comitedireccion" ? { comitedireccions: grupo._id } : (
          grupo.jerarquia === "direccion" ? { direccions: grupo._id } : (
          grupo.jerarquia === "division" ? { divisions: grupo._id } : (
          grupo.jerarquia === "departamento" ? { departamentos: grupo._id } : (
          grupo.jerarquia === "area" ? { unidadareas: grupo._id } : undefined 
        ))))) : (
          grupo.jerarquia === "direccion" ? { direccionagente: grupo._id } : (
          grupo.jerarquia === "division" ? { divisionagente: grupo._id } : (
          grupo.jerarquia === "departamento" ? { departamentoagente: grupo._id } : (
          grupo.jerarquia === "area" ? { areaagente: grupo._id } : undefined 
        ))))

        const findemail = idemail.find(e => e.id === fila["ID"].toLowerCase())
        return {
          //...fila,
          idusuario: fila["ID"],
          name: this.mayusculizer(fila["Nombre"]),
          lastname: this.mayusculizer(fila["Apellidos"]),
          email: findemail ? findemail.email : fila["ID"] + "@cet.elem",
          username: findemail ? findemail.email : fila["ID"] + "@cet.elem",
          password: "cetelem",
          confirmed: true,
          blocked: false,
          codigoUnidad,
          grupo: grupo._id,
          role: rol._id,
          test: true,
          ...pertenencia
        }

      })
      
      console.log( usuariosProcesados )

      const promesitas = usuariosProcesados.map(async body => {
        const resp = await request(process.env.REACT_APP_API_URL + "/users", {
          method: "POST",
          body
        })
        if(resp.status >= 400){
          errors.push(resp)
        }
        return resp
      })

      const arrayito = await Promise.all(promesitas)

      if( errors.length > 0){
        console.log({ arrayito, errors })
        alert("Fuck")
      }
      else{
        alert("Todo correcto")
      }
    }
  }

  toggleUsername = async () => {
    const users = await request(process.env.REACT_APP_API_URL + "/users?_limit=0")
    const usersBAM = users.filter(u => ["BAMAgente", "BAMArea", "BAMDepartamento", "BAMDireccion", "BAMDivision"].includes(u.role.name))
    const clave = usersBAM[0].username === usersBAM[0].email ? "idusuario" : ( usersBAM[0].username === usersBAM[0].idusuario ? "email" : undefined )
    if(clave){
      const p = usersBAM.map(u => {
        return request(process.env.REACT_APP_API_URL + "/users/" + u._id, {
          method: "PUT",
          body: { username: u[clave] }
        }).catch(err => console.log(err))
      })
      Promise.all(p).then(r => {
        if(r.filter(e => e).length === usersBAM.length){ message.success(`Ahora el username es ${clave === "idusuario" ? "el ID de Cetelem" : "el email"}`) }
        else{ message.error("Ha ocurrido un error durante el cambio de método de login")}
      })
    }
    else{ message.error("Algo raro pasa") }
  }

  campoAcceso = async () => {
    const users = await request(process.env.REACT_APP_API_URL + "/users?_limit=0")
    const usersBAM = users.filter(u => ["BAMAgente", "BAMArea", "BAMDepartamento", "BAMDireccion", "BAMDivision"].includes(u.role.name))
    // const usersCET = users.filter(u => ["CETAdmin", "CETManager", "CETAgente"].includes(u.role.name))
    // const usersAdmin = users.filter(u => ["Administrator"].includes(u.role.name))

    const p = users.map(u => {
      return request(process.env.REACT_APP_API_URL + "/users/" + u._id, {
        method: "PUT",
        body: { 
          acceso: {
            bam: ["BAMAgente", "BAMArea", "BAMDepartamento", "BAMDireccion", "BAMDivision"].includes(u.role.name) || u.role.name === "Administrator",
            cet: ["CETAdmin", "CETManager", "CETAgente"].includes(u.role.name) || u.role.name === "Administrator",
            rvi: u.role.name === "Administrator"
          }
        }
      }).catch(err => console.log(err))
    })

    Promise.all(p).then(r => {
      if(r.filter(e => e).length === usersBAM.length){ message.success(`Todos los usuarios tienen campo acceso pobladito y bien`) }
      else{ message.error("Ha ocurrido un error durante la creación del campo acceso") }
    })

  }

  borrarDesde = async () => {
    Modal.confirm({
      content: `¿Estás seguro de que quieres borrar todos los datos y medias subidos desde el ${ this.state.desde.format("YYYY-MM-DD") }? ` + (process.env.NODE_ENV !== "development" ? "¡¡¡OJO, ESTÁS EN PRODUCCIÓN!!!" : ""),
      onOk: async () => {
        const datobams= await request(process.env.REACT_APP_API_URL + "/datobams?_limit=0")
        const mediabams= await request(process.env.REACT_APP_API_URL + "/mediabams?_limit=0")

        const datobams24 = datobams.filter(d => moment(d.createdAt).isAfter(this.state.desde))
        console.log(datobams24)
        const mediabams24 = mediabams.filter(m => moment(m.createdAt).isAfter(this.state.desde))
    
        const promesitasd24 = datobams24.map(d => request(process.env.REACT_APP_API_URL + "/datobams/" + d._id, { method: "DELETE" }))
        const promesitasm24 = mediabams24.map(m => request(process.env.REACT_APP_API_URL + "/mediabams/" + m._id, { method: "DELETE" }))
    
        const respd24 = await Promise.all(promesitasd24)
        const respm24 = await Promise.all(promesitasm24)
    
        console.log({
          respd24,
          respm24
        })
        Modal.destroyAll()
    
        message.warning("Puede haber funcionado o puede que no. Echa un ojo a la consola")
      }
    })
  }

  borrarDatosNoAsociados = async () => {
    this.props.dispatch(estaCargando(true))
    const dbs = await request(process.env.REACT_APP_API_URL + "/datobams?_limit=0")
    const dbusernull = dbs.filter(db => !db.user )
    this.props.dispatch(estaCargando(false))
    console.log({ dbusernull })
    Modal.confirm({
      content: '¿Estás seguro de que quieres borrar todos los datos sin asociar con ningún usuario? ' + (process.env.NODE_ENV !== "development" ? "¡¡¡OJO, ESTÁS EN PRODUCCIÓN!!!" : ""),
      onOk: async () => {
        this.props.dispatch(estaCargando(true))
        const prom = dbusernull.map(db => request(process.env.REACT_APP_API_URL + "/datobams/" + db._id, { method: "DELETE" }))
        const resp = await Promise.all(prom)
        this.props.dispatch(estaCargando(false))
        console.log(resp)
      }
    })
  }

  frenoDeMano = bool => {
    this.props.dispatch(estaCargando(true))
    request(process.env.REACT_APP_API_URL + "/configs/" + this.props.config._id, {
      method: "PUT",
      body: { soloB2B: !bool }
    }).then(data => {
      console.log(data)
      this.props.dispatch(estaCargando(false))
      message.success("Crrrrrrrrrrra! Freno de mano " + ( data.soloB2B ? "echado" : "quitado" ))
      this.props.dispatch(fetchConfig())
    }).catch(err => {
      console.log(err)
      this.props.dispatch(estaCargando(false))
      message.error("La palanca del freno de mano se ha roto")
    })
  }

  frenoDeMano2 = bool => {
    this.props.dispatch(estaCargando(true))
    request(process.env.REACT_APP_API_URL + "/configs/" + this.props.config._id, {
      method: "PUT",
      body: { subidaRestringida: !bool }
    }).then(data => {
      console.log(data)
      this.props.dispatch(estaCargando(false))
      message.success("Crrrrrrrrrrra! Segundo freno de mano " + ( data.subidaRestringida ? "echado" : "quitado" ))
      this.props.dispatch(fetchConfig())
    }).catch(err => {
      console.log(err)
      this.props.dispatch(estaCargando(false))
      message.error("La palanca del freno de mano se ha roto")
    })
  }

  render() {
    return (
      <div className="container">
        <Loader loading={this.props.cargando} />
        <h1 className="text-center mt-4">Holi Page</h1>
        <h4 className="text-center">&#9888; Esta página es peligrosilla, si no sabes lo que haces, mejor no toques &#9888;</h4>
        <div className="d-flex p-2 row w-100 justify-content-center">

          <div>
            <input className="" value={this.setState.jsonete} onChange={evt => this.setState({ jsonete: evt.target.value })} />
            <Button onClick={() => this.meterGruposBAM()}>Meter JSON de jerarquía de BAM</Button>
          </div>
          <Dropzone style={{ width: "100%" }} onDrop={file => { this.cargarBAM(file) }}>
            <Button>Cargar datos de BAM</Button>
          </Dropzone>
          <Dropzone style={{ width: "100%" }} onDrop={file => { this.cargarUsuariosBAM(file) }}>
            <Button>Cargar usuarios de BAM</Button>
          </Dropzone>
          <div>
            <Button type="danger" onClick={() => this.borrarUsuariosBAM()}>Borrar usuarios de BAM</Button>
            <Button type="danger" onClick={() => this.borrarGruposBAM()}>Borrar grupos de BAM</Button>
            <Button type="danger" onClick={() => this.borrarMediasBAM()}>Borrar medias de BAM</Button>
            <Button type="danger" onClick={() => this.borrarDatosBAM()}>Borrar todos los datos de BAM</Button>
            <Button type="danger" onClick={() => this.borrarTodo()}>Borrar el PUTO UNIVERSO</Button>
          </div>
          <div>
            <Button type="primary" onClick={() => this.toggleUsername()}>Cambiar login (email por ID)</Button>
            <Button type="primary" onClick={() => this.campoAcceso()}>Añadir campo acceso</Button>
          </div>
          <div>
            <DatePicker value={this.state.desde} onChange={desde => this.setState({ desde })} />
            <Button type="primary" onClick={() => this.borrarDesde()}>Borrar datos y medias desde la fecha seleccionada</Button>
          </div>
          <div>
            <Button type="primary" onClick={() => this.borrarDatosNoAsociados()}>Borrar datos no asociados a ningún usuario</Button>
          </div>
          <div>
            <Button type="primary" onClick={() => this.frenoDeMano(this.props.config.soloB2B)}>{ this.props.config.soloB2B ? "Quitar" : "Echar"} freno de mano</Button>
            <Button type="primary" onClick={() => this.frenoDeMano2(this.props.config.subidaRestringida)}>{ this.props.config.subidaRestringida ? "Quitar" : "Echar"} segundo freno de mano</Button>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    usuarios: state.usuarios,
    datos: state.datos,
    mediabams: state.mediabams,
    roles: state.roles,
    fecha: state.fecha,
    cargando: state.cargando,
    config: state.config
  }
}

export default connect(mapStateToProps)(HoliPage)
