import { combineReducers } from 'redux'
import datos from './datos'
import usuarios from './usuarios'
import agentes from './agentes'
import roles from './roles'
import baseURL from './baseURL'
import fecha from './fecha'
import config from './config'
import cargando from './cargando'
import medias from './medias'
import datobam from './datobam'

const rootReducer = combineReducers({
    datos,
    medias,
    usuarios,
    agentes,
    roles,
    baseURL,
    fecha,
    cargando,
    config,
    datobam
})

export default rootReducer;