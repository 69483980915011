import React from 'react';
import PropTypes from 'prop-types';
import { findIndex, get, map, replace, set } from 'lodash';
import { Link } from 'react-router-dom';
import Button from '../components/Button';
import Input from '../components/InputsIndex';
import Logo from '../assets/Users-User-Male-4-icon.png';
import SocialLink from '../components/SocialLink';
import auth from '../utils/auth';
import request from '../utils/request';
import { getBaseURL } from '../utils/func'
import form from './forms.json';
import './styles.css';
import { connect } from 'react-redux'

class AuthPage extends React.Component {
  state = { value: {}, errors: [], didCheckErrors: false };

  componentDidMount() {
    auth.clearAppStorage();
    this.generateForm(this.props);
    if(this.props.match.params.authType === 'logout'){
      this.props.loggingAction()
      this.props.history.push('/auth/login');
    }
  }
  
  componentDidUpdate(prevProps){
    if( prevProps.match.params.authType !== this.props.match.params.authType){
      this.generateForm(this.props);
    }
    if(this.props.match.params.authType === 'logout'){
      auth.clearToken();
      auth.clearUserInfo();
      this.props.loggingAction()
      this.props.history.push('/auth/login');
    }
  }

  getRequestURL = () => {
    let requestURL;

    switch (this.props.match.params.authType) {
      case 'login':
        requestURL = process.env.REACT_APP_API_URL + '/auth/local';
        break;
      case 'register':
        requestURL = process.env.REACT_APP_API_URL + '/auth/local/register';
        break;
      case 'reset-password':
        requestURL = process.env.REACT_APP_API_URL + '/auth/reset-password';
        break;
      case 'forgot-password':
        requestURL = process.env.REACT_APP_API_URL + '/auth/forgot-password';
        break;
      default:
    }

    return requestURL;
  };
  
  generateForm = props => {
    const params = props.location.search
      ? replace(props.location.search, '?code=', '')
      : props.match.params.id;
    this.setForm(props.match.params.authType, params);
  };

  handleChange = ({ target }) => {
    this.setState({
      value: { ...this.state.value, [target.name]: target.value },
    })
  }

  handleSubmit = e => {
    e.preventDefault();
    const body = this.state.value;
    const requestURL = this.getRequestURL();

    // This line is required for the callback url to redirect your user to app
    if (this.props.match.params.authType === 'forgot-password') {
      set(body, 'url', process.env.REACT_APP_API_URL + '/auth/forgot-password');
    }

    request(requestURL, { method: 'POST', body: this.state.value })
      .then(response => {
        if( this.props.match.params.authType === 'forgot-password' && response.ok ){
          alert("Recibirá en breves momentos un email con un enlace para que pueda recuperar su contraseña")
        }
        else if( this.props.match.params.authType === 'forgot-password' && !(response.ok) ){
          alert("Ocurrió un error durante el envío del correo de recuperación de contraseña")
        }
        auth.setToken(response.jwt, body.rememberMe);
        auth.setUserInfo(response.user, body.rememberMe);
        this.redirectUser();
      })
      .catch(err => {
        // TODO handle errors for other views
        // This is just an example
        
        if( err.response && err.response.payload.message === "This email does not exist." ){ alert("El correo electrónico introducido no existe") }

        const resp = Array.isArray(err.response.payload.message) && Array.isArray(err.response.payload.message[0].messages) && err.response.payload.message[0].messages[0]
        const traduccion = 
          err.response === undefined ? "El servidor no responde. Inténtelo más tarde" : (
          resp.message === "Please provide your password." ? "Por favor, introduzca su contraseña" : (
          resp.message === "Please provide your username or your e-mail." ? "Por favor, introduzca usuario y contraseña" : (
          resp.message === "This email does not exist." ? "El email no se encuentra en nuestra base de datos" : (
          resp.message === "Identifier or password invalid." ? "Usuario o contraseña inválidos" : 
          resp.message
        ))))

        const errors = [
          { name: 'identifier', errors: [traduccion] }
        ];
        console.log({ err })
        this.setState({ didCheckErrors: !this.state.didCheckErrors, errors });
      });
  };

  redirectUser = async () => {
    const baseURL = await getBaseURL()
    this.props.history.push(baseURL)
    this.props.loggingAction()
  };

  /**
   * Function that allows to set the value to be modified
   * @param {String} formType the auth view type ex: login
   * @param {String} email    Optionnal
   */
  setForm = (formType, email) => {
    const value = get(form, ['data', formType], {});

    if (formType === 'reset-password') {
      set(value, 'code', email);
    }
    this.setState({ value });
  };

  /**
   * Check the URL's params to render the appropriate links
   * @return {Element} Returns navigation links
   */
  renderLink = () => {
    if (this.props.match.params.authType === 'login') {
      return (
        <div style={{ paddingBottom: "1em" }}>
          <Link to="/auth/forgot-password">¿Olvidaste la contraseña?</Link>
         {/* &nbsp;or&nbsp;
         <Link to="/auth/register">register</Link>*/}
        </div>
      );
    }

    return (
      <div className="pb-2">
        <Link to="/auth/login">Volver al inicio de sesión</Link>
      </div>
    );
  };

  render() {
    const inputs = get(form, ['views', this.props.match.params.authType], []);
    const providers = [/*'facebook', 'github', 'google', 'twitter'*/]; // To remove a provider from the list just delete it from this array...

    return (
      <div className="authPage">
        <div className="wrapper">
          <div className="formContainer">
          <div className="headerContainer">
            <img src={Logo} alt="logo" className="userimg" />
          </div>
          <h5 className="identi">Identifícate</h5>
            <p className="textoacceso">Accede a tu área privada de benchmarks y <br/>planes de acción personalizados</p>
              {providers.map(provider => (
                <SocialLink provider={provider} key={provider} />
              ))}
              <form onSubmit={this.handleSubmit}>
                  {map(inputs, (input, key) => (
                    <Input
                      autoFocus={key === 0}
                      customBootstrapClass={get(input, 'customBootstrapClass')}
                      didCheckErrors={this.state.didCheckErrors}
                      errors={get(
                        this.state.errors,
                        [
                          findIndex(this.state.errors, ['name', input.name]),
                          'errors',
                        ],
                        []
                      )}
                      key={get(input, 'name')}
                      label={get(input, 'label')}
                      name={get(input, 'name')}
                      onChange={this.handleChange}
                      placeholder={get(input, 'placeholder')}
                      type={get(input, 'type')}
                      validations={{ required: true }}
                      value={get(this.state.value, get(input, 'name'), '')}
                    />
                  ))}
                  <div className="linkContainer">{this.renderLink()}</div>
                    <Button
                      label="Acceder"
                      style={{ width: '100%' }}
                      className="accede"
                      type="submit"
                    />
              </form>
          </div>
        </div>
      </div>
    );
  }
}

AuthPage.defaultProps = {};
AuthPage.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  baseURL: state.baseURL
})

export default connect(mapStateToProps)(AuthPage);
